import React from 'react';
import PropTypes from 'prop-types';

import './Expander.scss';

export default class Expander extends React.Component {

  state = {
    open: this.props.open
  };

  render() {
    var style, openClass;
    if (this.state.open) {
      style = {
        maxHeight: `${this.props.maxHeight}px`,
      }
      openClass = "expanded";
    }

    return (
      <div className={"Expander clearfix " + openClass}>
        {this.props.togglePlacement === 'above' && this.toggleButton()}

        <div className="expander-content" style={style}>
          {
            this.state.open ?
              this.props.sections.map((section, i) => {
                return (
                  <div className="content-section" key={i}>
                    {section}
                  </div>
                );
              })
              :
              !this.props.hideAll &&
              this.props.sections.slice(0, this.props.visibleSectionCount).map((section, i) => {
                return (
                  <div className="content-section" key={i}>
                    {section}
                  </div>
                );
              })
          }
        </div>

        {this.props.togglePlacement === 'below' && this.toggleButton()}
      </div>
    );
  }

  toggleButton() {
    var toggleContent;

    if (this.state.open) {
      toggleContent = this.props.openIcon ? this.props.openIcon : this.props.toggleOpenedText;
    } else {
      toggleContent = this.props.closedIcon ? this.props.closedIcon : this.props.toggleClosedText;
    }

    if (this.props.sections.length > 1 || this.props.hideAll) {
      return (
        <div className="clearfix">
          <a href="#_" rel="" onClick={this.toggleOpen} className={`toggle-button ${this.props.centerToggle ? " centered " : ""}`}>
            {toggleContent}
          </a>
        </div>
      )
    }
  }

  toggleOpen = (e) => {
    e.preventDefault();

    this.setState({
      open: !this.state.open
    });
  }
}

Expander.propTypes = {
  open: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.node),
  closedIcon: PropTypes.node,
  openIcon: PropTypes.node,
  hideAll: PropTypes.bool,
  togglePlacement: PropTypes.string,
  toggleClosedText: PropTypes.string,
  toggleOpenedText: PropTypes.string,
  centerToggle: PropTypes.bool,
  visibleSectionCount: PropTypes.number,
  maxHeight: PropTypes.number
}

Expander.defaultProps = {
  open: false,
  hideAll: false,
  sections: [],
  togglePlacement: 'below',
  toggleOpenedText: 'View less',
  toggleClosedText: 'View more',
  centerToggle: false,
  visibleSectionCount: 1
} 