import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import Icon from '@ant-design/icons';

import IconMetrics from '../../theming/icons/IconMetrics';
import IconPositive from '../../theming/icons/IconPositive';
import IconError from '../../theming/icons/IconError';
import IconWarning from '../../theming/icons/IconWarning';
import IconUnknown from '../../theming/icons/IconUnknown';

export const metricIcon = (props) => <Icon component={IconMetrics}  {...props} />;

export const metricColumns = [
  {
    title: "Host",
    key: "host",
    field: "host",
    render: (text, record) => {
      if (record.host_name && record.host_id) {
        return (<Link to={"/glsmon/hosts/" + record.host_id
        }>{record.host_name}</Link>);
      }
    }
  },
  {
    title: "Query",
    key: "query",
    field: "query",
    render: (text, record) => {
      if (record.query_name && record.query_id) {
        return (<Link to={"/glsmon/hosts/" + record.host_id + "/queries/" + record.query_id
        }>{record.query_name}</Link>);
      }
    }
  },
  {
    title: "Status",
    key: "state",
    dataIndex: "state",
    className: "centered-column",
    render: (text, record) => {
      if (record.metric_state_type) {
        switch (record.metric_state_type.name) {
          case "Host Up": return (<span className="inline-icon positive"><IconPositive /> Host Up</span>);
          case "Host Down": return (<span className="inline-icon danger"><IconError /> Host Down</span>);
          case "Ok": return (<span className="inline-icon positive"><IconPositive /> Ok</span>);
          case "Warning": return (<span className="inline-icon warning"><IconWarning /> Warning</span>);
          case "Critical": return (<span className="inline-icon danger"><IconError /> Critical</span>);
          case "Unknown": return (<span className="inline-icon"><IconUnknown /> Unknown</span>);
          case "Stale": return (<span className="inline-icon"><IconWarning /> Stale</span>);
          default: return <span className="inline-icon"><IconUnknown /> Unknown</span>;
        }
      }
    }
  },
  {
    title: "Metric",
    key: "name",
    dataIndex: "name",
    render: (text, record) => <span className="bold">{record.name}</span>
  },
  {
    title: "Text ",
    dataIndex: "check_text_first_line_limited",
    key: "check_text_first_line_limited",
    render: (text, record) => <span className="column-text">{record.check_text_first_line_limited}</span>
  },
  {
    title: "Last checked",
    dataIndex: "post_age_hhmm",
    key: "post_age_hhmm",
    render: (text, record) => <span className="column-text">{record.post_age_hhmm}</span>
  },
  {
    title: "Last changed",
    dataIndex: "event_age_hhmm",
    key: "event_age_hhmm",
    render: (text, record) => <span className="column-text">{record.event_age_hhmm}</span>
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];

export const metricColumnsCondensed = [
  {
    title: "Host",
    key: "host",
    field: "host",
    render: (text, record) => {
      if (record.host_name && record.host_id) {
        return (<Link to={"/glsmon/hosts/" + record.host_id
        }>{record.host_name}</Link>);
      }
    }
  },
  {
    title: "Metric",
    key: "metric",
    dataIndex: "metric_name",
    render: (text, record) => record.name
  },
  {
    title: "Check",
    key: "check",
    dataIndex: "check",
    render: (text, record) => {
      if (record.check_name && record.check_id) {
        return (<Link to={"/glsmon/hosts/" + record.check_id
        }>{record.check_name}</Link>);
      }
    }
  },
  {
    title: "Status",
    key: "status",
    className: "centered-column",
    render: (text, record) => {
      switch (record.state) {
        case "Host Up": return (<span className="inline-icon positive"><IconPositive /> Host Up</span>);
        case "Host Down": return (<span className="inline-icon danger"><IconError /> Host Down</span>);
        case "Ok": return (<span className="inline-icon positive"><IconPositive /> Ok</span>);
        case "Warning": return (<span className="column-text warning"><IconWarning /> Warning</span>);
        case "Critical": return (<span className="inline-icon danger"><IconError /> Critical</span>);
        case "Unknown": return (<span className="inline-icon"><IconUnknown /> Unknown</span>);
        case "Stale": return (<span className="inline-icon"><IconWarning /> Stale</span>);
        default: return <span className="inline-icon"><IconUnknown /> Unknown</span>;
      }
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span className="metric-mobile-action">
        {record.actions.length > 0 && record.actions.map((action, i) => {

          if (!action.enabled(record)) { return null; }

          return (
            <span key={`${record.id}-${action.name}`}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record.id)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const metricFilters = {
  "host": "MultiSelector",
};

export const metricLabelMap = {
  "name": "Metric"
}

export const metricActions = (history) => {
  return [
    {
      name: "View",
      type: "primary",
      func: (id) => {
        history.push(`/glsmon/metrics/${id}`);
      },
      enabled: (record) => {
        return true
      }
    },
    {
      name: "Ack",
      type: "default",
      func: (id) => {
        history.push(`/glsmon/metrics/${id}/ack/new`);
      },
      enabled: (record) => {
        return true
      }
    },
  ];
}

