import React from 'react';
import { Button } from 'antd';
import BackLink from '../components/BackLink';
import SelectInput from '../components/SelectInput';
import CheckboxInput from '../components/CheckboxInput';
import TextInput from '../components/TextInput';

class MetricsAck extends React.Component {
  render() {
    const metricOptions = [
      { value: 1, optionLabel: "Var" },
      { value: 2, optionLabel: "Option2" },
      { value: 3, optionLabel: "Option3" }
    ];
    const metricStatesCheckboxes = [
      { defaultChecked: true, label: 'Host Down' },
      { defaultChecked: false, label: 'Host Up' },
      { defaultChecked: false, label: 'Stale' },
      { defaultChecked: false, label: 'Ok' },
      { defaultChecked: false, label: 'Warning' },
      { defaultChecked: false, label: 'Unknown' },
    ];
    const endTimeCheckboxes = [
      { defaultChecked: false, label: 'No scheduled end time' },
      { defaultChecked: true, label: 'Scheduled by date and time' },
      { defaultChecked: false, label: 'Schedule by duration' }
    ];

    return (
      <div className="MetricsAck view">

        <div className="page-area--tabbed">
          <BackLink history={this.props.history} />
          <h1 className="header-bar">Add Metric Acknowledgement</h1>

          <div className="view-section view-section--no-border">
            <SelectInput label="Metric" options={metricOptions} />

            <p className="bold">Contact Addresses</p>
            <CheckboxInput
              label="Apply to all Contact Addresses"
              description="(Leave blank to default to the file path selected above)"
              checkboxes={[{ defaultChecked: true, label: "" }]}
            />

            <p className="bold">Metric States</p>
            <CheckboxInput
              label="Apply to all metric States"
              description="For the duration of the Ack, treat selected states as synonymous with 'Ok' and not problem states"
              checkboxes={[{ defaultChecked: true, label: "" }]}
            />

            <CheckboxInput
              label="Metric States"
              checkboxes={metricStatesCheckboxes}
            />

            <TextInput
              label="End ack after n minutes in an un-acked state"
              description="You can optionally use this field to create itself after the problem state is resolved"
              inputType="number"
              initialValue={20}
            />

            <CheckboxInput
              label="Schedule End Time"
              description="Optionally st an end time to clear the Ack automatically"
              checkboxes={endTimeCheckboxes}
              type="radio"
            />

            <p>Schedule Duration (from now)</p>
            <TextInput label="Days" initialValue={1} inputType="number" />
            <TextInput label="Hours" initialValue={0} inputType="number" />
            <TextInput label="Minutes" initialValue={0} inputType="number" />
          </div>

          <div className="view-action"><Button type="primary">Save and Return to list</Button></div>

        </div>
      </div>
    )
  }
}

export default MetricsAck;