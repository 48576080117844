import React from 'react';
import { withRouter } from 'react-router-dom';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import IconAdd from '../theming/icons/IconAdd';
import axios from 'axios';
import { fileColumns } from '../api/schema/file';

class ProjectsViewingFiles extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    this.panelHeader = item => item.check;

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New File', href: `/glsmon/projects/${this.props.projectId}/files/new` }
    ];

    this.tableOptionsModel = {
      type: 'files',
      title: 'Files',
      rowsPerPage: 5,
      currentPage: 1
    };
  }

  render() {
    return (
      <div className="ProjectsViewingFiles nested-tab-view">
        <DataWrapper
          pageTitle="Files"
          api="file"
          columns={fileColumns}
          actions={[
            {
              name: "Edit",
              type: "primary",
              func: this.editFile,
              enabled: (record) => {
                return true
              }
            },
            {
              name: "Delete",
              type: "primary",
              func: this.deleteFile,
              enabled: (record) => {
                return true
              }
            }
          ]}
          requiredParams={{ project: this.props.projectId }}
          panelHeader={this.panelHeader}
          tableOptionsLinks={this.tableOptionsLinks}
          tableOptionsModel={this.tableOptionsModel}
        >
          {(props, state, actions) => {
            return (
              <div className="view-section view-section--no-border">
                <DynamicTable
                  {...props}
                  {...state}
                  {...actions}
                />
              </div>
            )
          }}
        </DataWrapper>
      </div>
    )
  }

  editFile = (file) => {
    this.props.history.push(`/glsmon/projects/${this.props.projectId}/files/${file.id}`);
  }
  deleteFile = (file) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(file);
    var r = window.confirm(`Are you sure you want to delete this project_file id : ${file.id} `);
    if (r == true) {
      var obj = { type: file.type, file_id: file.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}

export default withRouter(ProjectsViewingFiles);
