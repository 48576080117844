import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import HostsAdd from './HostsAdd';
import HostsEdit from './HostsEdit';
import HostsEditQueriesAdd from './HostsEditQueriesAdd';
import HostsAckAdd from './HostsAckAdd';
import HostsAckEdit from './HostsAckEdit';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import Filter from '../components/Filter';
import AlertBar from '../components/AlertBar';
import IconAdd from '../theming/icons/IconAdd';
import IconPositive from '../theming/icons/IconPositive';
import IconError from '../theming/icons/IconError';
import { hostColumns, hostFilterTypes as filterTypes } from '../api/schema/host';
import './Hosts.scss';
import axios from 'axios';

class Hosts extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New Host', href: '/glsmon/hosts/new' },
      { icon: null, linkText: 'Purchase Licenses', href: '/licenses' },
      { icon: null, linkText: '2 of 2 licenses remaining', href: null },
    ];

    this.tableOptionsModel = {
      type: 'hosts',
      title: 'Hosts',
      rowsPerPage: 5,
      currentPage: 1
    };
  }

  render() {
    let match = this.props.match;
    return (
      <div className="Hosts view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">
            <h1 className="header-bar">Host Overview</h1>
            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return <AlertBar key={i} alert={alert} showIcon />
              })}
            </div>
            <DataWrapper
              api="host"
              columns={hostColumns}
              filterTypes={filterTypes}
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
              panelHeader={this.panelHeader}
              actions={[
                {
                  name: "View",
                  type: "primary",
                  func: this.viewHost,
                  enabled: (record) => {
                    return true
                  }
                },
                {
                  name: "Ack",
                  type: "default",
                  func: this.ackHost,
                  enabled: (record) => {
                    return true
                  }
                },
                {
                  name: "Delete",
                  type: "primary",
                  func: this.deleteHost,
                  enabled: (record) => {
                    return true
                  }
                },
              ]}
            >
              {
                (props, state, actions) => (
                  <div>
                    <section className="view-section filters-section filters-section--expanded">
                      <header className="view-section-header">
                        <h3 className="view-section-title">Available Filters</h3>
                        <Filter expanded expandable
                          {...props}
                          {...state}
                          {...actions}
                        />
                      </header>
                    </section>
                    <section className="view-section hosts-section filters-section--expanded">
                      <DynamicTable
                        {...props}
                        {...state}
                        {...actions}
                      />
                    </section>
                  </div>
                )
              }
            </DataWrapper>
          </div>
        )} />
        <Switch>

          <Route exact path="/glsmon/hosts/new" component={HostsAdd} />

          <Route path="/glsmon/hosts/:hostid/queries/new" render={(props) => <HostsEditQueriesAdd viewTitle="Add Query" actionType="create" {...props} />} />
          <Route path="/glsmon/hosts/:hostid/queries/:queryid" render={(props) => <HostsEditQueriesAdd viewTitle="Edit Query" actionType="edit" {...props} />} />

          <Route path='/glsmon/hosts/:hostId/ack/new' render={(props) => <HostsAckAdd actionType="create" {...props} />} />
          <Route path='/glsmon/hosts/:hostId/ack/:id' render={(props) => <HostsAckEdit actionType="edit" {...props} />} />

          <Route exact path="/glsmon/hosts/:id/:subpage" component={HostsEdit} />
          <Route exact path="/glsmon/hosts/:id" component={HostsEdit} />

        </Switch>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Hostgroups are used to group Hosts, both to help visually organize large monitoring installations as well as to allow bulk configuration across similar or related hosts.</p>,
          <p>To register a host from its command line, see the Register PINs page. Hosts will appear in this list once they have registered themselves (or upon manual creation via browser), and will be licensed by default if any unused licenses are available.</p>,
          <p>Hosts are considered 'Down' when they have not reported within the past 5 minutes, at which point Notifications for Metrics on that Host (other than the 'Host Status' Metric itself) will be prevented until the Host begins reporting to the server again.</p>
        ]
      }
    ];
  }

  panelHeader = (item) => {
    return (
      <span className="panel-header-hosts inline-icon" href="/">
        {item.host_is_up ? <IconPositive /> : <IconError />}
        <a className="underline" href={`/glsmon/hosts/${item.id}`}>{item.name}</a>
      </span>
    );
  }

  viewHost = (host) => {
    this.props.history.push(`/glsmon/hosts/${host.id}`);
  }
  ackHost = (host) => {
    this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
  }
  deleteHost = (host) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(host);
    var r = window.confirm(`Are you sure you want to delete this host id : ${host.id} `);
    if (r == true) {
      var obj = { type: host.type ,host_id: host.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}

Hosts.propTypes = {
  alerts: PropTypes.array,
}

Hosts.defaultProps = {
  alerts: []
}

export default withRouter(Hosts);
