import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import WrappedDataForm from '../components/DataForm';
import { useAuth, useAuthDispatch, setAuthenticated } from '../AuthContext';
import { useUserDispatch } from '../UserContext';
import GLSAPI from '../api/api_config';
import './LoginForm.scss';
import axios from 'axios';

const LoginForm = () => {
  const [setData] = useState({});

  useEffect(() => {
    axios.get("https://www.serversynergy.com/glsmon/token")
      .then(function(response) {
        console.log(response.data);
        localStorage.setItem("user", JSON.stringify({'username':response.data}));
      }).catch(function(error) {
        console.log(error);
      })
  }, []);
  let authState = useAuth();
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  let renderForm = (defaultFormLayout) => {
    return (defaultFormLayout) => (
      <div>
        <Form.Item {...defaultFormLayout} name="username" initialValue={authState.user ? authState.user.username : ''}>
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item {...defaultFormLayout} password="password" name="password">
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Log in</Button>
        </Form.Item>
      </div>
    )
  }

  const onSubmitCallback = async (response) => {
    setAuthenticated(authDispatch, true);

    GLSAPI.endpoints['customer'].getAll()
      .then((response) => {
        userDispatch({ type: 'set_customers', payload: response.objects });

        // set the first customer as the active customer
        let customer = response.objects.length ? response.objects[0] : response.objects;

        if (customer) {
          userDispatch({ type: 'set_customer', payload: customer });
          window.location = "/glsmon/dashboard";
        }

      }).catch((error) => {
        if (error.message === "Authentication error") {
          message.destroy();
          message.error('Could not authenticate');
        } else {
          message.destroy();
          message.error('Something went wrong');
        }
      });
  }

  const onErrorCallback = (error) => {
    if (error.message === "Authentication error") {
      message.destroy();
      message.error('Could not authenticate');
    }
  }

  return (
    <div className="LoginForm">
      <WrappedDataForm
        title="Login"
        apiFunction={GLSAPI.endpoints.login}
        onSubmitCallback={onSubmitCallback}
        onErrorCallback={onErrorCallback}
        displayMessage={false}
      >
        {renderForm()}
      </WrappedDataForm>
    </div>
  );
}

export default LoginForm;
