import React from 'react';
import { Button, Form, Input, Col, Row, Select } from 'antd';
import WrappedDataForm from '../components/DataForm';
import BackLink from '../components/BackLink';
import './HostsEdit.scss';
import GLSAPI from '../api/api_config';
import { MinusCircleOutlined } from '@ant-design/icons';

class Metricsedit extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        let metricId = (props.match ? props.match.params.id : 57); // TEMP MOCK ID
        this.state = {
            metric: {},
            metricId,
            thresholdFields: [],
            outputFields: []
        }

    }

    componentDidMount(props) {
        this.setState({
            loading: true
        })

        GLSAPI.endpoints['metric'].getOne({ id: this.state.metricId })
            .then((response) => {
                let metric = response;
                let outputFields = metric.check_output_fields.map((field, index) => ({ id: index, ...field }));
                let thresholdFields = metric.default_thresholds.map((field, index) => ({ id: index, ...field }));

                this.setState({
                    metric: metric,
                    name: metric.name,
                    query_name: metric.query_name,
                    thresholdFields,
                    outputFields: outputFields.concat(this.state.outputFields)
                },
                    () => {
                        this.formRef.current.setFieldsValue({
                            name: this.state.metric.name,
                            query_name: this.state.metric.query_name,
                            default_thresholds: this.state.thresholdFields,
                            output_fields: this.state.outputFields
                        })
                    });
            }).catch((error) => {
                this.setState({
                    loading: false
                })
            });

    }

    onSubmitCallback = (host) => {
        this.props.history.push('/glsmon/metrics');
    }

    render() {

        return (
            <div className="Metricsedit view">
                <div className="page-area--tabbed">

                    <BackLink history={this.props.history} />

                    <h1 className="header-bar no-margin">Edit Metric</h1>

                    <section className="view-section filter-section">
                        <WrappedDataForm
                            title=""
                            apiFunction={GLSAPI.endpoints['metric'].edit}
                            apiResource="metric"
                            onSubmitCallback={this.onSubmitCallback}
                            hideRequiredMark
                            verbs={["Updating", "Updated"]}
                            initialValues={{
                                check_output_fields: this.state.outputFields,
                                default_thresholds: this.state.default_thresholds
                            }}
                            formRef={this.formRef}
                        >
                            {this.editMetricForm}
                        </WrappedDataForm>
                    </section>
                </div>
            </div>
        );
    }

    addThresholdField = () => {
        const fields = this.state.thresholdFields;
        const newFields = fields.concat({ id: this.nextThresholdFieldId, name: "" });
        this.setState({
            thresholdFields: newFields
        })
        this.nextThresholdFieldId++;
    }

    removeThresholdField = (removeId) => {
        const thresholdFields = this.state.thresholdFields;
        const newThresholdFields = thresholdFields.filter(field => field.id !== removeId);
        this.setState({
            thresholdFields: newThresholdFields
        });
    }

    editMetricForm = (defaultFormLayout) => (
        <div>
            <div className="">
                {this.state.metricId &&
                    <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.metricId}>
                        <Input type="hidden" />
                    </Form.Item>
                }
                <Form.Item label="Metric Name"
                    {...defaultFormLayout}
                    name="name"
                    initialValue={this.state.name}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Query Name"
                    {...defaultFormLayout}
                    name="query_name"
                    initialValue={this.state.query_name}
                >
                    <Input />
                </Form.Item>
                <Form.Item label={<span><span>Default Thresholds</span><span className="form-item-label-description"
                    name="default_thresholds"
                >If defined, incoming metric results are evaluated against these conditions in order from top to bottom. The first to be matched will decide the metric state. If none match, the metric is considered "Ok".</span></span>} {...defaultFormLayout}>


                    {
                        this.state.thresholdFields.length === 0 ?
                            <Col span={4}>
                                <div>
                                    <Button type="default" onClick={this.addThresholdField}>Add Row</Button>
                                </div>
                            </Col>
                            : null
                    }
                    {

                        this.state.thresholdFields.map((field, index) => {
                            return (
                                <div key={field.id}>
                                    <Row gutter={5}>
                                        <Col span={6}>
                                            <Form.Item name={['default_thresholds', index, 'metric_state_type_id']}
                                            >
                                                <Select
                                                    style={{ marginRight: 10 }}
                                                >
                                                    <Select.Option value={3} key={3}>Ok</Select.Option>
                                                    <Select.Option value={4} key={4}>Critical</Select.Option>
                                                    <Select.Option value={5} key={5}>Warning</Select.Option>
                                                    <Select.Option value={6} key={6}>Unknown</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item name={['default_thresholds', index, 'check_output_field_id']}
                                            >
                                                <Select
                                                >
                                                    {
                                                        this.state.outputFields.map((outputField, index) => {
                                                            return <Select.Option key={index} value={outputField.id}>{outputField.unit}</Select.Option>;
                                                        })
                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item name={['default_thresholds', index, 'compare_operator']}
                                            >
                                                <Select
                                                >
                                                    <Select.Option value={"<"}>{"<"}</Select.Option>
                                                    <Select.Option value={"<="}>{"<="}</Select.Option>
                                                    <Select.Option value={"=="}>{"=="}</Select.Option>
                                                    <Select.Option value={"!="}>{"!="}</Select.Option>
                                                    <Select.Option value={">="}>{">="}</Select.Option>
                                                    <Select.Option value={">"}>{">"}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item name={['default_thresholds', index, 'threshold_value']}
                                            >
                                                <Input placeholder={0}
                                                    style={{ paddingBottom: 2 }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        {index >= 1 ?
                                            <Col span={4} style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }} >
                                                <MinusCircleOutlined style={{ marginLeft: '5px', marginBottom: '15px' }} className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeThresholdField(field.id)} />
                                            </Col>
                                            : null

                                        }
                                    </Row>
                                    {
                                        this.state.thresholdFields.length === (index + 1) ?
                                            <Col span={4}>
                                                <div>
                                                    <Button type="default" onClick={this.addThresholdField}>Add Row</Button>
                                                </div>
                                            </Col>
                                            : null
                                    }

                                </div>
                            );
                        })
                    }
                </Form.Item>
            </div>
            <div className="view-action">
                <Form.Item>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
            </div>
        </div>
    );
}

export default Metricsedit;


