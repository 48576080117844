import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import DataWrapper from '../components/DataWrapper';
import AlertBar from '../components/AlertBar';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import IconPositive from '../theming/icons/IconPositive';
import IconError from '../theming/icons/IconError';
import IconWarning from '../theming/icons/IconWarning';
import IconUnknown from '../theming/icons/IconUnknown';
import { UserConsumer } from '../UserContext';
import { metricEventColumns, metricEventFilterTypes, metricEventActions } from '../api/schema/metricEvent';

class Events extends React.Component {

  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : 57);

    this.state = {
      resource: {},
      resourceId: resourceId,
      loading: true,
      error: {}
    }

    this.alerts = this.defaultAlerts().concat(this.props.alerts);
    this.toggler = { offLabel: 'Show All', onLabel: 'Show Problems', defaultChecked: true };
    this.tableOptionsModel = { type: 'metricEvents', title: 'Metric Events', rowsPerPage: 5, currentPage: 1 };
  }
  render() {
    let match = this.props.match;
    return (
      <div className="Events view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">
            <h1 className="header-bar">Metric Events</h1>
            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return <AlertBar key={i} alert={alert} showIcon />
              })}
            </div>
            <UserConsumer>
              {({ currentCustomer }) => (
                <DataWrapper
                  pageTitle="Metric Events"
                  api="metricevent"
                  requiredParams={{
                    customer_id: currentCustomer.id
                  }}
                  columns={metricEventColumns}
                  filterTypes={metricEventFilterTypes}
                  tableOptionsLinks={this.tableOptionsLinks}
                  tableOptionsModel={this.tableOptionsModel}
                  panelHeader={this.panelHeader}
                  actions={metricEventActions(this.props.history)}
                >
                  {
                    (props, state, actions) => (
                      <>
                        <section className="view-section filters-section filters-section--expanded">
                          <header className="view-section-header">
                            <h3 className="view-section-title">Available Filters</h3>
                            <Filter expanded expandable
                              {...props}
                              {...state}
                              {...actions}
                            />
                          </header>
                        </section>

                        <section className="view-section metrics-section">
                          <DynamicTable
                            {...props}
                            {...state}
                            {...actions}
                            panelHeader={this.panelHeader}
                          />
                        </section>
                      </>
                    )
                  }

                </DataWrapper>
              )}
            </UserConsumer>
          </div>
        )} />
        <Switch>
          <Route exact path={`/glsmon/events/:id`} render={() => { console.log('wrong path') }} />
        </Switch>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Metric Events record every time a Metric changes state (such as 'OK' to 'Critical', not mere output text changes). This page is linked a few times from the header with different url parameters to sort and filter in different ways - the active filters and sort priorities are listed below.</p>
        ]
      }
    ];
  }

  panelHeader = (item) => {
    let statusIcon;
    switch (item.status) {
      case "up":
        statusIcon = <IconPositive />;
        break;
      case "down":
        statusIcon = <IconError />;
        break;
      case "warning":
        statusIcon = <IconWarning />;
        break;
      default: statusIcon = <IconUnknown />
    }
    return (
      <span className="panel-header-events inline-icon" href="/">
        {statusIcon}
        <a className="underline" href="/" >{item.name}</a>
      </span>
    );
  };
}

Events.defaultProps = {
  alerts: []
}

export default withRouter(Events);
