import React from 'react';
import { Button } from 'antd';
import IconStar from '../../theming/icons/IconStar';

export const usersColumns = [
  {
    title: "Username",
    key: "username",
    render: (text, record) => record.star
      ? <span className="bold"><IconStar /> {record.username}</span>
      : <span className="bold"><span className="void-icon"></span>{record.name}</span>
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email"
  },
  {
    title: "Phone Number",
    key: "phone",
    dataIndex: "phone"
  },
  {
    title: "Customer Group",
    key: "customers",
    dataIndex: "customers",
    render: (text, record) => {
      let records = record.customers ? record.customers.slice(0, 2) : [];
      if (record.customers && record.customers.length > 2) {
        records.push({ name: `and ${record.customers.length - 2} more`, id: `extra-hosts` });
      }
      return records.map(record => <div key={record.id}>{record.name}</div>);
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];
