import React from 'react';
import { Input, Button, Checkbox, Select, Form, message } from 'antd';
import GLSAPI from '../api/api_config';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';

class ContactAddressAdd extends React.Component {
  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : null);

    this.state = {
      resource: {},
      fetched: this.props.actionType === 'create',
      resourceId: resourceId,
      loading: true
    }
  }

  componentDidMount(props) {
    if (this.props.actionType === "edit") {
      GLSAPI.endpoints['contactaddress'].getOne({ id: this.state.resourceId })
        .then((response) => {
          this.setState({
            loading: false,
            fetched: true,
            resource: response,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            fetched: false,
          });
        });
    }
  }

  render() {
    const { actionType } = this.props;
    this.apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['contactaddress'].create
      : GLSAPI.endpoints['contactaddress'].edit;
    return (
      <div className="ContactsAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />
          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} Contact Address</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="contact address"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            verbs={["Saving", "Saved"]}
            hideRequiredMark
          >
            {this.addContactAddressForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  onSubmitCallback = (contactaddress) => {
    this.props.history.push(`/glsmon/notifications/contacts`);
  }

  onErrorCallback = (error) => {
    if (error.message === 'Name already exists') {
      message.destroy();
      message.error('Name already exists');
    }
  }

  getCheckedValue = (e) => {
    return e.target.checked ? 1 : 0;
  };

  selectedMethodType = () => {
    if (this.state.resource.contact_method_type) {
      return this.state.resource.contact_method_type.id;
    } else {
      return "3";
    }
  }

  addContactAddressForm = (defaultLayoutForm) => {
    if (!this.state.fetched) return null;
    return (
      <div>
        <section className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultLayoutForm} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }
          <Form.Item label="Contact Name" {...defaultLayoutForm} name="name"
            initialValue={this.state.resource.name}
            rules={[{ required: true, message: 'Contact name is required' }]}
          >
            <Input placeholder="Contact Name" />
          </Form.Item>

          <Form.Item label={<span><span>Enabled</span><span className="form-item-label-description">If disabled, no messages will be sent to this contact address regardless of settings below.</span></span>} {...defaultLayoutForm}
            name="is_enabled"
            initialValue={this.state.resource.is_enabled}
            getValueFromEvent={this.getCheckedValue}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label={<span><span>Email Addresses</span><span className="form-item-label-description">Enter an email address, or a list of email addresses separated by commas or line breaks</span></span>} {...defaultLayoutForm}
            name="address"
            initialValue={this.state.resource.address}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="Method" {...defaultLayoutForm} name="contact_method_type_id" initialValue={this.selectedMethodType()}>
            <Select>
              <Select.Option value="3">Debug Email</Select.Option>
              <Select.Option value="2">Email</Select.Option>
              <Select.Option value="1">Log</Select.Option>
              <Select.Option value="4">Phone</Select.Option>
            </Select>
          </Form.Item>
        </section>
        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  }
}

export default ContactAddressAdd;
