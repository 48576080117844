import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export const checkColumns = [
  {
    title: "Check",
    key: "check",
    render: (text, record) => <Link to={`/glsmon/projects/${record.project.id}/checks/${record.id}`} className="underline">{record.name}</Link>
  },
  {
    title: "Path",
    key: "path",
    dataIndex: "relpath"
  },
  {
    title: "Interpreter",
    key: "interpreter",
    dataIndex: "interpreter",
    render: (text, record) => (record.interpreter)
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];

export const checkFilterTypes = {};
