import { setUser, removeUser } from '../auth-provider';
class API {
    constructor({ url }) {
        this.url = url;
        this.endpoints = {};
        this.activeCustomer = null;
        this.isAuthenticated = false;
    }

    setAuthenticated(isAuthenticated) {
        this.isAuthenticated = isAuthenticated;
    }

    setActiveCustomer(activeCustomer) {
        this.activeCustomer = activeCustomer;
    }

    checkIsAuthenticated = (name) => {
        return new Promise((resolve, reject) => {
            if (!this.endpoints[name].requireParams) {
                resolve(true);
            }

            if (this.isAuthenticated) {
                resolve(true);
            }
            reject(new Error('Not authenticated'));
        });
    }

    checkHasRequiredParams = (name) => {
        return new Promise((resolve, reject) => {
            if (!this.endpoints[name].requireParams) {
                resolve();
            }

            let requiredParams = this.requiredParams(name);

            let paramKeys = Object.keys(requiredParams);
            for (let i = 0; i < paramKeys.length; i++) {
                if (requiredParams[paramKeys[i]] === null || requiredParams[paramKeys[i]] === undefined) {
                    reject(new Error(`Missing required param: ${paramKeys[i]}`));
                }
            }
            resolve();
        });
    }

    beforeEach(name) {
        return this.checkIsAuthenticated(name).then(() => { return this.checkHasRequiredParams(name) });
    }

    createAuthEndpoints() {
        const resourceURL = `${this.url}/auth/api`;

        this.endpoints.login = ((params) => {
            var formData = new FormData();
            formData.append('api', `{"action": "login", "username":"${params.username}","password":"${params.password}"}`);
            return fetch(resourceURL, {
                method: 'POST',
                body: JSON.stringify({ "action": "login", ...params }),
                credentials: 'include'
            })
                .then(response => {
                    if (response.ok) {
                        setUser({ username: params.username });
                        return response;
                    }

                    let authError = new Error();
                    authError.message = "Authentication error";
                    throw authError;
                })
                .then(this.handleErrors);
        });

        this.endpoints.logout = (() => {
            return fetch(resourceURL, {
                headers: { "Content-Type": "application/json" },
                method: 'POST',
                body: JSON.stringify({ "action": "logout" }),
                credentials: 'include'
            })
                .then(() => {
                    this.isAuthenticated = false;
                    removeUser();
                })
                .then(this.handleErrors)
                .then(this.parseResponse);
        });
    }

    createEntity(entity) {
        this.endpoints[entity.name] = this.createEndpoints(entity);
    }

    createEndpoints({ name, requireParams = true }) {
        var endpoints = {};
        endpoints.requireParams = requireParams;
        const resourceURL = `${this.url}/${name}/api`;

        endpoints.getAll = ((params) => {
            return this.beforeEach(name)
                .then(() => {
                    return fetch(resourceURL, {
                        headers: { "Content-Type": "application/json" },
                        method: 'POST',
                        body: JSON.stringify({ "action": "list", ...params, ...this.requiredParams(name) }),
                        credentials: 'include',
                        mode: 'cors'
                    })
                })
                .then(this.handleErrors)
                .then(this.parseResponse);
        });

        endpoints.getAllPages = (next = 1, objectData = []) => {
            let pageParams = {
                page: next,
                page_rows: 100
            };

            return new Promise((resolve, reject) => fetch(resourceURL, {
                headers: { "Content-Type": "application/json" },
                method: 'POST',
                body: JSON.stringify({ "action": "list", ...pageParams, ...this.requiredParams(name) }),
                credentials: 'include',
                mode: 'cors'
            }).then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                response.json().then((data) => {
                    objectData = objectData.concat(data.objects);

                    if (data.list_meta) {
                        let meta = data.list_meta;
                        if (next <= meta.page_count) {
                            this.endpoints[name].getAllPages(meta.page + 1, objectData).then(resolve).catch(reject);
                        } else {
                            resolve(objectData);
                        }
                    } else {
                        reject();
                    }
                }).catch(reject);
            }).catch(reject));
        };

        endpoints.getOne = (({ id }) => {
            return fetch(resourceURL, {
                headers: { "Content-Type": "application/json" },
                method: 'POST',
                body: JSON.stringify({ "action": "view", "id": id, ...this.requiredParams(name) }),
                credentials: 'include'
            })
                .then(this.handleErrors)
                .then(this.parseResponse);
        });

        endpoints.create = ((data) => {
            return fetch(resourceURL, {
                headers: { "Content-Type": "application/json" },
                method: 'POST',
                body: JSON.stringify({ "action": "add", ...data, ...this.requiredParams(name) }),
                credentials: 'include',
                mode: 'cors'
            })
                .then(this.handleErrors)
                .then(this.parseResponse);
        });

        endpoints.edit = ((data) => {
            return fetch(resourceURL, {
                headers: { "Content-Type": "application/json" },
                method: 'POST',
                body: JSON.stringify({ "action": "edit", ...data, ...this.requiredParams(name) }),
                credentials: 'include',
                mode: 'cors'
            })
                .then(this.handleErrors)
                .then(this.parseResponse);
        });


        if (name === 'host' || name === 'metric') {
            endpoints.graphMetaData = ((id) => {
                return fetch(resourceURL, {
                    headers: { "Content-Type": "application/json" },
                    method: 'POST',
                    body: JSON.stringify({ "action": "view", "id": id, ...this.requiredParams(name) }),
                    credentials: 'include',
                    mode: 'cors'
                })
                    .then(this.handleErrors)
                    .then(this.parseResponse);
            });
        }

        if (name === 'host') {
            endpoints.register = ((data) => {
                return fetch(`${this.url}/register/token`, {
                    headers: { "Content-Type": "application/json" },
                    method: 'POST',
                    body: JSON.stringify({
                        "name": data.name,
                        ...this.requiredParams(name)
                    }),
                    credentials: 'include',
                    mode: 'cors'
                })
                    .then(this.handleErrors)
                    .then(this.parseResponse);

            })
        }

        return endpoints;
    }

    requiredParams(endpointName) {
        let requiredParams = {};

        if (this.endpoints[endpointName].requireParams) {
            requiredParams['customer_id'] = this.activeCustomer ? this.activeCustomer.id : null;
        }
        return requiredParams;
    }

    parseResponse(response) {
        if (response.ok) {
            return response.json();
        }

        return response;
    }

    handleErrors(response) {
        if (!response.ok) {
            return response.json().then((body) => {
                if (response.status === 404) {
                    throw new Error('Not found');
                } else {
                    if (body.msg && body.msg.err) {
                        throw new Error(body.msg.err);
                    } else {
                        throw new Error('Something went wrong');
                    }
                }
            })
        }
        return response;
    }
}

export default API;
