import React from 'react';
import { Collapse } from 'antd';
import './Help.scss';

const Panel = Collapse.Panel;

class Help extends React.Component {
  render() {
    return (
      <div className="Help view">
        <div className="page-area--notabs">
          <Collapse defaultActiveKey={['1']} bordered={false}>
            <Panel header="Adding a Host" key="1" showArrow={false}>
              <ul>
                <li>Verify your Customer account has free host license(s), or add more if needed.</li>
                <li>Find or create a Register PIN with active use(s) remaining</li>
                <li>Delete or move aside the client's old /usr/libexec/glsmon/ and perhaps /var/glsmon-client/ directories if they exist. If old data remains it may confuse things and fail to update from the server properly.'</li>
                <li>(Maybe?) Install the 'gls-monitor' package from the linuxglobal repo on the new host, if not already done. (This has not been updated recently as of this writing, so the gls-checker script and cron job are obsolete, but it may still install some other prerequisite packages from yum)</li>
                <li>Install prerequisites. perl-Params-Check is newly required (not sure from what), and was available via yum on the current server. Other than that I believe it should require whatever was required last time we installed clients, though I don't have that list handy as of this writing. Most required perl packages are included in the FatPacked script itself, but a few may need installed outside of that.</li>
                <li>Make sure the host's /usr/bin/gls-checker script has been updated to the new (Feb 2018) version, and that /etc/cron.d/gls-something agrees with its calling syntax (gls-checker --cron metrics)</li>
                <li>Run '/usr/bin/gls-checker register --customer $cust --host-ident $host --pin $pin', substituting variables.</li>
                <li>If all goes well, it should update itself and begin reporting metrics within a minute or two</li>
                <li>Optionally, run 'gls-checker update' then 'gls-checker metrics' manually to sync files and config and send initial results for all configured Queries.</li>
              </ul>
            </Panel>
            <Panel header="Troubleshooting a Non-Reporting Host" key="2" showArrow={false}>
              <ul>
                <li>Run 'gls-checker -v 1 metrics' for verbose/debug output that may suggest a cause</li>
                <li>Verify its /etc/cron.d/gls-monitor cron is active and not commented.</li>
              </ul>
            </Panel>
            <Panel header="Configuring a Host" key="3" showArrow={false}>
              <ul>
                <li>In the Host edit page, assign Projects to have their files synced onto the Host. This will automatically create Queries on the Host from all Checks available in that Project. (This will eventually give the option to pick and choose)</li>
                <li>In the Project edit page, ensure all used Projects are set to sync server_to_client, or files will not be pushed to the host. (Yes, this should someday integrate better into the above point)</li>
                <li>If not all Checks are applicable for the Host, delete the unwanted Queries. These can be most easily found by using the 'Query' link in the 'Children' column of the Host list page.</li>
              </ul>
            </Panel>
            <Panel header="Creating a New Check" key="4" showArrow={false}>
              <ul>
                <li>Find or create a Project to contain the new Check</li>
                <li>Find or create a File within that Project</li>
                <li>Create a Check referencing the Project and File path</li>
                <li>To assign to Hosts, create Queries referencing the newly created Check, and ensure that the Project containing the Check's File is linked to the intended Host.</li>
              </ul>
            </Panel>
            <Panel header="Viewing Current Status" key="5" showArrow={false}>
              <ul>
                <li>All of the 'Metrics' header menu, except 'Queries', are views of Metric results.</li>
                <li>For current and historical details about the state of a specific Metric, follow the 'View' link in the 'Actions' column of the Metric list.</li>
                <li>The 'All Problems' header link will list all currently non-OK Metrics</li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default Help;