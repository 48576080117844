import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Layout, Menu, Badge, Row } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import AccountMenuItem from './AccountMenuItem';

import './SiteHeader.scss';

const { Header } = Layout;

class SiteHeader extends React.Component {

    mainMenuIsActive(match, location) {
        if (match) {
            if (match.url.split('/')[1] === location.pathname.split('/')[1]) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    mainMenu() {
        return this.props.mainMenu.map((item) => {
            return (
                <Menu.Item key={item.key}>
                    <NavLink to={item.path} activeClassName="active" >{item.title}</NavLink>
                </Menu.Item>
            );
        });
    };

    accountMenu() {
        return this.props.accountMenu.map((item) => {
            if (item.key === 'alerts') {
                return (
                    <Menu.Item key={item.key} title={item.title} className="alerts">
                        <Badge count={item.badgeCount}>
                            <ExclamationCircleFilled />
                        </Badge>
                        <span className="icon-text">{item.title}</span>
                    </Menu.Item>
                )
            } else if (item.key === 'account') {
                return (
                    <AccountMenuItem key={item.key} subMenu={this.props.accountSubMenu} badgeCount={item.badgeCount} customerName={this.props.customerName} />
                )
            } else {
                return (
                    <Menu.Item key={item.key} title={item.title}>
                        <Badge count={item.badgeCount}>
                            <span>{item.title}</span>
                        </Badge>
                    </Menu.Item>
                )
            }
        })
    };
    remove() {
        localStorage.removeItem('user');
    }
    render() {
        return (
            <Header className="SiteHeader">
                <Row className="info-row">
                    <div className="logo">
                        <Link to="/">
                            {
                                this.props.logo
                            }
                        </Link>
                    </div>
                    <Menu className="info-menu" mode="horizontal">
                        {
                            this.accountMenu()
                        }
                    </Menu>
                </Row>
                <Row className="menu-row">
                    <Menu className="main-menu" mode="horizontal"
                    >
                        {
                            this.mainMenu()
                        }
                    </Menu>
                    <div className="licenses" mode="horizontal">
                        <a href="https://www.serversynergy.com" onClick={this.remove.bind(this)}>
                            <p>Log Out</p>
                        </a>
                    </div>
                    <div>
                        <a href="">
                            <p>Purchase Licenses</p>
                        </a>
                    </div>
                </Row>
            </Header>
        )
    };
}

SiteHeader.propTypes = {
    logo: PropTypes.element,
    accountMenu: PropTypes.arrayOf(PropTypes.object),
    mainMenu: PropTypes.arrayOf(PropTypes.object),
    customerName: PropTypes.string,
}

SiteHeader.defaultProps = {
    accountMenu: [],
    accountSubMenu: [],
    mainMenu: [],
    customerName: 'Customer Name'
}

export default withRouter(SiteHeader);
