import API from '../api/API';

export const apiURL = 'https://www.serversynergy.com/glsmon';

const GLSAPI = new API({ url: apiURL });

GLSAPI.createEntity({ name: 'metric' });
GLSAPI.createEntity({ name: 'customer', requireParams: false });
GLSAPI.createEntity({ name: 'user', requireParams: false });
GLSAPI.createEntity({ name: 'invite' });
GLSAPI.createEntity({ name: 'hostack' });
GLSAPI.createEntity({ name: 'host' });
GLSAPI.createEntity({ name: 'query' });
GLSAPI.createEntity({ name: 'check' });
GLSAPI.createEntity({ name: 'contactaddress' });
GLSAPI.createEntity({ name: 'contactnode' });
GLSAPI.createEntity({ name: 'timeperiod' });
GLSAPI.createEntity({ name: 'project' });
GLSAPI.createEntity({ name: 'hostgroup' });
GLSAPI.createEntity({ name: 'file' });
GLSAPI.createEntity({ name: 'notification' });
GLSAPI.createEntity({ name: 'metricevent' });
GLSAPI.createEntity({ name: 'metricack' });
GLSAPI.createEntity({ name: 'registerpin' });
GLSAPI.createEntity({ name: 'ghost' });

GLSAPI.createAuthEndpoints();

export default GLSAPI;
