import React from 'react';
import { Select, Button, Input, Form, message } from 'antd';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';
import BackLink from '../components/BackLink';

class CustomerAccountsAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource: {},
      availableCustomers: []
    }

  }

  componentDidMount() {
    GLSAPI.endpoints['customer'].getAllPages()
      .then((response) => {
        let customers = response.map((item, id) => { return { ...item, key: id } });

        this.setState({
          availableCustomers: customers
        });
      }).catch((error) => {
      })
  }

  render() {
    const { viewTitle, actionType } = this.props;
    const apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['customer'].create
      : GLSAPI.endpoints['customer'].edit;

    return (
      <div className="CustomerAccountsAdd view">
        <div className="page-area--tabbed">

          <BackLink {...this.props} />

          <h1 className="header-bar">{viewTitle}</h1>

          <WrappedDataForm
            title=""
            apiFunction={apiFunction}
            apiResource="user"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            verbs={["Inviting", "Invited"]}
          >
            {this.addCustomerForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  onErrorCallback = (error) => {
    if (error.message === "This customer is already assigned to this user") {
      message.destroy();
      message.error('This customer already exists for this user.');
    }
  }


  onSubmitCallback = () => {
    this.props.history.push('/glsmon/users');
  }

  addCustomerForm = (defaultFormItemLayout) => {
    return (
      <div>
        <div className="view-section view-section--no-border">
          <Form.Item label="Invite Email" {...defaultFormItemLayout} name="invite_email" rules={[{ required: true, message: 'An email address is required' }]}>
            <Input placeholder="myemail@example.com" />
          </Form.Item>
          <Form.Item label="Customer" {...defaultFormItemLayout} name="cust_name" initialValue={this.state.resource.name}>
            <Select>
              {
                this.state.availableCustomers.map((resource) => {
                  return <Select.Option value={resource.name} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

        </div>
        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  }

}

export default CustomerAccountsAdd;
