import React from 'react';
import { Row, Col } from 'antd';

class Page extends React.Component {

  render() {
    return (
      <main className="Page outer-wrap">
        <div className="inner-wrap page-area">
          <Row>
            <Col span={24}>

              {/* If routing is handled on client side, replace this.props.view with routes */}
              {this.props.view}

            </Col>
          </Row>
        </div>
      </main>
    );
  }
}

export default Page;