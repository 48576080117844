import React from 'react';
import { Button, Form, Input, Checkbox } from 'antd';
import ResourceInfo from '../components/ResourceInfo';
import TabbedPage from '../components/TabbedPage';
import WrappedDataForm from '../components/DataForm';
import MultiSelector from '../components/MultiSelector';
import BackLink from '../components/BackLink';
import HostsEditQueries from './HostsEditQueries';
import HostsEditProjects from './HostsEditProjects';
import HostEditEnvironment from './HostsEditEnvironment';
import { hostIcon } from '../theming/_props-mock/ResourceInfoProps';
import { hostWidgets, hostLabelMap } from '../api/schema/host';
import './HostsEdit.scss';
import GLSAPI from '../api/api_config';

class HostsEdit extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    let hostId = (props.match ? props.match.params.id : 57); // TEMP MOCK ID
    this.state = {
      host: {},
      hostId,
      selectedHostGroups: [],
      availableHostgroups: []
    }

  }

  componentDidMount(props) {
    this.setState({
      loading: true
    })

    GLSAPI.endpoints['host'].getOne({ id: this.state.hostId })
      .then((response) => {
        let host = response;

        this.setState({
          host: host,
          selectedHostGroups: host.hostgroups,
          is_licensed: host.is_licensed
        },
          () => {
            this.formRef.current.setFieldsValue({
              is_licensed: this.state.host.is_licensed,
            })
          });
      }).catch((error) => {
        this.setState({
          loading: false
        })
      });

    GLSAPI.endpoints['hostgroup'].getAllPages()
      .then((response) => {
        let hostgroups = response.map((item, i) => { return { ...item, key: item.id } });

        this.setState({
          availableHostgroups: hostgroups
        });
      })

  }

  onSubmitCallback = (host) => {
    this.props.history.push('/glsmon/hosts');
  }

  onTitleClick = (id) => {
    this.props.history.push(`/glsmon/hosts/${id}/edit`);
  }

  getCheckedValue = (e) => {
    if (e.target.checked === false) {
      this.setState({ is_licensed: 0 });
      return e.target.checked ? 1 : 0;
    }
    else {
      this.setState({ is_licensed: 1 });
      return e.target.checked ? 1 : 0;
    }
  };


  render() {
    const match = this.props.match;
    var subPage = 'queries';

    if (match && match.params.subpage) {
      subPage = match.params.subpage;
    }

    return (
      <div className="HostsEdit view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">Viewing Host</h1>

          <section className="view-section view-section--no-border">
            <ResourceInfo
              resource={this.state.host}
              resourceIcon={hostIcon}
              widgets={hostWidgets}
              labelMap={hostLabelMap}
              onTitleClick={this.onTitleClick}
            />
          </section>

          <section className="view-section filter-section">
            <WrappedDataForm
              title=""
              apiFunction={GLSAPI.endpoints['host'].edit}
              apiResource="host"
              onSubmitCallback={this.onSubmitCallback}
              hideRequiredMark
              verbs={["Updating", "Updated"]}
              formRef={this.formRef}
            >
              {this.editHostForm}
            </WrappedDataForm>
          </section>

          <TabbedPage
            isContainer={false}
            router={{ match: this.props.match, history: this.props.history }}
            activeTab={subPage}
            tabs={[
              {
                title: 'Queries',
                key: 'queries',
                content: <HostsEditQueries hostId={this.state.hostId} {...this.props} />
              },
              {
                title: 'Projects',
                key: 'projects',
                content: <HostsEditProjects hostId={this.state.hostId} {...this.props} />
              },
              {
                title: 'Environment',
                key: 'environment',
                content: <HostEditEnvironment hostId={this.state.hostId} host={this.state.host} {...this.props} />
              },
            ]}
          />
        </div>
      </div>
    );
  }

  editHostForm = (decoratorFunction, defaultFormLayout) => (
    <div>
      <div className="">
        {this.state.hostId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.hostId}>
            <Input type="hidden" />
          </Form.Item>
        }
        <Form.Item label="Licensed"
          {...defaultFormLayout}
          name="is_licensed"
          initialValue={this.state.is_licensed}
          valuePropName="checked"
          getValueFromEvent={this.getCheckedValue}
        >
          <Checkbox defaultChecked={this.state.is_licensed} />
        </Form.Item>
        <Form.Item label="" name="hostgroups">
          <MultiSelector
            dataSource={this.state.availableHostgroups}
            targetKeys={this.state.selectedHostGroups}
            titles={["Available Hostgroups", "Selected hostgroups"]}
          />
        </Form.Item>
      </div>
      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  );
}

export default HostsEdit;

