import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RegisterPinAdd from './RegisterPinAdd';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import AlertBar from '../components/AlertBar';
import IconAdd from '../theming/icons/IconAdd';
import IconStar from '../theming/icons/IconStar';
import { pinColumns } from '../api/schema/registerpin';
import axios from 'axios';

export default class RegisterPin extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New Pin', href: '/glsmon/registerpin/new' }
    ];

    this.tableOptionsModel = { type: 'registerpin', title: 'Registerpin', rowsPerPage: 5, currentPage: 1 };

  }

  render() {
    let match = this.props.match;
    return (
      <div className="RegisterPin view">
        <Route exact path={match.url} render={() => (
          <div className="page-area">
            <h1 className="header-bar">Register Pins</h1>
            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return <AlertBar key={i} alert={alert} showIcon />
              })}
            </div>

            <DataWrapper
              api="registerpin"
              columns={pinColumns}
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
              panelHeader={this.panelHeader}
              actions={[
                {
                  name: "Edit",
                  type: "primary",
                  func: this.viewPins,
                  enabled: (record) => {
                    // create a rule to determine if the action is available
                    return true
                  }
                },
                {
                  name: "Delete",
                  type: "primary",
                  func: this.deletePins,
                  enabled: (record) => {
                    return true
                  }
                },
              ]}
            >
              {
                (props, state, actions) => (
                  <section className="view-section accounts-section">
                    <DynamicTable
                      {...state}
                      {...props}
                      {...actions}
                    />
                  </section>
                )
              }
            </DataWrapper>
          </div>
        )} />
        <Switch>
          <Route
            path="/glsmon/registerpin/new"
            render={props => <RegisterPinAdd viewTitle="Add Pin" actionType="create" {...props} />}
          />
          <Route
            path="/glsmon/registerpin/:id"
            render={props => <RegisterPinAdd viewTitle="Edit Pin" actionType="edit" {...props} />}
          />
        </Switch>
      </div>
    );
  }

  viewPins = (record) => {
    this.props.history.push('/glsmon/registerpin/' + record.id);
  }
  deletePins = (record) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(record);
    var r = window.confirm(`Are you sure you want to delete this registerpin id : ${record.id} `);
    if (r == true) {
      var obj = { type: record.type, registerpin_id: record.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }

  panelHeader = (item) => {
    return (
      <span className="panel-header-customer inline-icon" href="/">
        {item.star ? <IconStar /> : <span className="void-icon--nowidth"></span>}{item.name}
      </span>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Installation PINs are used when configuring a new host to monitor. You must generate a PIN before installing a host. This allows you to register a host with a Customer name and one-time (or optionally greater) use PIN instead of having to type your username and password into a potentially untrusted host. These installation PINs can be considered analogous to an S/KEY (Lamport scheme).</p>
        ]
      }
    ];
  }
}

RegisterPin.defaultProps = {
  alerts: []
}
