import React from 'react'

const IconViewable = props => {
  const { width, height } = props;

  return (
    <svg className="IconViewable gls-icon" width={width || "25px"} height={height || "15px"} viewBox="0 0 25 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-1048.000000, -5290.000000)">
          <g id="Group-25" transform="translate(928.000000, 5273.000000)">
            <g id="viewable" transform="translate(108.000000, 0.000000)">
              <rect id="Rectangle-Copy" x="0" y="0" width="48" height="48"></rect>
              <g id="flaticon1554854607-svg" transform="translate(24.500000, 24.500000) scale(1, -1) translate(-24.500000, -24.500000) translate(12.000000, 17.000000)" fill="#495466" fillRule="nonzero">
                <path d="M12.5,0 C7.72348369,0 3.39188913,2.63040494 0.195612151,6.90288264 C-0.0652040503,7.25291695 -0.0652040503,7.74193548 0.195612151,8.0919698 C3.39188913,12.3695951 7.72348369,15 12.5,15 C17.2765163,15 21.6081109,12.3695951 24.8043878,8.09711736 C25.0652041,7.74708305 25.0652041,7.25806452 24.8043878,6.9080302 C21.6081109,2.63040494 17.2765163,0 12.5,0 Z M12.8426409,12.7814001 C9.67193413,12.9821551 7.05354403,10.3517502 7.25299172,7.15511325 C7.4166411,4.51956074 9.53896901,2.38332189 12.1573591,2.21859986 C15.3280659,2.01784489 17.946456,4.64824983 17.7470083,7.84488675 C17.5782449,10.4752917 15.4559169,12.6115305 12.8426409,12.7814001 Z M12.6841056,10.341455 C10.9760151,10.4495539 9.56453922,9.03397392 9.67704817,7.31468771 C9.76398691,5.89396019 10.9095326,4.74605353 12.3210085,4.65339739 C14.0290989,4.54529856 15.4405748,5.96087852 15.3280659,7.68016472 C15.2360131,9.10603981 14.0904674,10.2539465 12.6841056,10.341455 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconViewable;
