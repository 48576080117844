import React from 'react';
import { withRouter } from 'react-router-dom';
import DataWrapper from '../components/DataWrapper';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import IconAdd from '../theming/icons/IconAdd';
import IconError from '../theming/icons/IconError';
import IconPositive from '../theming/icons/IconPositive';
import { projectColumns, projectFilterTypes } from '../api/schema/project';

class HostgroupsViewingProjects extends React.Component {
  constructor(props) {
    super(props);
    this.parentResourceId = props.match.params ? parseInt(props.match.params.id) : null;
    console.log(props, this.parentResourceId);
    console.log(this.parentResourceId);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Add Project', href: '/glsmon/projects/new' },
    ];

    this.tableOptionsModel = {
      type: 'projects',
      title: 'Projects',
      rowsPerPage: 5,
      rowCount: 9,
      currentPage: 1
    };
  }
  render() {
    return (
      <div className="HostgroupsViewingProjects nested-tab-view">
        <DataWrapper
          pageTitle="Projects"
          api="project"
          columns={projectColumns}
          filterTypes={projectFilterTypes}
          requiredParams={{ hostgroup: this.parentResourceId }}
          panelHeader={this.panelHeader}
          tableOptionsLinks={this.tableOptionsLinks}
          tableOptionsModel={this.tableOptionsModel}
          actions={[
            {
              name: "View",
              type: "primary",
              func: this.viewProject,
              enabled: (record) => {
                // create a rule to determine if the action is available
                return true
              }
            },
          ]}>
          {
            (props, state, actions) => (
              <>
                <section className="view-section filters-section filters-section--expanded">
                  <header className="view-section-header">
                    <h3 className="view-section-title">Available Filters</h3>
                    <Filter expanded expandable
                      {...props}
                      {...state}
                      {...actions}
                      availableFilters={state.availableFilters.filter((filter) => filter.key !== "hostgroup")}
                    />
                  </header>
                </section>

                <section className="view-section">
                  <DynamicTable
                    {...props}
                    {...state}
                    {...actions}

                  />
                </section>
              </>
            )
          }
        </DataWrapper>
      </div>
    )
  }

  viewProject = (project) => {
    this.props.history.push(`/glsmon/projects/${project.id}`);
  }

  panelHeader = item => {
    const icon = item.public
      ? <IconPositive />
      : <IconError />

    return <span className="bold inline-icon">{icon} {item.name}</span>;
  }
}

export default withRouter(HostgroupsViewingProjects);
