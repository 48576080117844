import React from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';
import MultiSelector from '../components/MultiSelector';
import BackLink from '../components/BackLink';

class UsersAdd extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    let resourceId = (props.match ? props.match.params.id : 0);
    this.state = {
      resourceId,
      resource: {},
      availableCustomers: [],
      selectedCustomers: [],
      method: "",
      drop1: true,
      drop2: true
    }

    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['user'].create
      : GLSAPI.endpoints['user'].edit;
  }

  componentDidMount() {
    if (this.props.actionType === 'edit') {
      GLSAPI.endpoints['user'].getOne({ id: this.state.resourceId })
        .then((response) => {
          let resource = response;
          console.log(resource);
          if (resource.contact_method_type_id === 2) {
            this.setState({ method: 2 });
            this.setState({ drop1: false });
            this.setState({ drop2: true });
          } else {
            this.setState({ method: 4 });
            this.setState({ drop2: false });
            this.setState({ drop1: true });
          }
          let selectedCustomers = resource.customer_id ? resource.customer_id : [];
          this.setState({
            resource,
            selectedCustomers
          },
            () => {
              this.formRef.current.setFieldsValue({
                username: this.state.resource.username,
                contact_method_type_id: this.state.resource.contact_method_type_id,
                email: this.state.resource.email,
                phone: this.state.resource.phone
              })
            });
        })
        .catch((error) => {
          message.error(`Something went wrong while getting user.`, 5);
        })
    }

    GLSAPI.endpoints['customer'].getAll({ page_rows: 100, page: 1 })
      .then((response) => {
        let customers = response.objects.map((item, i) => { return { ...item, key: item.id } });
        this.setState({
          availableCustomers: customers,
        });
      }).catch((error) => {
        message.error(`Something went wrong while fetching customers.`, 5);
      })

  }

  render() {

    return (
      <div className="UsersAdd view">
        <div className="page-area--tabbed">
          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} User</h1>


          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="user"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            verbs={this.getVerbs()}
            formRef={this.formRef}
          >
            {this.addUserForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  onSubmitCallback = (user) => {
    this.props.history.push('/glsmon/users');
  }

  selectedMethodType = () => {
    if (this.state.resource.contact_method_type) {
      return this.state.resource.contact_method_type.id;
    } else {
      return " ";
    }
  }

  onErrorCallback = (error) => {
    if (error.message === "Name already exists") {
      message.destroy();
      message.error('Name already exists. Please try a different name.');
    }
  }

  selectchange = (value) => {
    if (value == 2) {
      this.setState({ method: 2 });
      this.setState({ drop1: false });
      this.setState({ drop2: true });
    } else {
      this.setState({ method: 4 });
      this.setState({ drop2: false });
      this.setState({ drop1: true });
    }
  };

  addUserForm = (defaultFormLayout) => (
    <div>
      <div className="view-section view-section--no-border">
        {this.state.resourceId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
            <Input type="hidden" />
          </Form.Item>
        }

        <Form.Item label="Username" {...defaultFormLayout} name="username" initialValue={this.state.resource.username} rules={[{ required: true, type: "email", message: ' ' }]} hasFeedback>
          <Input placeholder="Username" disabled={this.props.actionType === 'edit'} />
        </Form.Item>

        <Form.Item label={<span><span>Password</span><span className="form-item-label-description">
          If modifiying an existing user, leave this empty to not change the password. This should be split into its own 'Change Password' page at some point.
        </span></span>
        }
          {...defaultFormLayout}
          name="password"
          rules={[
            { required: (this.props.actionType === 'create' ? true : false), message: 'A password is required' },
            { min: 8, message: "Password must be minimum 8 characters." }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item label="Confirm Password" {...defaultFormLayout}
          name="confirm_password"
          rules={[
            { required: (this.props.actionType === 'create' ? true : false), message: 'Password confirmation is required' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords must match'));
              }
            })
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item label="Method Type" {...defaultFormLayout} name="contact_method_type_id" rules={[{ required: true, message: 'Please select a contact type!' }]}>
          <Select placeholder="Method Type" onChange={this.selectchange} defaultValue={this.selectedMethodType()}>
            <Select.Option value={2}>Email</Select.Option>
            <Select.Option value={4}>Phone</Select.Option>
          </Select>
        </Form.Item>

        {this.state.method === 2 ?
          <div>
            <Form.Item label={<span><span>Email </span><span className="form-item-label-description">Enter your email address </span></span>} {...defaultFormLayout}
              name="email"
              rules={[{ required: true, message: 'Email is required.' }]}
            >
              <Input placeholder="Email" disabled={this.state.drop1} />
            </Form.Item>
          </div>
          :
          this.state.method === 4 ?
            <div>
              <Form.Item label={<span><span>Phone Number</span><span className="form-item-label-description">Enter your phone number </span></span>} {...defaultFormLayout}
                name="phone"
                rules={[{ required: true, message: 'Phone is required.' }]}
              >
                <Input placeholder="Phone" disabled={this.state.drop2} />
              </Form.Item>
            </div> : null
        }
      </div>
      {this.props.actionType === 'create' ?
        <div className="view-section">
          <Form.Item label="Customers" labelCol={{ xs: { span: 24 } }} colon={false} name="customer_id" initialValue={this.state.selectedCustomers} >
            <MultiSelector
              dataSource={this.state.availableCustomers}
              targetKeys={this.state.selectedCustomers}
              titles={["Available Customers", "Added Customers"]}
            />
          </Form.Item>
        </div>
        :
        <div></div>
      }

      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  );
}

export default UsersAdd;
