import React from 'react';

import SiteHeader from './SiteHeader';
import { logo, mainMenu, accountMenu, accountSubMenu } from '../config/header';

/* Receives and passes props into SiteHeader */
class Header extends React.Component {

  render() {
    return (
      <SiteHeader
        logo={logo}
        mainMenu={mainMenu}
        accountMenu={accountMenu}
        accountSubMenu={accountSubMenu}
        customerName="Will Newman"
        {...this.props}
      />
    );
  }
}

export default Header;
