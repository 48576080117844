import React from 'react';
import { Button, Input, Select, Form, message } from 'antd';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';
// import CustomerBar from '../components/CustomerBar';
import './HostsAdd.scss';
import { UserStateContext } from '../UserContext';
import axios from 'axios';

class HostsRegister extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    console.log(props);
    let resourceId = (props.match ? props.match.params.id : null); // TEMP MOCK ID
    this.state = {
      resource: {},
      resourceId: resourceId,
      availableCustomers: [],
      fetched: false,
      loading: true,
      customers: [],
      ghost: {}
    }
    // this.apiFunction = GLSAPI.endpoints['host'].register;
    this.loadData1 = this.loadData1.bind(this);
  }
  loadData1() {
    axios
      .get("https://www.serversynergy.com/glsmon/ghost")
      .then(res => {
        console.log(res);
        this.setState({ ghost: res.data },
            () => {
              this.formRef.current.setFieldsValue({
                name: this.state.ghost.host_ident
              })
            });
      })
      .catch(error => {
        //  this.setState({
        //  errorMsg: `Error retreiving todos data. Detailed error : ${error}`
        // });
      });
  }
  // componentDidMount() {
  //   this.loadData1();
  // }
  componentDidMount(props) {
    this.loadData1();
    this.setState({
      loading: true
    })

    if (this.props.actionType === "edit") {
      GLSAPI.endpoints['host'].getOne({ id: this.state.resourceId })
        .then((response) => {
          this.setState({
            resource: response,
            loading: false,
            fetched: true
          });
        }).catch((error) => {
          this.setState({
            loading: false
          })
        });
    } else {
      this.setState({ fetched: true });
    }

    GLSAPI.endpoints['customer'].getAllPages()
      .then((response) => {
        let customers = response.map((item, id) => { return { ...item, key: id } });

        this.setState({
          availableCustomers: customers
        });
      }).catch((error) => {
        console.log(error);
      })

  }

  submithandler = () => {
    var element = document.querySelector("span.ant-select-selection-item");
    var host_name = this.state.ghost.host_ident;
    var host_display_name = document.getElementById('host_ident').value;
    if (element !== null && host_name === host_display_name) {
      var obj = { name: host_display_name, customer_name: document.querySelector("span.ant-select-selection-item").title };
      console.log(obj);

      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      if (document.getElementById('host_ident').value && document.querySelector("span.ant-select-selection-item").innerHTML) {
        axios.post('https://www.serversynergy.com/glsmon/register/token', JSON.stringify(obj), options)
          .then(response => {
            console.log(response);
            if (response.data.msg === "Already Registered") {
              alert("HostName is already registered");
              // window.location.reload();
            }
            else if (response.status === 200) {
              alert("Host registered sucessfully");
              window.location = "https://www.serversynergy.com/glsmon/hosts";
            }
          })
          .catch(() => {
            // alert("That is not a valid Host Name");
            // window.location.reload();
            console.log('catch');
          })
      }
    }
    else if( element === null && host_name !== host_display_name ) {
      alert("Please enter the required fields");
    }
    // else if(host_name !== host_display_name ) {
    //   alert("That is not a valid Host Name");
    //   // window.location.reload();
    // }
  }


  render() {

    return (
      <div style={{ width: '100%' }}>
        {/* <CustomerBar /> */}
        <div className="HostsRegister view">
          <div className="page-area--tabbed">

            <h1 className="header-bar no-margin">Register Host</h1>

            <WrappedDataForm
              formRef={this.formRef}
            >
              {/* title=""
              apiFunction={this.apiFunction}
              apiResource="host"
              // onSubmitCallback={this.onSubmitCallback}
              verbs={["Registering", "Registered"]}
              // onErrorCallback={this.onErrorCallback}
              hideRequiredMark */}
              {this.registerHostForm}
            </WrappedDataForm>

          </div>
        </div>
      </div>
    );
  }

  // onSubmitCallback = (host) => {
  //   this.props.history.push('/glsmon/hosts');
  // }

  // onErrorCallback = (error) => {
  //   if (error.message === 'Name already exists') {
  //     message.destroy();
  //     message.error('Name already exists');
  //   }
  // }

  registerHostForm = (defaultFormLayout) => (
    <div>
      <center>
        <p>Review the host details below: set the display name, confirm the PIN being used, and click the <b>"Complete Registration" </b>to fully register the host.</p>
        <table>
          <tr><td><b>Hostname:</b></td><td>{this.state.ghost.hostname}</td></tr>
          <tr><td><b>IP Address:</b></td><td>{this.state.ghost.ip}</td></tr>
          <tr><td><b>Mac Address:</b></td><td>{this.state.ghost.mac}</td></tr>
          <tr><td><b>Initial Host Connect Time:</b></td><td>{this.state.ghost.creation_time}</td></tr>
        </table>
      </center>

      <div className="view-section view-section--no-border">
        {/* {this.context.currentCustomer.id &&
          <Form.Item style={{ display: 'none' }} name="customer_id" initialValue={this.context.currentCustomer.id}>
            <Input type="hidden" />
          </Form.Item>
        }
        {this.state.resourceId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormItemLayout} name="id" initialValue={this.state.resourceId}>
            <Input type="hidden" />
          </Form.Item>} */}

        <Form.Item label="Host Display Name" {...defaultFormLayout} name="name"
          defaultValue={this.state.ghost.host_ident}
          rules={[{ required: true, message: 'Host Display Name is required' }]}
        >
          <Input id='host_ident' disabled/>
        </Form.Item>
        <Form.Item label="Customer" {...defaultFormLayout} name="customer_name"
          rules={[{ required: true, message: 'Please choose a customer' }]}
        >
          <Select>
            {
              this.state.availableCustomers.map((resource) => {
                return <Select.Option value={resource.name} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
              })
            }
          </Select>
        </Form.Item>
      </div>
      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={this.submithandler.bind(this)}>Complete Registration</Button>
        </Form.Item>
      </div>
    </div>
  );
}

HostsRegister.contextType = UserStateContext;
export default HostsRegister;
