import React from 'react';
// main theme styling imported first to avoid overriding individual component styles
import './App.scss';

import Page from './components/Page';
import LoginForm from './components/LoginForm';
import Register from './components/Register';
import Main from './views/Main';
import HostsRegister from './views/HostsRegister';
import { useAuth } from './AuthContext';
import { Route, Switch } from 'react-router-dom';

const App = (props) => {
  let authState = useAuth();
  return (
    <div className="App">
      <div id="Auth">
        <Switch>
          <Route path="/glsmon/signup"
            render={props => <Page view={<Register />} {...props} />}>
          </Route>
          <Route path="/glsmon/register/:token"
            render={props => <Page view={<HostsRegister />} {...props} />} >
          </Route>
        </Switch>
      </div>
      {
        !authState.authenticated ?
          <Route path="/"
            render={props => <Page view={<LoginForm />} {...props} />}>
          </Route>
          :
          <Main {...props} />
      }
    </div >
  );
}

export default App;
