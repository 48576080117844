import React from 'react';
import { Button, Input, Checkbox, Form, message } from 'antd';
import BackLink from '../components/BackLink';
import GLSAPI from '../api/api_config';
import { formatKeyValuePairs } from '../helpers';
import WrappedDataForm from '../components/DataForm';
import MultiSelector from '../components/MultiSelector';
import KeyValue from '../components/KeyValue';
import { UserStateContext } from '../UserContext';
import './HostsAdd.scss';

class HostsAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      availableHostgroups: []
    }
  }
  componentDidUpdate(prevProps) {
  }

  componentDidMount(props) {
    this.setState({
      loading: true
    })

    GLSAPI.endpoints['hostgroup'].getAll()
      .then((response) => {
        let hostgroups = response.objects.map((item, i) => { return { ...item, key: item.id } });

        this.setState({
          availableHostgroups: hostgroups
        });
      }).catch((error) => {
      })

  }

  render() {
    return (
      <div className="HostsAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">Add Host</h1>

          <WrappedDataForm title=""
            apiFunction={GLSAPI.endpoints['host'].create}
            apiResource="host"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark>
            {this.addHostForm}
          </WrappedDataForm>
        </div>
      </div>
    );
  }

  onSubmitCallback = (host) => {
    this.props.history.push('/glsmon/hosts');
  }

  onErrorCallback = (error) => {
    if (error.message === "Name already exists") {
      message.destroy();
      message.error('Host Name already exists. Please try a different name.');
    }
  }

  getCheckedValue = (e) => {
    return e.target.checked ? 1 : 0;
  };

  addHostForm = (defaultFormItemLayout) => (
    <div>
      <div className="view-section view-section--no-border">
        {this.context.currentCustomer.id &&
          <Form.Item style={{ display: 'none' }} name="customer_id" initialValue={this.context.currentCustomer.id}>
            <Input type="hidden" />
          </Form.Item>
        }
        <Form.Item label="Host Name" {...defaultFormItemLayout} name="name"
          rules={[{ required: true, message: 'Host name is required' }]}
        >
          <Input placeholder="Host name" />
        </Form.Item>

        <Form.Item label={<span><span>Licensed</span><span className="form-item-label-description">Probably not enforced yet in many or most places it should be</span></span>}
          {...defaultFormItemLayout}
          name="is_licensed"
          initialValue={false}
          valuePropName="checked"
          getValueFromEvent={this.getCheckedValue}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item label={<span>Registered</span>}
          {...defaultFormItemLayout}
          name="is_registered"
          initialValue={false}
          valuePropName="checked"
          getValueFromEvent={this.getCheckedValue}
        >
          <Checkbox />
        </Form.Item>
      </div>
      <div className="view-section">
        <Form.Item label="Hostgroups" labelCol={{ xs: { span: 24 } }} colon={false} name="hostgroups" initialValue={[]}>
          <MultiSelector dataSource={this.state.availableHostgroups} titles={["Available hostgroups", "Selected"]} />
        </Form.Item>
      </div>
      <div className="view-section">
        <Form.Item label="Environment" labelCol={{ xs: { span: 24 } }} name="env_vars" getValueFromEvent={formatKeyValuePairs}>
          <KeyValue />
        </Form.Item>
      </div>
      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  );
}

HostsAdd.contextType = UserStateContext;
export default HostsAdd;
