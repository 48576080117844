import React from 'react';

const IconHosts = props => {
  const { width, height } = props;

  return (
    <svg className="IconHosts gls-icon" width={width || "17px"} height={height || "21px"} viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-727.000000, -5286.000000)">
          <g id="Group-24" transform="translate(711.000000, 5272.000000)">
            <g id="hosts">
              <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
              <g id="flaticon1549925547-svg" transform="translate(16.000000, 14.000000)" fill="#0C1B2E">
                <path d="M9.38638,11.6775859 C9.09058,11.6865198 8.79478,11.691483 8.50034,11.691483 C8.20488,11.691483 7.90806,11.6868506 7.61158,11.6775859 C5.11564,11.611078 3.12664,11.2679505 1.69456,10.7785719 C1.02136,10.5575409 0.44506,10.2974654 0,10 L0,12.3529217 L0,12.5183641 L0,12.6838065 L0,13.110317 C0.96764,14.0933757 4.21396,15 8.5,15 C12.78604,15 16.03236,14.0933757 17,13.110317 L17,12.6838065 L17,12.5183641 L17,12.3529217 L17,10 C16.55188,10.2994507 15.97048,10.5615115 15.2915,10.7832043 C13.8601,11.2702667 11.87518,11.6114089 9.38638,11.6775859 Z" id="Path"></path>
                <path d="M0,5 L0,7.35292171 L0,7.51836411 L0,7.6838065 L0,8.11031699 C0.89692,9.02157369 3.75122,9.8673152 7.57724,9.98577195 C7.61634,9.98709549 7.65646,9.98742638 7.6959,9.98841903 C7.80674,9.991397 7.91724,9.99437496 8.02978,9.99602938 C8.18516,9.99867646 8.3419,10 8.5,10 C8.6581,10 8.81484,9.99867646 8.97022,9.99636027 C9.08242,9.99470584 9.19326,9.99172788 9.3041,9.98874992 C9.34354,9.98775726 9.38366,9.98709549 9.42276,9.98610284 C13.24878,9.8673152 16.10308,9.02157369 17,8.11064787 L17,7.6838065 L17,7.51836411 L17,7.35292171 L17,5 C15.34522,6.10614784 11.84696,6.69115214 8.5,6.69115214 C5.15304,6.69115214 1.65512,6.10614784 0,5 Z" id="Path"></path>
                <path d="M16.9541,3.01482353 C16.57262,1.49682353 13.6391,0 8.5,0 C3.37382,0 0.44336,1.48941176 0.04998,3.00352941 C0.02108,3.05541176 0,3.11223529 0,3.17647059 L0,3.35294118 L0,3.99035294 C0.96458,5.03541176 4.18778,6 8.5,6 C12.81222,6 16.03576,5.03541176 17,3.99035294 L17,3.35294118 L17,3.17647059 C17,3.11717647 16.98096,3.06388235 16.9541,3.01482353 Z" id="Path"></path>
                <path d="M0,15 L0,17.8606476 C0,17.917156 0.01462,17.970525 0.03978,18.0179641 C0.44132,19.7254229 4.03376,21 8.5,21 C12.95604,21 16.5427,19.7306552 16.9575,18.029475 C16.9847,17.9792454 17,17.9216906 17,17.8606476 L17,15.0006976 C15.5941,16.0161037 12.6973,16.8141968 8.5,16.8141968 C4.30236,16.8141968 1.40556,16.0154061 0,15 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconHosts;
