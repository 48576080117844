import React from 'react'

const IconUnknown = props => {
  const { width, height } = props;

  return (
    <svg className="IconUnknown gls-icon" width={width || "22px"} height={height || "22px"} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-1157.000000, -5286.000000)">
          <g id="Group-25" transform="translate(928.000000, 5273.000000)">
            <g id="unknown" transform="translate(216.000000, 0.000000)">
              <g id="Group-5">
                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
              </g>
              <g id="flaticon1554419052-svg" transform="translate(13.000000, 13.000000)" fill="#495466" fillRule="nonzero">
                <path d="M11,0 C4.95,0 0,4.95 0,11 C0,17.05 4.95,22 11,22 C17.05,22 22,17.05 22,11 C22,4.95 17.05,0 11,0 Z M12.1,18.7 L9.9,18.7 L9.9,16.5 L12.1,16.5 L12.1,18.7 Z M14.41,10.23 L13.42,11.22 C12.5400431,11.9900431 12.1,12.65 12.1,14.3 L9.9,14.3 L9.9,13.75 C9.9,12.54 10.34,11.44 11.22,10.67 L12.54,9.24 C12.98,8.91 13.2,8.36 13.2,7.7 C13.2,6.49 12.21,5.5 11,5.5 C9.79,5.5 8.8,6.49 8.8,7.7 L6.6,7.7 C6.6,5.28 8.58,3.3 11,3.3 C13.42,3.3 15.4,5.28 15.4,7.7 C15.4,8.69 14.96,9.57 14.41,10.23 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconUnknown;
