import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import IconPositive from '../../theming/icons/IconPositive';
import IconError from '../../theming/icons/IconError';
import IconWarning from '../../theming/icons/IconWarning';
import IconUnknown from '../../theming/icons/IconUnknown';
import { formatTimestampSeconds } from '../../helpers';

export const metricEventColumns = [
  {
    title: "Metric",
    key: "metric",
    dataIndex: "metric",
    render: (text, record) => {
      return (
        <span className="column-text">
          <Link to={`/glsmon/metrics/${record.metric.id}`} >
            {record.metric.name} - {record.metric.host.name}
          </Link>
        </span>
      );
    }
  },
  {
    title: "Status",
    key: "state",
    dataIndex: "state",
    className: "centered-column",
    render: (text, record) => {
      if (record.metric_state_type) {
        switch (record.metric_state_type.name) {
          case "Host Up": return (<span className="inline-icon positive"><IconPositive /> Host Up</span>);
          case "Host Down": return (<span className="inline-icon danger"><IconError /> Host Down</span>);
          case "Ok": return (<span className="inline-icon positive"><IconPositive /> Ok</span>);
          case "Warning": return (<span className="inline-icon warning"><IconWarning /> Warning</span>);
          case "Critical": return (<span className="inline-icon danger"><IconError /> Critical</span>);
          case "Unknown": return (<span className="inline-icon"><IconUnknown /> Unknown</span>);
          case "Stale": return (<span className="inline-icon"><IconWarning /> Stale</span>);
          default: return <span className="inline-icon"><IconUnknown /> Unknown</span>;
        }
      }
    }
  },
  // {
  //   title: "Acked",
  //   key: "acked",
  //   dataIndex: "acked",
  //   render: (text, record) => {
  //     if (record.contact.length > 0) {
  //       return (<span className="column-text positive">Acked</span>)
  //     } else {
  //       return (<span className="column-text danger">Not Acked</span>)
  //     }
  //   }
  // },
  {
    title: "Contact Addresses",
    key: "contact",
    dataIndex: "is_global",
    render: (text, record) => {
      if (record.contact.length > 0) {
        if (record.contact[0].is_global == 1) {
          return "Global - Metric"
        }
        else {
          return record.contact.length + " Contacts"
        }
      }
      else {
        return "No contacts"
      }
    }
  },
  {
    title: "Event Start",
    dataIndex: "event_start",
    key: "event_start",
    render: (text, record) => <span className="column-text">{formatTimestampSeconds(record.event_start)}</span>
  },
  {
    title: "Event End",
    dataIndex: "event_end",
    key: "event_end",
    render: (text, record) => <span className="column-text">{formatTimestampSeconds(record.event_end)}</span>
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const metricEventFilterTypes = {
  "host": "MultiSelector",
};

export const metricEventActions = (history) => {
  return [
    {
      name: "View",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/metrics/${record.id}`);
      },
      enabled: (record) => {
        return false;
      }
    },
    {
      name: "Ack",
      type: "default",
      func: (record) => {
        history.push(`/glsmon/metrics/${record.metric.id}/ack/new`);
        localStorage.setItem('Name', record.id);
      },
      enabled: (record) => {
        return true
      }
    },
  ];
}
