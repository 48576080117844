import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import IconPositive from '../../theming/icons/IconPositive';
import IconError from '../../theming/icons/IconError';
import IconWarning from '../../theming/icons/IconWarning';
import IconUnknown from '../../theming/icons/IconUnknown';

export const contactNodesColumns = [
  {
    title: 'Node Name',
    key: 'node_name',
    render: (text, record) => record && <Link to={`/glsmon/notifications/contact-nodes/${record.id}`}>{record.name}</Link>
  },
  {
    title: 'Event Sources',
    key: 'eventsources',
    dataIndex: 'eventsources'
  },
  {
    title: 'Previous Nodes',
    key: 'prev_nodes',
    render: (text, record) => record.prev_nodes && record.prev_nodes.map(node => {
      return <div><Link to={`/glsmon/notifications/contact-nodes/${node.id}`}>{node.name}</Link></div>
    })

  },
  {
    title: 'Host States',
    key: 'host_states',
    render: (text, record) => {
      switch (record.host_states_str) {
        case 'Down': return <IconError />;
        case 'Up': return <IconPositive />;
        case 'Warning': return <IconWarning />;
        default: return <IconUnknown />;
      }
    }
  },
  {
    title: 'Service States',
    key: 'service_states',
    render: (text, record) => {
      return record && record.service_states_str
    }
  },
  {
    title: 'Time Period',
    key: 'timeperiod',
    render: (text, record) => record.timeperiod && record.timeperiod.name
  },
  {
    title: 'Delay',
    key: 'delay',
    dataIndex: 'delay'
  },
  {
    title: 'Notification Interval',
    key: 'notify_rate',
    dataIndex: 'notify_rate'
  },
  {
    title: 'Next Nodes',
    key: 'next_nodes',
    render: (text, record) => record.next_nodes && record.next_nodes.map(node => {
      return <div><Link to={`/glsmon/notifications/contact-nodes/${node.id}`}>{node.name}</Link></div>
    })
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
]
