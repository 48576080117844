import React from 'react';
import PropTypes from 'prop-types';

import IconAdd from '../theming/icons/IconAdd';
import IconDelete from '../theming/icons/IconDelete';
import SearchField from './SearchField';

import { Transfer, Pagination, Spin } from 'antd';
import Icon from '@ant-design/icons';

import './MultiSelector.scss';

class MultiSelector extends React.Component {
    itemsPerPage = 12;

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            targetKeys: this.props.targetKeys,
            selectedKeys: this.props.selectedKeys,
            dataSource: this.props.dataSource.map((item) => { return { ...item, visible: true } }),

            filterEnabled: false,
            filterValue: ""
        }
    }

    componentDidMount(props) {
        this.setState({
            dataSource: this.buildPaginatedList()
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataSource !== prevProps.dataSource) {
            this.setState({
                dataSource: this.props.dataSource.map((item) => { return { ...item, visible: true } }),
            })
        }
        if (this.props.targetKeys !== prevProps.targetKeys) {
            this.setState({
                targetKeys: this.props.targetKeys
            })
        }
    }

    render() {
        let data = this.buildPaginatedList()
        return (
            <div className="MultiSelector">
                {this.props.filterField &&
                    <SearchField
                        dataIndex={this.props.dataIndex}
                        title="Filter"
                        placeholder={`Filter by ${this.props.dataIndex}`}
                        onChange={this.handleFilterChange}
                    />
                }
                <Transfer
                    dataSource={data}
                    targetKeys={this.state.targetKeys}
                    selectedKeys={this.state.selectedKeys}
                    onSelectChange={this.handleSelectChange}
                    titles={this.props.titles}
                    showSearch={this.props.search}
                    lazy={false}
                    render={(item) => this.buildItem(item)}
                >
                    {() => {
                        if (this.props.loading) {
                            return <div className="loading"><Spin /></div>;
                        }
                    }}
                </Transfer>
                {this.props.dataSource.length > this.itemsPerPage &&
                    <div className="pagination-wrapper">
                        <Pagination
                            defaultPageSize={this.itemsPerPage}
                            current={this.state.currentPage}
                            total={(this.state.dataSource.length - this.state.targetKeys.length)}
                            onChange={this.handlePageChange}
                            showSizeChanger={false}
                        />
                    </div>
                }
            </div>
        );
    }

    buildItem = (item) => {

        const iconComponent = this.state.targetKeys.indexOf(item.key) > -1 ? IconDelete : IconAdd;

        return (
            <span className="transfer-item" key={item.key}>
                {item.name}
                <Icon component={iconComponent} />
            </span>
        );
    }

    buildPaginatedList = (page = this.state.currentPage, targetKeys = this.state.targetKeys) => {
        let amountAdded = 0;
        let min = (page - 1) * this.itemsPerPage;
        let max = this.itemsPerPage * page;
        let data = this.state.dataSource.map((item, i) => {
            // current item is already selected
            let currentKeyIndex = targetKeys.indexOf(item.key);
            if (currentKeyIndex > -1) {
                return ({ ...item, disabled: false });
            }

            // show items until space is filled
            if (i >= min && (i < max || amountAdded < this.itemsPerPage) && item.visible) {
                amountAdded += 1;
                return ({ ...item, disabled: false });
            } else {
                return ({ ...item, disabled: true });
            }
        });

        return data;
    }

    handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        var currentTargetKeys = this.state.targetKeys;
        // key is already in the targets, need to move back
        if (sourceSelectedKeys.length > 0) {
            currentTargetKeys = [...currentTargetKeys, ...sourceSelectedKeys];
        } else if (targetSelectedKeys.length > 0) {

            let currentTargetKey = targetSelectedKeys[0];
            let currentKeyIndex = currentTargetKeys.indexOf(currentTargetKey);
            currentTargetKeys.splice(currentKeyIndex, 1);
        }

        let amountUnselected = this.props.dataSource.length - currentTargetKeys.length;
        let newMaxPage = Math.ceil(amountUnselected / this.itemsPerPage);
        let currentPage = this.state.currentPage > newMaxPage ? newMaxPage : this.state.currentPage;

        let newPaginatedData = this.buildPaginatedList(currentPage, currentTargetKeys);

        this.setState({
            targetKeys: currentTargetKeys,
            dataSource: newPaginatedData,
            currentPage: currentPage
        }, () => {
            this.props.updateSelectedKeys(this.props.dataIndex, currentTargetKeys);
            this.props.onChange(currentTargetKeys);
        });
    }

    handlePageChange = (page) => {
        this.setState({
            dataSource: this.buildPaginatedList(page),
            currentPage: page
        });
    }

    handleFilterChange = (dataIndex, checked, value) => {

        this.setState({
            filterEnabled: checked,
            filterValue: value,
            dataSource: this.filterData(checked, value)
        });
    }

    filterData = (enabled, filterValue) => {
        let rawData = this.state.dataSource;

        if (filterValue === "" || !enabled) {
            return rawData.map((item) => { return ({ ...item, visible: true }) });
        }

        let data = rawData.map((item) => {
            if (item[this.props.dataIndex].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
                return { ...item, visible: true }
            } else {
                return { ...item, visible: false }
            }
        });

        return data;
    }
}

MultiSelector.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.any),
    targetKeys: PropTypes.arrayOf(PropTypes.string),
    selectedKeys: PropTypes.arrayOf(PropTypes.string),
    updateSelectedKeys: PropTypes.func,
    onChange: PropTypes.func,
    dataIndex: PropTypes.string,
    filterField: PropTypes.bool,
}

MultiSelector.defaultProps = {
    dataSource: [],
    targetKeys: [],
    selectedKeys: [],
    titles: ["Available", "Selected"],
    dataIndex: null,
    updateSelectedKeys: () => { },
    onChange: () => { },
    filterField: false,
}

export default MultiSelector;