import React from 'react';
import { Button, Form, Select, Input, InputNumber, Checkbox, TimePicker, message, Spin } from 'antd';
import moment from 'moment';
import GLSAPI from '../api/api_config';
import { formatTimeValue } from '../helpers';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';
import './HostsEditQueriesAdd.scss';
import { UserStateContext } from '../UserContext';

let timeFormat = "HH:mm";

class HostsEditQueriesAdd extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    const parentHostId = (props.match ? parseInt(props.match.params.hostid) : 0);
    const resourceId = (props.match ? props.match.params.queryid : 0);

    this.state = {
      fetchingQueries: false,
      fetchingHosts: false,
      fetchingChecks: false,
      resource: {},
      availableHosts: [],
      availableChecks: [],
      parentHostId,
      resourceId
    };

    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['query'].create
      : GLSAPI.endpoints['query'].edit;

  }

  componentDidMount() {
    this.setState({
      fetchingChecks: true,
      fetchingHosts: true,
      fetchingQuery: (this.props.actionType === 'edit' ? true : false)
    });


    // if (this.props.actionType === 'create') {
    //   this.formRef.current.setFieldsValue({
    //     "name": "ok_check"
    //   })
    // }

    if (this.props.actionType === 'edit') {
      GLSAPI.endpoints['query'].getOne({ id: this.state.resourceId })
        .then((response) => {
          this.setState({
            resource: response,
            fetchingQuery: false
          }, () => {
            this.formRef.current.setFieldsValue({
              "host_id": this.state.resource.host ? this.state.resource.host.id : null,
              "check_id": this.state.resource.check ? this.state.resource.check.id : null,
              "ionice_class": this.state.resource.ionice_class ? this.state.resource.ionice_class : null,
              "ionice_prio": this.state.resource.ionice_prio ? this.state.resource.ionice_prio : null,
              "nice": this.state.resource.nice ? this.state.resource.nice : null,
              "run_interval": this.state.resource.run_interval ? this.state.resource.run_interval : null,
              "start_time": this.state.resource.start_time ? moment((this.state.resource.start_time), "HH: mm") : null,
              "stop_time": this.state.resource.stop_time ? moment((this.state.resource.stop_time), "HH: mm") : null,
              "is_enabled": this.state.resource.is_enabled ? this.state.resource.is_enabled : false,
            })
          });
        }).catch((error) => {
          console.log(error);
          this.setState({
            fetchingQuery: false
          });
          message.destroy();
          message.error('Error fetching query', 5);
        })
    }

    GLSAPI.endpoints['host'].getAllPages()
      .then((response) => {
        let availableHosts = response.map((item, i) => { return { ...item, key: item.id } });

        this.setState({
          availableHosts,
          fetchingHosts: false
        });
      }).catch((error) => {
        console.log(error);
        this.setState({
          fetchingHosts: false
        });
        message.destroy();
        message.error('Error fetching hosts', 5);
      })

    GLSAPI.endpoints['check'].getAllPages()
      .then((response) => {
        function sortBy(arr, prop) {
          return arr.sort((a, b) => b[prop] - a[prop]);
        }
        var data = sortBy(response, 'id');
        console.log(data, "Hi");
        let availableChecks = data.map((item, i) => { return { ...item, key: item.id } });

        this.setState({
          availableChecks,
          fetchingChecks: false
        }, () => {
          if (!this.formRef.current.getFieldValue('check_id')) {
            this.formRef.current.setFieldsValue({
              "check_id": this.state.availableChecks.length > 0 ? this.state.availableChecks[0].id : null,
            })
          }
        });

      }).catch((error) => {
        console.log(error);
        this.setState({
          fetchingChecks: false
        })
        message.destroy();
        message.error('Error fetching checks', 5);
      })
  }

  render() {
    const { viewTitle } = this.props;
    return (
      <div className="HostsEditQueriesAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">{viewTitle}</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="query"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            specialFormatting={{
              start_time: formatTimeValue,
              stop_time: formatTimeValue
            }}
            verbs={this.getVerbs()}
            formRef={this.formRef}
          >
            {this.addQueryForm}
          </WrappedDataForm>
        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  onSubmitCallback = (query) => {
    this.props.history.push(`/glsmon/hosts/${this.state.parentHostId}/queries`);
  }

  onErrorCallback = (error) => {
    if (error.message === "Name already exists") {
      message.destroy();
      message.error('Query name already exists. Please adjust.');
    }
  }

  getCheckedValue = (e) => {
    return e.target.checked ? 1 : 0;
  };

  // use this to get name from select value
  onSelectedValue = (value) => {
    var selecteLabelPerValue = this.state.availableChecks.find(x => x.id === value).name;
    this.formRef.current.setFieldsValue({ name: selecteLabelPerValue });
    return;
  };


  addQueryForm = (defaultFormLayout) => (
    <div>
      <div className="view-section view-section--no-border">
        {this.state.resourceId !== 0 &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
            <Input type="hidden" />
          </Form.Item>
        }

        {this.context && this.context.currentCustomer !== {} &&
          <Form.Item style={{ display: 'none' }} name="customer_id" initialValue={this.context.currentCustomer.id}>
            <Input type="hidden" />
          </Form.Item>
        }

        <Form.Item
          label={<span>Host {this.state.fetchingHosts ? <Spin size="small" /> : ''}</span>}
          {...defaultFormLayout}
          name="host_id"
          initialValue={this.state.parentHostId}
          rules={[{ required: true, message: 'A host is required' }]}
        >
          <Select >
            {
              this.state.availableHosts.map((host) => <Select.Option value={parseInt(host.id)} key={parseInt(host.id)}>{host.name}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          label={<span>Check {this.state.fetchingChecks ? <Spin size="small" /> : ''}</span>}
          {...defaultFormLayout}
          name="check_id"
          rules={[
            { required: true, message: 'A check is required' }
          ]}
        >
          <Select
            onChange={this.onSelectedValue}
          >
            {
              this.state.availableChecks.map((check) => <Select.Option value={parseInt(check.id)} key={parseInt(check.id)}>{check.name}</Select.Option>)
            }
          </Select>
        </Form.Item>

        {this.props.actionType === 'create' &&
          <Form.Item
            label={<span><span>Query Name</span><span className="form-item-label-description">(Leave untouched to default to the same name as the check selected above).</span></span>}
            {...defaultFormLayout}
            name="name"
            rules={[
              { required: this.props.actionType === 'create', message: 'Query name is required' }
            ]}
          >
            <Input />
          </Form.Item>
        }

        <Form.Item label="Enabled" {...defaultFormLayout} name="is_enabled" valuePropName="checked" getValueFromEvent={this.getCheckedValue}
          initialValue={this.state.resource ? this.state.resource.is_enabled : 1}>
          <Checkbox />
        </Form.Item>

        <Form.Item label="Daily Start Time:" {...defaultFormLayout} name="start_time" initialValue={moment((this.state.resource.start_time ? this.state.resource.start_time : '00:00'), timeFormat)}>
          <TimePicker format={timeFormat} />
        </Form.Item>

        <Form.Item label="Daily Stop Time:" {...defaultFormLayout} name="stop_time" initialValue={moment((this.state.resource.stop_time ? this.state.resource.stop_time : '23:59'), timeFormat)}>
          <TimePicker format={timeFormat} />
        </Form.Item>

        <Form.Item label={<span><span>Run Interval:</span><span className="form-item-label-description">Time in minutes between executions of this query</span></span>}
          {...defaultFormLayout}
          name="run_interval"
          initialValue={this.state.er}
          extra="Minutes."
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Ionice class:" {...defaultFormLayout} name="ionice_class"
        >

          <Select>
            <Select.Option value={1} key={1}>realtime</Select.Option>
            <Select.Option value={2} key={2}>best-effort</Select.Option>
            <Select.Option value={3} key={3}>idle</Select.Option>
          </Select>

        </Form.Item>

        <Form.Item label={<span>Ionice prio: <span className="form-item-label-description">Set the priority with a number from 1 to 7</span></span>} {...defaultFormLayout} name="ionice_prio" initialValue={(this.state.resource ? this.state.resource.ionice_prio : '')}>
          <InputNumber placeholder={(this.state.resource ? this.state.resource.ionice_class : '')} />
        </Form.Item>

        <Form.Item label={<span>Nice: <span className="form-item-label-description">Set a value between -20 and 20</span></span>} {...defaultFormLayout} name="nice" initialValue={(this.state.resource ? this.state.resource.nice : '')}>
          <InputNumber placeholder={(this.state.resource ? this.state.resource.nice : '')} />
        </Form.Item>
      </div>

      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  )
}

HostsEditQueriesAdd.contextType = UserStateContext;

export default HostsEditQueriesAdd;
