import React from 'react';
import PropTypes from 'prop-types';
import MultiSelector from './MultiSelector';
import SearchField from './SearchField';
import Expander from './Expander';
import { Select, Switch } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import './Filter.scss';

const Option = Select.Option;

class Filter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            enabledFilters: this.props.enabledFilters
        };

        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._handleSearchFieldChange = this._handleSearchFieldChange.bind(this);
    }

    buildFilter() {
        const availableFilters = this.props.availableFilters;
        const enabledFilters = this.props.enabledFilters;

        return (
            <div className="Filter clearfix">
                {
                    availableFilters.map((filter) => {

                        var enabledFilterValue = "All";
                        for (var i = 0; i < enabledFilters.length; i++) {
                            if (enabledFilters[i].key === filter.key) {
                                enabledFilterValue = enabledFilters[i].value
                            }
                        }

                        if (filter.type === "SearchField") {
                            return (
                                <SearchField key="search-field" dataIndex={filter.key} title={filter.key} onChange={this._handleSearchFieldChange} />
                            )
                        } else if (filter.type === "MultiSelector") {
                            return (
                                <MultiSelector key="multi-selector" dataIndex={filter.key} dataSource={filter.values} titles={[filter.key, ""]} updateSelectedKeys={this._handleSelectChange} />
                            )
                        } else if (filter.type === "Switch") {
                            // Untested TODO set this up for /Events show problems.
                            return (
                                <Switch key="switch" defaultChecked={this.defaultChecked ? this.defaultChecked : false} onChange={this._handleSelectChange} />
                            )
                        } else {
                            return (
                                <div className="single-filter" key={filter.key}>
                                    <label>{filter.key}: </label>
                                    <Select
                                        defaultValue={enabledFilterValue}
                                        onChange={(value) => {
                                            this._handleSelectChange(filter.key, value)
                                        }}
                                        className="filter-select"
                                    // mode="multiple"
                                    >
                                        <Option value="all">All</Option>
                                        {
                                            filter.values.map((value) => {
                                                return (
                                                    <Option key={value.key} value={value.key}>{value.name}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </div>
                            )
                        }
                    })
                }
            </div>
        );
    }

    render() {
        if (this.props.expandable) {
            return <Expander
                sections={[this.buildFilter()]}
                openIcon={<MinusOutlined />}
                closedIcon={<PlusOutlined />}
                hideAll
                open={this.props.expanded}
                togglePlacement="above"
            />
        } else {
            return this.buildFilter();
        }

    }

    _handleSelectChange(filterKey, value) {
        var enabledFilters = this.state.enabledFilters;
        var filterIndex = -1;

        for (var i = 0; i < enabledFilters.length; i++) {
            if (enabledFilters[i].key === filterKey) {
                filterIndex = i;
                break;
            }
        }

        if (filterIndex > -1) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    enabledFilters[filterIndex].value = value;
                } else {
                    enabledFilters.splice(filterIndex, 1);
                }
            } else if (value === "all") {
                enabledFilters.splice(filterIndex, 1);
            } else {
                enabledFilters[filterIndex].value = value;
            }
        } else {
            enabledFilters.push({
                key: filterKey,
                value: value
            });
        }

        this.setState({
            enabledFilters: enabledFilters
        }, () => {
            this.props.onFilterSelectionChange(this.state.enabledFilters);
        })

    }

    _handleSearchFieldChange(filterKey, checkboxValue, inputValue) {
        var enabledFilters = this.state.enabledFilters;

        var filterIndex = -1;

        for (var i = 0; i < enabledFilters.length; i++) {
            if (enabledFilters[i].key === filterKey) {
                filterIndex = i;
                break;
            }
        }

        if (filterIndex > -1) {
            if (checkboxValue) {
                enabledFilters[filterIndex].value = inputValue;
            } else {
                enabledFilters.splice(filterIndex, 1);
            }
        } else {
            if (checkboxValue) {
                enabledFilters.push({
                    key: filterKey,
                    value: inputValue
                });
            } else {
                enabledFilters.splice(filterIndex, 1);
            }
        }

        this.setState({
            enabledFilters: enabledFilters
        }, () => {
            this.props.onFilterSelectionChange(this.state.enabledFilters);
        });

    }
}

Filter.propTypes = {
    availableFilters: PropTypes.arrayOf(PropTypes.object),
    enabledFilters: PropTypes.arrayOf(PropTypes.object),
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    onFilterSelectionChange: PropTypes.func,
}

Filter.defaultProps = {
    availableFilters: [],
    enabledFilters: [],
    expandable: false,
    expanded: false,
    onFilterSelectionChange: () => { }
}

export default Filter;