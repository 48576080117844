import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import DataWrapper from '../components/DataWrapper';
import { notificationColumns, notificationFilterTypes as filterTypes } from '../api/schema/notification';
import './Notifications.scss';
import './Dashboard.scss';
import DynamicTable from '../components/DynamicTable';
import Filter from '../components/Filter';
import AlertBar from '../components/AlertBar';
import MetricsAckAdd from './MetricsAckAdd';
import PropTypes from 'prop-types';
import NotificationsLinks from '../components/NotificationsLinks';
import { Row, Col } from 'antd';
import ContactsTabs from '../views/ContactsTabs';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsModel = {
      type: 'notifications',
      title: 'Notifications',
      rowsPerPage: 5,
      currentPage: 1
    };
  }

  render() {
    let match = this.props.match;
    return (
      <div className="Notifications">
        <Row>
          <Col span={12} offset={12} className="notifications-links-wrap">
            <NotificationsLinks />
          </Col>
        </Row>
        <Route exact path={match.url} render={() => (
          <Row style={{ paddingTop: '31px' }}>
            <Col span={24}>
              <div className="page-area--tabbed">
                <h1 className="header-bar">Notifications</h1>
                <div className="page-info">
                  {this.alerts.map((alert, i) => {
                    return <AlertBar key={i} alert={alert} showIcon />
                  })}
                </div>
                <DataWrapper
                  api="notification"
                  columns={notificationColumns}
                  filterTypes={filterTypes}
                  tableOptionsModel={this.tableOptionsModel}
                  actions={[
                    {
                      name: "Ack",
                      type: "default",
                      func: (record) => {
                        this.props.history.push(`/glsmon/metrics/${record.metric.id}/ack/new`);
                      },
                      enabled: (record) => {
                        return true
                      }
                    },
                  ]}
                >
                  {
                    (props, state, actions) => (
                      <div>
                        <section className="view-section filters-section filters-section--expanded">
                          <header className="view-section-header">
                            <h3 className="view-section-title">Available Filters</h3>
                            <Filter expanded expandable
                              {...props}
                              {...state}
                              {...actions}
                            />
                          </header>
                        </section>
                        <section className="view-section hosts-section filters-section--expanded">
                          <DynamicTable
                            {...props}
                            {...state}
                            {...actions}
                          />
                        </section>
                      </div>
                    )
                  }
                </DataWrapper>
              </div>
            </Col>
          </Row>
        )} />
        <Switch>
          <Route path={`${match.path}/:metricId/ack/new`} render={(props) => <MetricsAckAdd actionType="create" {...props} />} />
          <Row style={{ marginTop: '-7.5px' }}>
            <Col span={24}>
              <Route path="/glsmon/notifications/:subpage" render={(props) => {
                var subPage = 'contacts';
                if (props.match && props.match.params.subpage) {
                  subPage = props.match.params.subpage;
                }

                return (
                  <ContactsTabs
                    activeTab={subPage}
                    match={props.match}
                    history={props.history}
                  />
                )
              }} />
            </Col>
          </Row>
        </Switch>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Notifications are sent after a Metric changes state and that signal is passed through Contact Nodes until it reaches a Contact Address. Notifications for problem states are typically set to recur at a fixed interval until either the Metric changes state again or that Metric is Acknowledged in some way.</p>
        ]
      }
    ];
  }
}

Notifications.propTypes = {
  alerts: PropTypes.array,
}

Notifications.defaultProps = {
  alerts: []
}

export default withRouter(Notifications);
