import React from 'react';
import Projects from './Projects';
import TabbedPage from '../components/TabbedPage';
import Page from '../components/Page';

const DevelopmentTabs = props => {
  const developmentTabs = [
    { title: 'Projects', key: 'glsmon/projects', content: <Projects {...props} /> }
  ];
  const tabbedPage = <TabbedPage
    router={{ match: props.match, history: props.history }}
    tabs={developmentTabs}
    activeTab='glsmon/projects'
  />;

  return <Page view={tabbedPage} {...props} />;
};

export default DevelopmentTabs;
