import GLSAPI from './api/api_config';

function setUser(user) {
  return window.localStorage.setItem('user', JSON.stringify(user));
}

async function getUser() {
  // pretend that the API returns important info 
  // which we save to LocalStorage on login
  // return: { id: 7, username: "fc" }
  let user = window.localStorage.getItem('user');

  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
}

async function removeUser() {
  // window.localStorage.removeItem('user');
}

async function checkAuthenticated() {
  let status = null;
  await GLSAPI.endpoints['user'].getAll().then((response) => {
    status = true;
  }).catch((error) => {
    status = false;
  });

  return status;
}

export { setUser, getUser, removeUser, checkAuthenticated };
