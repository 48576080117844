import React from 'react';
import { withRouter } from 'react-router-dom';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import { projectColumns } from '../api/schema/project';

class ProjectsBrowsePublic extends React.Component {

  constructor(props) {
    super(props);

    this.tableOptionsModel = {
      type: 'projects',
      title: 'Projects',
      rowsPerPage: 5,
      rowCount: 16,
      currentPage: 1
    };

    this.panelHeader = item => <a href="/" className="underline">{item.project}</a>;
  }
  render() {


    return (
      <div className="ProjectsBrowsePublic view">
        <div className="page-area--tabbed">

          <h1 className="header-bar no-margin">Browse Public Projects</h1>

          <section className="view-section view-section--no-border">
            <DataWrapper
              pageTitle="Projects"
              api="project"
              requiredParams={{ is_public: 1 }}
              columns={projectColumns}
              actions={[
                {
                  name: "View",
                  type: "primary",
                  func: this.viewProject,
                  enabled: (record) => {
                    // create a rule to determine if the action is available
                    return true
                  }
                },
              ]}
              tableOptionsModel={this.tableOptionsModel}
            >
              {(props, state, actions) => (
                <>
                  <div className="view-section">
                    <DynamicTable
                      {...props}
                      {...state}
                      {...actions}
                    />
                  </div>
                </>
              )}
            </DataWrapper>
          </section>
        </div>
      </div>
    );
  }

  viewProject = (id) => {
    this.props.history.push(`/glsmon/projects/${id}`);
  }
}

export default withRouter(ProjectsBrowsePublic);
