import * as React from 'react';
import Page from '../components/Page';
import { Spin } from 'antd';

import './LoadingScreen.scss';

function LoadingScreen(props) {
  return (
    <Page view={
      <div className="LoadingScreen">
        <Spin />
        <h2>Loading. . .</h2>
      </div>
    } />
  )
}

export default LoadingScreen;