import React from 'react';

export const dashboardAlerts = [
  {
    type: 'warning',
    title: '',
    content: [<p>Detailed description about an informative event.</p>]
  }
];

export const notifConfigAlerts = [
  {
    type: 'info',
    title: '',
    content: [<p>Notifications are sent after a Metric changes state and that signal is passed through Contact Nodes until it reaches a Contact Address. Notifications for problem states are typically set to recur at a fixed interval until either the Metric changes state again or that Metric is Acknowledged in some way.</p>]
  }
];

export const accountsAlerts = [
  {
    type: 'info',
    content: [
      <p>Customer accounts own Hosts, Projects, and most other object types - except for Users, which are independent entities that can be linked to multiple Customers. A logged in User can use the 'Browse As' button below to change their current browser view and edit permissions to match any Customer they are linked to.</p>,
      <p>In general one Customer should not be able to view or edit objects from another Customer, with some exceptions such as publically-shared Projects. These permissions are not fully enforced at the moment, since permission bugs were getting in the way of higher priority development issues.</p>,
      <p>Multiple Customers on one server should work fine, and that separation should be mostly honored, but some data and permissions may leak between them so it should not yet be treated as a proper security boundary.</p>
    ]
  }
]

export const hostsAlerts = [
  {
    type: 'info',
    // title: 'Info',
    content: [
      <p>Hostgroups are used to group Hosts, both to help visually organize large monitoring installations as well as to allow bulk configuration across similar or related hosts.</p>,
      <p>To register a host from its command line, see the Register PINs page. Hosts will appear in this list once they have registered themselves (or upon manual creation via browser), and will be licensed by default if any unused licenses are available.</p>,
      <p>Hosts are considered 'Down' when they have not reported within the past 5 minutes, at which point Notifications for Metrics on that Host (other than the 'Host Status' Metric itself) will be prevented until the Host begins reporting to the server again.</p>
    ]
  }
];

export const hostgroupsAlerts = [
  {
    type: "info",
    title: "",
    content: [<p>Hostgroups are used to group Hosts, both to help visually organize large monitoring installations as well as to allow bulk configuration across similar or related hosts.</p>]
  }
];


export const usersAlerts = [
  {
    type: 'info',
    title: "",
    content: [<p>Users exist to provide login credentials to the browser interface, and are linked to one or more Customers which they may browse as to share that Customer's permissions. Users will eventually (but not yet) be able to own their own Contact Addresses and Nodes.</p>]
  }
];


export const projectsAlerts = [
  {
    type: 'info',
    title: "",
    content: [
      <p>Projects are used to manage related groups of Checks and other Files used directly or indirectly by Queries to generate Metrics.</p>,
      <p>Projects exist as individual branches within git repositories. By default each repository contains a single project, but cloned projects will exist as separate branches within a single repository for simpler development of related projects.</p>,
      <p>In order to run any Checks from a Project on a Host, the Host must have explicit permission to pull that Project form the server. The list table below shows which Hosts have which types of pull permissions, as well as which have (at some point) synced to each project.</p>,
      <p>In addition to pull permissions, Hosts may have push permissions per Project, allowing development to be done on such a Host. In this case any commits created by that Host will be automatically pushed to the server, and from there to any other Hosts that pull from the same Project.</p>
    ]
  }
]

export const metricsAlerts = [
  {
    type: 'info',
    content: [
      <p>Metrics are generated by running Queries on Hosts, and describe the current state of the Host and services running on it.</p>,
      <p>See also the list of Metric Events for a similar but slightly different view of current Metric states.</p>,
    ]
  }
];
export const eventsAlerts = [
  {
    type: 'info',
    content: [
      <p>Metric Events record every time a Metric changes state (such as 'OK' to 'Critical', not mere output text changes). This page is linked a few times from the header with different url parameters to sort and filter in different ways - the active filters and sort priorities are listed below.</p>
    ]
  }
];
