import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import AlertBar from '../components/AlertBar';
import DataWrapper from '../components/DataWrapper';
import DashboardPanel from '../components/DashboardPanel';
import { UserConsumer } from '../UserContext';
import { dashboardAlerts } from '../theming/_props-mock/AlertBarProps';
import { metricColumnsCondensed, metricActions } from '../api/schema/metric';
import { hostColumnsCondensed, hostActions } from '../api/schema/host';
import { notificationColumnsCondensed, notificationActions } from '../api/schema/notification';
import { metricAckColumnsCondensed, metricackAction } from '../api/schema/metricAck';
import { hostAckCondensedColumns, hostAckAction } from '../api/schema/hostAck';
import './Dashboard.scss';

class Dashboard extends React.Component {

  render() {
    return (
      <div className="Dashboard view">
        <AlertBar alert={dashboardAlerts[0]} showIcon />
        <UserConsumer>
          {({ currentCustomer }) => (
            <>
              <Row gutter={8}>
                <Col md={24} lg={15}>
                  <DataWrapper
                    api="metric"
                    tableOptionsModel={{ type: 'dashboard_panel', rowsPerPage: 5, currentPage: 1 }}
                    showPagination={false}
                    title="Metric Problems"
                    allLink="/glsmon/metrics"
                    columns={metricColumnsCondensed}
                    requiredParams={{
                      customer_id: currentCustomer.id,
                      //metric_state_type: [2, 1] // this should be an array of "problem" states
                    }}
                    actions={metricActions(this.props.history)}
                  >
                    {(props, state, actions) => (
                      <DashboardPanel
                        {...props}
                        {...state}
                        {...actions}
                      />
                    )}
                  </DataWrapper>
                </Col>
                <Col md={24} lg={9}>
                  <DataWrapper
                    api="host"
                    tableOptionsModel={{ type: 'dashboard_panel', rowsPerPage: 5, currentPage: 1 }}
                    showPagination={false}
                    title="Host Problems"
                    allLink="/glsmon/hosts"
                    columns={hostColumnsCondensed}
                    requiredParams={{
                      customer_id: currentCustomer.id,
                    }}
                    actions={hostActions(this.props.history)}
                  >
                    {(props, state, actions) => (
                      <DashboardPanel
                        {...props}
                        {...state}
                        {...actions}
                      />
                    )}
                  </DataWrapper>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <DataWrapper
                    api="notification"
                    tableOptionsModel={{ type: 'dashboard_panel', rowsPerPage: 5, currentPage: 1 }}
                    showPagination={false}
                    title="Recent Notification Log"
                    allLink="/glsmon/notifications"
                    columns={notificationColumnsCondensed}
                    requiredParams={{
                      customer_id: currentCustomer.id,
                    }}
                    actions={notificationActions(this.props.history)}
                  >
                    {(props, state, actions) => (
                      <DashboardPanel
                        {...props}
                        {...state}
                        {...actions}
                      />
                    )}
                  </DataWrapper>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col md={24} lg={15}>
                  <DataWrapper
                    api="metricack"
                    tableOptionsModel={{ type: 'dashboard_panel', rowsPerPage: 5, currentPage: 1 }}
                    showPagination={false}
                    title="Recent Metric Ack Summary"
                    allLink="/glsmon/metricack"
                    columns={metricAckColumnsCondensed}
                    requiredParams={{
                      customer_id: currentCustomer.id,
                    }}
                    actions={metricackAction(this.props.history)}
                  >
                    {(props, state, actions) => (
                      <DashboardPanel
                        {...props}
                        {...state}
                        {...actions}
                      />
                    )}
                  </DataWrapper>
                </Col>
                <Col md={24} lg={9}>
                  <DataWrapper
                    api="hostack"
                    columns={hostAckCondensedColumns}
                    tableOptionsModel={{ type: 'dashboard_panel', rowsPerPage: 5, currentPage: 1 }}
                    showPagination={false}
                    title="Recent Host Ack Summary"
                    allLink="/glsmon/hostack"
                    requiredParams={{
                      customer_id: currentCustomer.id,
                    }}
                    actions={hostAckAction(this.props.history)}
                  >
                    {
                      (props, state, actions) => (
                        <DashboardPanel
                          {...props}
                          {...state}
                          {...actions}
                        />
                      )
                    }
                  </DataWrapper>
                </Col>
              </Row>
            </>
          )}
        </UserConsumer>
      </div>
    );
  }
}

export default withRouter(Dashboard);
