import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import Icon from '@ant-design/icons';

import IconHosts from '../../theming/icons/IconHosts';
import IconPositive from '../../theming/icons/IconPositive';
import IconError from '../../theming/icons/IconError';

import { useUserState } from '../../UserContext';
import { formatTimestampSeconds } from '../../helpers';

export const hostIcon = (props) => <Icon component={IconHosts}  {...props} />;

export const hostColumns = [
  {
    title: "Host",
    key: "host",
    field: "host",
    render: (text, record) => <Link className="underline" to={"/glsmon/hosts/" + record.id}>{record.name}</Link>
  },
  {
    title: "Licensed",
    key: "is_licensed",
    dataIndex: "is_licensed",
    render: (text, record) => {
      return record.is_licensed
        ? <span className="positive">Yes</span>
        : <span className="danger">No</span>
    }
  },
  {
    title: "Registered",
    key: "is_registered",
    dataIndex: "is_registered",
    render: (text, record) => {
      return record.is_registered
        ? <span className="positive">Yes</span>
        : <span className="danger">No</span>
    }
  },
  {
    title: "Host is up",
    key: "is_up",
    field: "is_up",
    render: (text, record) => {
      switch (record.is_up) {
        case true: return (<span className="inline-icon positive"><IconPositive /> Host Up</span>);
        case false: return (<span className="inline-icon danger"><IconError /> Host Down</span>);
        default: return <span className="inline-icon">Stale</span>;
      }
    }
  },
  {
    title: "Acked",
    dataIndex: "has_ack",
    key: "acked"
  },
  {
    title: "Last checked-in",
    dataIndex: "last_seen_time",
    key: "last_seen_time",
    render: (text, record) => {
      if (record.last_seen_time) {
        return formatTimestampSeconds(record.last_seen_time);
      }
    }
  },
  {
    title: "Hostgroups",
    key: "hostgroups",
    render: (text, record) => {
      let records = record.hostgroups ? record.hostgroups.slice(0, 2) : [];
      if (record.hostgroups && record.hostgroups.length > 2) {
        records.push({ name: `and ${record.hostgroups.length - 2} more`, id: `extra-hosts` });
      }
      return records.map(record => <div key={record.id}>{record.name}</div>);
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];

export const hostColumnsCondensed = [
  {
    title: "Host",
    key: "host",
    render: (text, record) => <Link className="underline" to={`/glsmon/hostgroups/${record.id}`}>{record.name}</Link>
  },
  {
    title: "Status",
    key: "status",
    render: (text, record) => {
      switch (record.is_up) {
        case true: return (<span className="inline-icon positive"><IconPositive /> Host Up</span>);
        case false: return (<span className="inline-icon danger"><IconError /> Host Down</span>);
        default: return <span className="inline-icon">Stale</span>;
      }
    }

  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {

          if (!action.enabled(record)) { return null; }

          return (
            <span key={`${record.id}-${action.name}`}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const hostFilterTypes = {
  "host": "MultiSelector",
};

function hostCustomerWidget(props) {
  const userState = useUserState();
  const resource = props.resource;
  const hostCustomer = userState.customers.find((customer) => customer.id === resource.customer_id)

  if (hostCustomer) {
    return (
      <div>
        <p>{hostCustomer.name}</p>
        <label>Customer</label>
      </div>
    )
  }
}

export const hostWidgets = {
  "customer": {
    render: (resource) => {
      if (resource) {
        return hostCustomerWidget({ resource: resource })
      }
    }
  },
  "is_licensed": {
    render: (resource) => {
      let icon = resource.is_licensed ? IconPositive : IconError;
      return (
        <div className="align-center">
          <strong>Licensed</strong>
          <Icon component={icon} />
        </div>
      )
    }
  },

  "is_registered": {
    render: (resource) => {
      let icon = resource.is_registered ? IconPositive : IconError;
      return (
        <div className="align-center">
          <strong>Registered</strong>
          <Icon component={icon} />
        </div>
      )
    }
  },
};

export const hostLabelMap = {
  "name": "Host Name"
}

export const hostActions = (history) => {
  return [
    {
      name: "View",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/hosts/${record.id}`);
      },
      enabled: (record) => {
        return false
      }
    },
    {
      name: "Ack",
      type: "default",
      func: (record) => {
        history.push(`/glsmon/hosts/${record.id}/ack/new`);
      },
      enabled: (record) => {
        return true
      }
    },
  ];
}
