import React from 'react';

const IconError = props => {
  const { width, height } = props;

  return (
    <svg className="IconError gls-icon" width={width || "22px"} height={height || "22px"} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-491.000000, -5285.000000)">
          <g id="error" transform="translate(478.000000, 5272.000000)">
            <g id="Group-5">
              <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
            </g>
            <path d="M24.0001383,13 C17.9248777,13 13,17.9235566 13,24 C13,30.0731241 17.9248777,35 24.0001383,35 C30.0756755,35 35,30.0731241 35,24 C34.9997234,17.9235566 30.0753989,13 24.0001383,13 Z M28.1494273,30.2219372 L24.0001383,26.0739791 L19.8519557,30.2219372 L17.7780027,28.1479582 L21.9259087,23.998617 L17.7780027,19.8506588 L19.8519557,17.7766797 L23.9998617,21.9246379 L28.1474911,17.7783394 L30.2214441,19.8523184 L26.0738147,23.998617 L30.2228271,28.1479582 L28.1494273,30.2219372 Z" id="HostDown" fill="#DE2E28" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconError;
