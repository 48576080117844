import React from 'react';
import { Button } from 'antd';

export const queryColumns = [
  {
    title: "Name",
    key: "name",
    render: (text, record) => <span className="bold">{record.name}</span>
  },
  {
    title: "Project",
    key: "project",
    dataIndex: "project",
    render: (text, record) => (record ? record.project[0].name : '')
  },
  {
    title: "Check",
    key: "check",
    dataIndex: "check",
    render: (text, record) => (record.checkx.name)
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];

export const queryFilterTypes = {};