import React from 'react';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'antd';
import DataWrapper from '../components/DataWrapper';
import AlertBar from '../components/AlertBar';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import IconPositive from '../theming/icons/IconPositive';
import IconError from '../theming/icons/IconError';
import IconWarning from '../theming/icons/IconWarning';
import IconUnknown from '../theming/icons/IconUnknown';
import { hostAckColumns, hostAckActions } from '../api/schema/hostAck';

class HostAcks extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);
    this.toggler = { offLabel: 'Show All', onLabel: 'Show Problems', defaultChecked: true };
    this.tableOptionsModel = { type: 'hostAcks', title: 'Host Acks', rowsPerPage: 5, currentPage: 1 };
  }

  render() {
    return (
      <div className="HostAcks view">
        <div className="page-area--tabbed">
          <h1 className="header-bar">Host Acks</h1>
          <div className="page-info">
            {this.alerts.map((alert, i) => {
              return <AlertBar key={i} alert={alert} showIcon />
            })}
          </div>
          <DataWrapper
            api="hostack"
            columns={hostAckColumns}
            tableOptionsLinks={this.tableOptionsLinks}
            tableOptionsModel={this.tableOptionsModel}
            panelHeader={this.panelHeader}
            actions={hostAckActions(this.props.history)}
          >
            {
              (props, state, actions) => (
                <>
                  <section className="view-section filters-section filters-section--expanded">
                    <header className="view-section-header">
                      <h3 className="view-section-title">Available Filters</h3>
                      <Filter expanded expandable
                        {...props}
                        {...state}
                        {...actions}
                      />
                    </header>
                  </section>

                  <section className="view-section metrics-section">
                    <DynamicTable
                      {...props}
                      {...state}
                      {...actions}
                    />
                  </section>
                </>
              )
            }
          </DataWrapper>
        </div>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Like Metric Acknowledgements, but for entire Hosts rather than individual Metrics. A Host Acknowledgement will prevent Notifications from being sent for any Metrics from the specified Host.</p>,
          <p>When an Ack is deleted (either manually or because of an automated ending condition), any ongoing recurring Notifications that would have sent during the acked period will send once immediately, then recur at their normal rate from that point. (That is, an Ack pauses notifications rather than skips them. If a notification would have been sent while acked, and is still actively relevent when the Ack is cleared, it will resume its normal Notification schedule immediately rather than waiting to sync to the schedule it would have been on without the ack)</p>
        ]
      }
    ];
  }

  panelHeader = item => {
    let statusIcon;
    switch (item.status) {
      case "up":
        statusIcon = <IconPositive />;
        break;
      case "down":
        statusIcon = <IconError />;
        break;
      case "warning":
        statusIcon = <IconWarning />;
        break;
      default: statusIcon = <IconUnknown />
    }
    return (
      <span className="panel-header-hosts inline-icon" href="/">
        <Checkbox />
        {statusIcon}
        <a className="underline" href="/">{item.host.name}</a>
      </span>
    );
  };
}

HostAcks.defaultProps = {
  alerts: []
}

export default withRouter(HostAcks);
