import React from 'react';
import { Button, Input, InputNumber, Form, Radio, message } from 'antd';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';
import MultiSelector from '../components/MultiSelector';
import BackLink from '../components/BackLink';
const RadioGroup = Radio.Group;

class CustomerAccountsAdd extends React.Component {
  constructor(props) {
    super(props);

    let resourceId = (props.match ? props.match.params.id : 0); // TEMP MOCK ID

    this.state = {
      customer: {},
      value: 1,
      fetched: this.props.actionType === 'create',
      availableUsers: [],
      selectedUserIds: [],
      resourceId: resourceId,
    }
  }

  componentDidMount() {
    if (this.state.resourceId) {
      GLSAPI.endpoints['customer'].getOne({ id: this.state.resourceId })
        .then((response) => {
          let customer = response;
          this.setState({
            customer: customer,
            fetched: true,
            selectedUserIds: customer.user_id,
          });
        }).catch((error) => {
          this.setState({
            loading: false
          })
        });
    }

    GLSAPI.endpoints['user'].getAllPages()
      .then((response) => {
        let users = response.map((item) => { return { ...item, key: item.id } });

        this.setState({
          availableUsers: users
        });
      }).catch((error) => {
        this.setState({
          loading: false
        })
      });
  }
  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  }

  render() {
    const { viewTitle, actionType } = this.props;
    const apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['customer'].create
      : GLSAPI.endpoints['customer'].edit;

    return (
      <div className="CustomerAccountsAdd view">
        <div className="page-area--tabbed">

          <BackLink {...this.props} />

          <h1 className="header-bar">{viewTitle}</h1>

          <WrappedDataForm
            title=""
            apiFunction={apiFunction}
            apiResource="customer"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            verbs={this.getVerbs()}
          >
            {this.addCustomerForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  onSubmitCallback = (customer) => {
    this.props.history.push('/glsmon/customers');
  }

  onErrorCallback = (error) => {
    if (error.message) {
      message.destroy();
      message.error(error.message);
    }
  }

  addCustomerForm = (defaultFormItemLayout) => {
    if (!this.state.fetched) return null;
    return (
      <div>
        <div className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultFormItemLayout} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }

          <Form.Item label={<span><span>Customer Name</span><span className="form-item-label-description">
            This is restricted to a [-\w./] character set for now: This is still used unfiltered in filesystem (git repo) paths</span></span>}
            {...defaultFormItemLayout}
            name="name"
            initialValue={this.state.customer.name}
            rules={[{
              required: true, message: 'Customer name is required and spaces are not allowed', pattern: /^[\w./-]+$/,
              validator: (_, value) => {
                if (!value || value.trim().length === 0) {
                  return Promise.reject(new Error('Customer name cannot be empty'));
                }
                if (/\s/.test(value)) {
                  return Promise.reject(new Error('Spaces are not allowed in the customer name'));
                }
                return Promise.resolve();
              },
            }]}
          >
            <Input placeholder={"Customer name"} disabled={this.props.actionType === 'edit'} />
          </Form.Item>

          <Form.Item label="License Count" {...defaultFormItemLayout}
            name="license_count"
            initialValue={this.state.customer.license_count}
            rules={[{ required: true, message: 'License count is required' }]}
          >
            <InputNumber />
          </Form.Item>
        </div>
        {this.props.actionType === 'create' ?
          <div className="view-section">
            <RadioGroup onChange={this.onChange} value={this.state.value}>
              <Radio value={1}>Existing User</Radio>
              <Radio value={2}>New User</Radio>
            </RadioGroup>
          </div>
          :
          <div></div>
        }
        {this.state.value == 1 ?
          <div className="view-section">
            <Form.Item label="Users" labelCol={{ xs: { span: 24 } }} colon={false}
              name="user_id"
              initialValue={this.state.selectedUserIds}
            >
              <MultiSelector
                dataIndex="name"
                dataSource={this.state.availableUsers}
                targetKeys={this.state.selectedUserIds}
                titles={["Available Users", "Added Users"]}
              />
            </Form.Item>
          </div>
          :
          <div className="view-section">
            <Form.Item label="Invite Email" {...defaultFormItemLayout} name="invite_email" rules={[{ required: true, message: 'An email address is required' }]}>
              <Input placeholder="myemail@example.com" />
            </Form.Item>
          </div>
        }

        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  }

  editCustomerForm = (defaultFormItemLayout) => {
  }
}

export default CustomerAccountsAdd;
