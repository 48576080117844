import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UsersAdd from './UsersAdd';
import UsersInvite from './UsersInvite';
import DataWrapper from '../components/DataWrapper';
import AlertBar from '../components/AlertBar';
import DynamicTable from '../components/DynamicTable';
import IconAdd from '../theming/icons/IconAdd';
import IconStar from '../theming/icons/IconStar';
import { usersColumns } from '../api/schema/user';
import { UserConsumer } from '../UserContext';
import axios from 'axios';

class Users extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [

      { icon: <IconAdd />, linkText: 'Invite a New User', href: '/glsmon/users/invite' },
      { icon: null, linkText: 'Create New User', href: '/glsmon/users/new' },
    ];

    this.tableOptionsModel = {
      type: 'users',
      title: 'Users',
      rowsPerPage: 5,
      currentPage: 1
    };
  }

  render() {
    let match = this.props.match;
    return (
      <div className="Users view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">
            <h1 className="header-bar">Users</h1>
            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return <AlertBar key={i} alert={alert} showIcon />
              })}
            </div>

            <section className="view-section hosts-section filters-section--expanded">
              <DataWrapper
                api="user"
                columns={usersColumns}
                tableOptionsLinks={this.tableOptionsLinks}
                tableOptionsModel={this.tableOptionsModel}
                panelHeader={this.panelHeader}
                actions={[
                  {
                    name: "Edit",
                    type: "primary",
                    func: this.Editusers,
                    enabled: (record) => {
                      // create a rule to determine if the action is available
                      return true
                    }
                  },
                  {
                    name: "Delete",
                    type: "primary",
                    func: this.deleteusers,
                    enabled: (record) => {
                      return true
                    }
                  }
                ]}
              >
                {
                  (props, state, actions) => (
                    <DynamicTable
                      {...props}
                      {...state}
                      {...actions}
                    />
                  )
                }
              </DataWrapper>
            </section>
          </div>
        )} />
        <Switch>
          <Route path="/glsmon/users/invite" render={(props) => (
            <UserConsumer>
              {(userState) => (
                <UsersInvite viewTitle="Invite User" actionType="create" {...props} userState={userState} />
              )}
            </UserConsumer>
          )} />
          <Route path="/glsmon/users/new" render={props => <UsersAdd viewTitle="Add User" actionType="create" {...props} />} />
          <Route path="/glsmon/users/:id" render={props => <UsersAdd viewTitle="Edit User" actionType="edit" {...props} />} />
        </Switch>
      </div>
    )
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        title: "",
        content: [<p>Users exist to provide login credentials to the browser interface, and are linked to one or more Customers which they may browse as to share that Customer's permissions. Users will eventually (but not yet) be able to own their own Contact Addresses and Nodes.</p>]
      }
    ];
  }

  Editusers = (user) => {
    this.props.history.push('/glsmon/users/' + user.id);
  }

  deleteusers = (user) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(user);
    var r = window.confirm(`Are you sure you want to delete this user id : ${user.id} `);
    if (r == true) {
      var obj = { type: user.type, user_id: user.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location = "/";
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }

  panelHeader = (item) => {
    return item.star
      ? <span className="bold"><IconStar /> {item.username}</span>
      : <span className="bold"><span className="void-icon"></span>{item.username}</span>
  }
}

Users.defaultProps = {
  alerts: []
}

export default Users;
