import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ProjectsAdd from './ProjectsAdd';
import ProjectsBrowsePublic from './ProjectsBrowsePublic';
import ProjectsCheckAdd from './ProjectsCheckAdd';
import ProjectsFilesAdd from './ProjectsFilesAdd';
import ProjectsViewing from './ProjectsViewing';
import DataWrapper from '../components/DataWrapper';
import AlertBar from '../components/AlertBar';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable'
import IconAdd from '../theming/icons/IconAdd';
import IconPositive from '../theming/icons/IconPositive';
import IconError from '../theming/icons/IconError';
import axios from 'axios';
import { projectColumns, projectFilterTypes } from '../api/schema/project';

class Projects extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Add Project', href: '/glsmon/projects/new' },
    ];

    this.tableOptionsModel = {
      type: 'projects',
      title: 'Projects',
      rowsPerPage: 5,
      rowCount: 16,
      currentPage: 1
    };
  }
  render() {
    let match = this.props.match;
    return (
      <div className="Projects view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">

            <h1 className="header-bar">Existing Projects</h1>
            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return <AlertBar key={i} alert={alert} showIcon />
              })}
            </div>

            <DataWrapper
              pageTitle="Projects"
              api="project"
              columns={projectColumns}
              actions={[
                {
                  name: "View",
                  type: "primary",
                  func: this.viewProject,
                  enabled: (record) => {
                    // create a rule to determine if the action is available
                    return true
                  }
                },
                {
                  name: "Delete",
                  type: "primary",
                  func: this.deleteProject,
                  enabled: (record) => {
                    return true
                  }
                }
              ]}
              filterTypes={projectFilterTypes}
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
            >
              {(props, state, actions) => (
                <>
                  <div className="view-section filters-section filters-section--expanded">
                    <header className="view-section-header">
                      <h3 className="view-section-title">Available Filters</h3>
                      <Filter expanded expandable
                        {...props}
                        {...state}
                        {...actions}
                      />
                    </header>
                  </div>
                  <div className="view-section">
                    <DynamicTable
                      {...props}
                      {...state}
                      {...actions}
                    />
                  </div>
                </>
              )}
            </DataWrapper>
          </div>
        )} />
        <Switch>
          <Route path="/glsmon/projects/new" component={ProjectsAdd} />
          <Route path="/glsmon/projects/browse-public" component={ProjectsBrowsePublic} />
          <Route path="/glsmon/projects/:id/checks/new" render={(props) => <ProjectsCheckAdd actionType="create" {...props} />} />
          <Route path="/glsmon/projects/:id/checks/:resource_id" render={(props) => <ProjectsCheckAdd actionType="edit" {...props} />} />
          <Route path="/glsmon/projects/:id/files/new" render={(props) => <ProjectsFilesAdd actionType="create" {...props} />} />
          <Route path="/glsmon/projects/:id/files/:resource_id" render={(props) => <ProjectsFilesAdd actionType="edit" {...props} />} />
          <Route path="/glsmon/projects/:id/:subpage" component={ProjectsViewing} />
          <Route path="/glsmon/projects/:id" component={ProjectsViewing} />
        </Switch>
      </div>
    )
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        title: "",
        content: [
          <p>Projects are used to manage related groups of Checks and other Files used directly or indirectly by Queries to generate Metrics.</p>,
          <p>Projects exist as individual branches within git repositories. By default each repository contains a single project, but cloned projects will exist as separate branches within a single repository for simpler development of related projects.</p>,
          <p>In order to run any Checks from a Project on a Host, the Host must have explicit permission to pull that Project form the server. The list table below shows which Hosts have which types of pull permissions, as well as which have (at some point) synced to each project.</p>,
          <p>In addition to pull permissions, Hosts may have push permissions per Project, allowing development to be done on such a Host. In this case any commits created by that Host will be automatically pushed to the server, and from there to any other Hosts that pull from the same Project.</p>
        ]
      }
    ];
  }

  panelHeader = item => {
    let icon = item.public ? <IconPositive /> : <IconError />;
    return (
      <span className="inline-icon">
        {icon} <a href="/" className="underline">{item.project}</a>
      </span>
    );
  };

  viewProject = (project) => {
    this.props.history.push(`/glsmon/projects/${project.id}`);
  }
  deleteProject = (project) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(project);
    var r = window.confirm(`Are you sure you want to delete this project id : ${project.id} `);
    if (r == true) {
      var obj = { type: project.type, project_id: project.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}


Projects.defaultProps = {
  alerts: []
}
export default withRouter(Projects);
