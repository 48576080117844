import React from 'react';

import IconArrowLeft from '../theming/icons/IconArrowLeft';

const BackLink = ({ history }) => (
  <div className="back-link-wrap">
    <a href="/" className="inline-icon" onClick={(e) => { e.preventDefault(); history.goBack() }}>
      <IconArrowLeft /> Back
    </a>
  </div>
);

export default BackLink;