import React from 'react';
import Icon from '@ant-design/icons';

import IconPositive from '../icons/IconPositive';
import IconError from '../icons/IconError';
import IconHosts from '../icons/IconHosts';
import IconHostsGroup from '../icons/IconHostsGroup';
import IconMetrics from '../icons/IconMetrics';

export const hostResource = {
  "acked_contact_count": "0",
  "customer": {
    "id": "2",
    "name": "customer_4",
    "type": "customer"
  },
  "has_ack": 0,
  "has_global_ack": "0",
  "has_non_global_ack": "0",
  "hostgroups": [],
  "id": "11",
  "is_licensed": "1",
  "is_registered": "0",
  "is_up": "0",
  "last_seen_time": 1552897571,
  "name": "customer_4_host_1",
  "type": "host"
};

export const hostPropertyWidgets = {
  "customer": (resource) => {
    return (
      <div>
        <p>{resource.customer.name}</p>
        <label>Customer</label>
      </div>
    )
  },
  "is_licensed": (resource) => {
    let icon = resource.is_licensed === "1" ? IconPositive : IconError;
    return (
      <div className="align-center">
        <strong>Licensed</strong>
        <Icon component={icon} />
      </div>
    )
  },
};

export const hostLabelMap = {
  "name": "Host Name"
}

export const hostIcon = (props) => <Icon component={IconHosts}  {...props} />;


/**
 * Hostgroup
 */
export const hostgroupResource = {
  "acked_contact_count": "0",
  "host": {
    "id": "2",
    "name": "metrics.linuxglobal.net, irc.ewheeler.net",
    "type": "host"
  },
  "has_ack": 0,
  "has_global_ack": "0",
  "has_non_global_ack": "0",
  "hostgroups": [],
  "id": "11",
  "is_licensed": "1",
  "is_registered": "0",
  "is_up": "0",
  "last_seen_time": 1552897571,
  "name": "Hostgroup1",
  "type": "hostgroup"
}

export const hostgroupPropertyWidgets = {
  "hosts": (resource) => {
    if (resource && resource.hosts && resource.hosts.length > 0) {
      return (
        <div>
          <p>{resource.hosts[0].name}</p>
          <label>Hosts</label>
        </div>
      );
    }
  }
}

export const hostgroupLabelMap = {
  "name": "Hostgroup Name"
}

export const hostgroupIcon = (props) => <Icon component={IconHostsGroup}  {...props} />;

export const registerIcon = (props) => <Icon component={IconHosts}  {...props} />;



/**
 * Project
 */
export const projectResource = {
  "acked_contact_count": "0",
  "customer": {
    "id": "2",
    "name": "EFN_dev_host",
    "type": "customer"
  },
  "has_ack": 0,
  "has_global_ack": "0",
  "has_non_global_ack": "0",
  "hostgroups": [],
  "id": "11",
  "is_licensed": "1",
  "is_registered": "0",
  "is_up": "0",
  "last_seen_time": 1552897571,
  "name": "EFN_project",
  "type": "project"
};

export const projectPropertyWidgets = {
  "customer": (resource) => {
    return (
      <div>
        <p>{resource.customer.name}</p>
        <label>Customer</label>
      </div>
    );
  }
}

export const projectLabelMap = {
  "name": "Project Name"
}

export const projectIcon = (props) => <Icon component={IconHostsGroup}  {...props} />;


/**
 * Metrics
 */
export const metricsViewingResource = {
  "acked_contact_count": "0",
  // "customer": {
  //     "id": "2",
  //     "name": "EFN_dev_host",
  //     "type": "customer"
  // },
  "has_ack": 0,
  "has_global_ack": "0",
  "has_non_global_ack": "0",
  "hostgroups": [],
  "id": "11",
  "is_licensed": "1",
  "is_registered": "0",
  "is_up": "0",
  "last_seen_time": 1552897571,
  "name": "metrics-dev.linuxglobal.net",
  "type": "metric"
};

export const metricsViewingWidgets = {};

export const metricsViewingLabels = { "name": "Metric" };

export const metricsViewingIcon = (props) => <Icon component={IconMetrics}  {...props} />;

// Events
export const eventsViewingResource = {
  "acked_contact_count": "0",
  "check": {
    "id": "2",
    "name": "metrics-dev.linuxglobal.net - random_check",
    "type": "check",
    "status": "Ok",
    "last_seen_time": 1557936409650
  },
  // "has_ack": 0,
  // "has_global_ack": "0",
  // "has_non_global_ack": "0",
  // "hostgroups": [],
  // "id": "11",
  // "is_licensed": "1",
  // "is_registered": "0",
  // "is_up": "0",
  // "last_seen_time": 1552897571,
  "name": "metrics-dev.linuxglobal.net - Host Status",
  "type": "event"
};

export const eventsViewingWidgets = {
  "check": (resource) => {
    console.log('last-seen: ', resource.check.last_seen_time)
    console.log('now: ', Date.now())

    let lastSeen = (Date.now() - resource.check.last_seen_time) / 1000
    let statusClassName = resource.check.status === "Ok" ? "positive" : "danger";
    return (
      <div>
        <p className="bold">{resource.check.name}</p>
        <label>is in <span className={statusClassName}>{resource.check.status}</span> state for {lastSeen.toFixed()} seconds</label>
      </div>
    );
  }
};

export const eventsViewingLabels = { "name": "Event" };

export const eventsViewingIcon = props => <Icon component={IconMetrics} {...props} />;