import React from 'react';
import PropTypes from 'prop-types';

import GLSAPI from '../api/api_config';

import { reFormatFilters, addSortingToColumns } from '../helpers';

import { UserStateContext } from '../UserContext';

class DataWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      requiredParams: { ...props.requiredParams },
      rowsPerPage: this.props.tableOptionsModel.rowsPerPage,
      data: [],
      columns: this.props.columns,
      enabledFilters: [],
      availableFilters: [],
      enabledSorts: [],
      loading: true,
      currentPage: 1,
      lastContext: this.context
    }

  }

  componentDidMount() {
    this.fetchData(this.state.requiredParams);
  }

  componentDidUpdate(prevProps) {
    let newProps = this.props;
    if (newProps.requiredParams) {
      if (newProps.requiredParams !== prevProps.requiredParams) {
        this.setState({
          requiredParams: newProps.requiredParams,
          loading: true,
        }, () => {
          this.fetchData();
        })
      }
    }

    if (this.context && this.context !== this.state.lastContext) {
      this.setState({
        loading: true,
        lastContext: this.context
      }, () => {
        this.fetchData();
      })
    }

  }

  responseMapper = (item, i) => {
    return { ...item, actions: this.props.actions };
  }

  fetchData(params, newState) {
    let combinedParams = { ...params, ...this.state.requiredParams, page_rows: this.state.rowsPerPage };// customer_id: this.context.state.currentCustomer.id};

    GLSAPI.endpoints[this.props.api].getAll(combinedParams)
      .then((response) => {
        let availableFilters = reFormatFilters(response.available_filters, this.props.filterTypes);
        let columns = addSortingToColumns(this.state.columns, response.custom_sort);

        let rowCount = response.list_meta ? parseInt(response.list_meta.object_count) : (response.objects ? response.objects.length : 0);
        let rowsPerPage = this.props.tableOptionsModel.rowsPerPage || response.list_meta.rows_per_page;

        let data = response.objects.map(this.responseMapper);

        this.setState({
          loading: false,
          data: data,
          availableFilters: availableFilters,
          rowCount,
          rowsPerPage,
          columns,
          ...newState
        });
      }).catch((error) => {
        this.setState({
          loading: false
        })
      });
  }

  render() {
    let actions = {
      handleSortChange: this.onSortChange,
      handlePageChange: this.onPageChange,
      onFilterSelectionChange: this.onFilterChange
    };
    return this.props.children(this.props, this.state, actions);
  }

  onFilterChange = (enabledFilters) => {
    this.setState({
      loading: true
    })

    const newRequestData = {};

    enabledFilters.forEach((filter) => {
      newRequestData[filter.key] = filter.value
    });

    let existingParams = {
      ...this.state.requiredParams,
      "sort_by": this.state.enabledSorts,
      "page_rows": this.state.rowsPerPage,
    };

    this.fetchData({ ...newRequestData, ...existingParams }, { currentPage: 1 });

  }

  onSortChange = (sort) => {
    this.setState({
      loading: true
    });

    let enabledSorts = [];

    if (Object.keys(sort).length === 0) {
      this.setState({
        loading: false,
        enabledSorts: enabledSorts,
      });
      return;
    }

    let sortKey = sort.column.field;
    let order = sort.order === "ascend" ? "asc" : "desc";
    let newSortObject = {};

    newSortObject[sortKey] = order;

    enabledSorts.push(newSortObject);

    let newRequestData = {
      "sort_by": enabledSorts
    };

    let existingParams = {
      ...this.state.requiredParams,
      "page_rows": this.state.rowsPerPage
    };

    this.state.enabledFilters.forEach((filter) => {
      existingParams[filter.key] = filter.value
    });

    this.fetchData({ ...newRequestData, ...existingParams }, { enabledSorts, currentPage: 1 });

  }

  onPageChange = (pageNum) => {
    this.setState({
      loading: true
    });

    let newRequestData = {
      page: pageNum
    }

    let existingParams = {
      ...this.state.requiredParams,
      "sort_by": this.state.enabledSorts,
      "page_rows": this.state.rowsPerPage
    };

    this.state.enabledFilters.forEach((filter) => {
      existingParams[filter.key] = filter.value
    });

    this.fetchData({ ...newRequestData, ...existingParams }, { currentPage: pageNum });

  }
}

DataWrapper.contextType = UserStateContext;

DataWrapper.propTypes = {
  pageTitle: PropTypes.string,
  api: PropTypes.string,
  requiredParams: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.array,
  panelHeaderKey: PropTypes.string,
  filterTypes: PropTypes.object,
  showPagination: PropTypes.bool,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  tableOptionsLinks: PropTypes.array,
  tableOptionsModel: PropTypes.object,
  tableOptionsToggler: PropTypes.object,
}

DataWrapper.defaultProps = {
  requiredParams: {},
  actions: [],
  showPagination: true,
  expandable: true,
  expanded: true,
  filterTypes: {},
}

export default DataWrapper;