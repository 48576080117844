import React from 'react';
import PropTypes from 'prop-types';

import { Form, message } from 'antd';

import './DataForm.scss';

class WrappedDataForm extends React.Component {

  formLayout = 'horizontal';

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    colon: false
  }

  onFinish = (values) => {
    delete values.confirm_password;

    if (this.props.specialFormatting) {
      let formattingRules = this.props.specialFormatting;
      let formatProperties = Object.keys(formattingRules);
      for (let i = 0; i < formatProperties.length; i++) {
        let propertyKey = formatProperties[i];
        values[propertyKey] = formattingRules[propertyKey](values[propertyKey]);
      }
    }

    if (this.props.apiFunction) {

      if (this.props.displayMessage) {
        message.loading(`${this.props.verbs[0]} ${this.props.apiResource}`, 0);
      }

      this.props.apiFunction(values)
        .then((response) => {
          if (this.props.displayMessage) {
            message.destroy();
            message.success(`${this.props.verbs[1]} ${this.props.apiResource} with id of: ${response.id}`, 5);
          }
          this.props.onSubmitCallback(response);
        }).catch((error) => {
          if (this.props.onErrorCallback) {
            this.props.onErrorCallback(error);
          } else {
            if (this.props.displayMessage) {
              message.destroy();
              message.error(`Something went wrong while ${this.props.verbs[0].toLowerCase()} ${this.props.apiResourceReadable ? this.props.apiResourceReadable : this.props.apiResource}`, 5);
            }
          }
        })
    }
  }

  render() {
    const { apiFunction, apiResource, onSubmitCallback, displayMessage, specialFormatting, onErrorCallback, initialValues, formRef, ...restProps } = this.props;
    return (
      <Form ref={formRef} layout={this.formLayout} onFinish={this.onFinish} initialValues={initialValues} className="DataForm" {...restProps}>
        <h2>{this.props.title}</h2>
        {this.props.children(this.formItemLayout)}
      </Form>
    );
  }
}

WrappedDataForm.propTypes = {
  apiResource: PropTypes.string,
  apiResourceReadable: PropTypes.string,
  apiFunction: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  onErrorCallback: PropTypes.func,
  verb: PropTypes.array,
  displayMessage: PropTypes.bool,
  initialValues: PropTypes.object,
  formRef: PropTypes.object,
}

WrappedDataForm.defaultProps = {
  verbs: ['Creating', 'Created'],
  displayMessage: true
}

export default WrappedDataForm;
