import React from 'react';
import Metrics from './Metrics';
import Events from './Events';
import MetricAcks from './MetricAcks';
import Page from '../components/Page';
import TabbedPage from '../components/TabbedPage';

const MetricsTabs = props => {
  const { activeTab } = props;
  const metricsTabs = [
    { title: 'Metrics', key: 'glsmon/metrics', content: <Metrics {...props} /> },
    { title: 'Events', key: 'glsmon/events', content: <Events {...props} /> },
    { title: 'MetricAcks', key: 'glsmon/metricack', content: <MetricAcks {...props} /> }
  ];
  const tabbedPage = <TabbedPage
    tabs={metricsTabs}
    activeTab={activeTab}
  />;

  return <Page view={tabbedPage} {...props} />;
}

export default MetricsTabs;
