import React from 'react';
import moment from 'moment';
import { Button, Input, InputNumber, Checkbox, Select, DatePicker, Form } from 'antd';
import GLSAPI from '../api/api_config';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';
import { formatTimeValueEpoch } from '../helpers';
import { UserStateContext } from '../UserContext';

class HostsAckEdit extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    let resourceId = (props.match ? props.match.params.id : 0);
    let parentHostId = parseInt((props.match ? props.match.params.hostId : 0));

    this.state = {
      resource: {},
      resourceId,
      fetched: this.props.actionType === 'create',
      parentHostId,
      availableHosts: [],
      is_global: 0,
      endTimeMethod: "1",
      availablecontactAddress: [],
      checkedAddress: []

    }
    this.handleChange = this.handleChange.bind(this);

  }

  fetchResourceData() {
    if (this.context && this.context.currentCustomer !== {}) {
      GLSAPI.endpoints['host'].getAllPages()
        .then((response) => {
          let availableHosts = response.map((item, i) => { return { ...item, key: i } });
          this.setState({
            availableHosts
          });
        }).catch((error) => {
        });
      GLSAPI.endpoints['contactaddress'].getAllPages()
        .then((response) => {
          let availablecontactAddress = response.map((item, i) => { return { ...item, key: i } });
          this.setState({
            availablecontactAddress
          });
        }).catch((error) => {
        });
      if (this.state.resourceId) {
        GLSAPI.endpoints['hostack'].getOne({ id: this.state.resourceId })
          .then((response) => {
            let checkedAddress = (response.contact_address_id ? response.contact_address_id : []);
            this.setState({
              loading: false,
              fetched: true,
              resource: response,
              host_id: response.host_id,
              contact_address_id: response.contact_address_id,
              checkedAddress
            },
              () => {
                this.formRef.current.setFieldsValue({
                  end_time: moment.unix(this.state.resource.end_time),
                  is_global: this.state.resource.is_global,
                  host_id: this.state.host_id,
                  contact_address_id: this.state.contact_address_id,

                })
              });
          }).catch((error) => {
            this.setState({
              loading: false,
              fetched: false,
            });
          });
      }
    }
  }

  componentDidMount() {
    this.fetchResourceData();
  }

  componentDidUpdate() {
  }

  render() {

    const { actionType } = this.props;
    this.apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['hostack'].create
      : GLSAPI.endpoints['hostack'].edit;

    return (
      <div className="HostsAckEdit view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">Edit Host Acknowledgement</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="hostack"
            apiResourceReadable="Host Acknowledgement"
            onSubmitCallback={this.onSubmitCallback}
            hideRequiredMark
            specialFormatting={{
              end_time: formatTimeValueEpoch
            }}
            formRef={this.formRef}
          >
            {this.editHostAckForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  onSubmitCallback = (hostack) => {
    this.setState({
      resourceId: hostack.id
    });
    this.props.history.push(`/glsmon/hostack`)
  }

  getCheckedValue = (e) => {
    if (e.target.checked === false) {
      this.setState({ is_global: 0 });
      return e.target.checked ? 1 : 0;
    }
    else {
      this.setState({ is_global: 1 });
      return e.target.checked ? 1 : 0;
    }
  };

  handleEndTimeSelection = (e) => {
    this.setState({
      endTimeMethod: e.target.value
    })
  }

  scheduledEndTimeInputs = () => {
    if (this.state.endTimeMethod === "1") {
      return (
        <DatePicker showTime />
      );
    } else if (this.state.endTimeMethod === "2") {
      return (
        <div>
          <div><span>Days </span> <InputNumber defaultValue={1} /></div>
          <div><span>Hours </span> <InputNumber defaultValue={0} /></div>
          <div><span>Minutes </span> <InputNumber defaultValue={0} /></div>
        </div>
      );
    }

    return <Input style={{ display: 'none' }} value="" />
  }

  handleChange = (checkedValue) => {
    if (checkedValue.target.checked) {
      this.state.checkedAddress.push('' + checkedValue.target.id);
    } else {
      let newArray = this.state.checkedAddress.filter(function (value) {
        return value !== '' + checkedValue.target.id
      });
      this.state.checkedAddress = newArray;
    }
    return checkedValue.target.checked ? 1 : 0;
  };

  editHostAckForm = (defaultFormLayout) => {

    return (
      <div>
        <section className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }
          <Form.Item label="Host" {...defaultFormLayout} name="host_id"
            initialValue={this.state.parentHostId}
            rules={[{ required: true, message: 'A host is required.' }]}
          >
            <Select>
              {
                this.state.availableHosts.map((host) => {
                  return <Select.Option value={parseInt(host.id)} key={parseInt(host.id)}>{host.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>
          <Form.Item label="Contact Addresses" {...defaultFormLayout} name="is_global"
            initialValue={this.state.is_global}
            getValueFromEvent={this.getCheckedValue}
            valuePropName="checked"
          >

            <Checkbox>Apply to all Contact Addresses
              {
                this.state.availablecontactAddress.map((resource) => {
                  if (this.state.is_global === 0) {
                    if (this.state.contact_address_id.filter(e2 => e2 == resource.id).length > 0) {
                      return (
                        <Form.Item name="contact_address_id"
                          initialValue={this.state.resource.contact_address_id}
                          getValueFromEvent={() => { return this.state.checkedAddress }}
                        >

                          <Checkbox
                            id={resource.id}
                            options={this.state.availablecontactAddress}
                            name={resource.name}
                            defaultChecked={this.state.contact_address_id}
                            onChange={this.handleChange}
                            value={parseInt(resource.id)}
                            key={parseInt(resource.id)}>{resource.name}
                          </Checkbox>
                        </Form.Item>

                      );
                    }
                    else {
                      return (
                        <Form.Item name="contact_address_id"
                          initialValue={this.state.resource.contact_address_id}
                          getValueFromEvent={() => { return this.state.checkedAddress }}
                        >

                          <Checkbox
                            id={resource.id}
                            name={resource.name}
                            options={this.state.availablecontactAddress}
                            onChange={this.handleChange}
                            value={parseInt(resource.id)}
                            key={parseInt(resource.id)}>{resource.name}
                          </Checkbox>
                        </Form.Item>

                      );
                    }
                  }
                  else {
                    return <li></li>
                  }
                })

              }
            </Checkbox>
          </Form.Item>
          <Form.Item
            label={<span><span>Scheduled End Time</span><span className="form-item-label-description">Optionally set an end time to clear the Ack automatically.</span></span>}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 6 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 14 },
            }}
            colon={false}
          >

            <>
              <Form.Item name="end_time" rules={[{ required: true, type: 'object' }]}>
                <DatePicker showTime />
              </Form.Item>
            </>

          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>

        </section>
      </div>
    )
  }
}

HostsAckEdit.contextType = UserStateContext;
export default HostsAckEdit;
