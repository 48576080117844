import React from 'react';
import { Menu, Badge, Dropdown } from 'antd';
import { SettingFilled, CaretDownFilled } from '@ant-design/icons';

function getSubMenu(items) {
    return (
        <Menu>
            {
                items.map((item) => {
                    return (
                        <Menu.Item key={item.key}>
                            <a target="_blank" rel="noopener noreferrer" href={item.path}>{item.title}</a>
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )
}

function AccountMenuItem({ route, subMenu, badgeCount, customerName, children, ...props }) {
    return (
        <Menu.Item key={route} className="account" {...props}>
            <Dropdown overlay={getSubMenu(subMenu)}>
                <a className="ant-dropdown-link" href="#_">
                    <Badge count={badgeCount}>
                        <SettingFilled />
                    </Badge>
                    <span className="menu-item-text">{customerName}</span>
                    <CaretDownFilled />
                </a>
            </Dropdown>
        </Menu.Item>
    );
}

export default AccountMenuItem;