import React from 'react';
import { Link } from 'react-router-dom';
import DynamicTable from './DynamicTable';

class DashboardPanel extends React.Component {
  render() {
    return (
      <div className="page-area dashboard-area">
        <header className="dashboard-area-header">
          <h3 className="dashboard-area-title">{this.props.title}</h3>
          <Link to={this.props.allLink}>Show All</Link>
        </header>
        <DynamicTable
          {...this.props}
          showTitle={false}
        />
      </div>
    );
  }
}

DashboardPanel.defaultProps = {
  loading: true,
  resourceName: '',
}

export default DashboardPanel;