import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider, Collapse, Typography } from 'antd';
import TableOptions from './TableOptions';
import './DynamicTable.scss';
import { v4 as uuidv4 } from 'uuid';
import { formatTimestampSeconds } from '../helpers';

const Panel = Collapse.Panel;
const Title = Typography.Title;

class DynamicTable extends React.Component {

  state = {
    currentPage: this.props.pageNum,
  }

  render() {
    return (
      <div className="DynamicTable">
        {this.props.showPagination &&
          <TableOptions
            model={{
              type: this.props.tableOptionsModel.type,
              title: this.props.tableOptionsModel.title,
              rowsPerPage: this.props.tableOptionsModel.rowsPerPage,
              rowCount: this.props.rowCount,
              currentPage: this.props.currentPage
            }}
            links={this.props.tableOptionsLinks}
            toggler={this.props.tableOptionsToggler}
            navigateToPage={this.navigateToPage}
          />
        }
        {
          this.props.showTitle &&
          <Title level={3}>{this.props.title}</Title>
        }
        <Collapse className="dynamic-table-collapse" accordion>{this.createPanels()}</Collapse>
        <Table
          className="dynamic-table-table"
          dataSource={this.props.data}
          columns={this.props.columns}
          onChange={this.handleTableChange}
          loading={this.props.loading}
          pagination={{ current: this.state.currentPage, pageSize: this.props.rowsPerPage, }}
          rowKey={(record) => record.id + uuidv4()}
        />
        {this.props.showPagination &&
          <TableOptions
            model={{
              type: this.props.tableOptionsModel.type,
              title: this.props.tableOptionsModel.title,
              rowsPerPage: this.props.rowsPerPage,
              rowCount: this.props.rowCount,
              currentPage: this.props.currentPage
            }}
            toggler={this.props.tableOptionsToggler}
            navigateToPage={this.navigateToPage}
            links={this.props.tableOptionsLinks}
          />
        }
      </div>
    );
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (sorter) {
      this.props.handleSortChange(sorter);
    }
  }

  // THIS FUNCTION IS HANDLING MOBILE DISPLAY OF SOME ITMES FOR WHICH THE SCHEMA IS NOT WORKING
  // TODO: this should be set in the table schema instead. Something is off. The real issue is that the ant colapse panel is not leveraging the schema. Need to ask Will how to do this.
  // UPDATE: the issue was some css that was hiding several columns in mobile view in all data table views. This has now been address need to test if we can remove (or reduce) this below function.
  displayDataTypes = (item, index) => {
    switch (index) {
      case 'metric':
        return item['metric'].name;
      case 'metric_name':
        return item['name'];
      case 'contact_addresses':
        return item['contact_addresses'].length !== 0 ? item['contact_addresses'].map(contact => contact.name).join(', ') : 'None';
      case 'contact_nodes':
        return item['contact_nodes'].length !== 0 ? item['contact_nodes'].map(contact => contact.name).join(', ') : 'None';
      case 'mon_fri_definition':
        let outputV = '';
        if (item) {
          if (item.monday) {
            outputV += 'mon ' + item.monday + ', ';
          };
          if (item.tuesday) {
            outputV += 'tue ' + item.tuesday + ', ';
          };
          if (item.wednesday) {
            outputV += 'wed ' + item.wednesday + ', ';
          };
          if (item.thursday) {
            outputV += 'thu ' + item.thursday + ', ';
          };
          if (item.friday) {
            outputV += 'fri ' + item.friday;
          };
        }
        return outputV;
      case 'sat_sun_definition':
        let outputValue = '';
        if (item) {
          if (item.saturday) {
            outputValue += 'sat ' + item.saturday + ', ';
          };
          if (item.sunday) {
            outputValue += 'sun ' + item.sunday;
          };
        }
        return outputValue;
      case 'check':
        return item['check_id'];
      case 'time':
        return formatTimestampSeconds(item['time']);
      case 'event_start':
        return formatTimestampSeconds(item['event_start']);
      case 'event_end':
        return formatTimestampSeconds(item['event_end']);
      default:
        return item[index];
    }
  }

  createPanels = () => {
    return this.props.data.map((item, index) => {
      var itemProperties = [];
      for (var i in this.props.columns) {
        var column = this.props.columns[i];
        if (column.key === "actions" || column.dataIndex === "actions") {
          continue;
        }
        if (column.dataIndex && !column.render) {
          itemProperties.push(
            <span key={column.dataIndex}>
              <span className="property-label">{column['title']}</span>
              <p>
                {this.displayDataTypes(item, column.dataIndex)}
              </p>
            </span>
          );
        } else if (column.render) {
          itemProperties.push(
            <span key={column.key}>
              <span className="property-label">{column['title']}</span>
              <p>{column.render("", item)}</p>
            </span>
          );
        }
      }
      return (
        <Panel header={this.props.panelHeader(item)} key={index} showArrow={false}>
          <div className={"panel-inner status-" + item.status}>
            {itemProperties}
            {
              item.actions &&
              <div className="actions">
                {item.actions.map((action, index) => {
                  return (
                    <span key={index}>
                      <Button type={action.type}
                        onClick={() => action.func(item)}
                        disabled={action.disabled}
                      >
                        {action.name}
                      </Button>
                      {index !== item.actions.length - 1 && <Divider type="vertical" />}
                    </span>
                  );
                })}
              </div>
            }
          </div>
        </Panel>
      );
    });
  }

  navigateToPage = (pageNum) => {
    this.setState({
      currentPage: pageNum
    }, () => {
      this.props.handlePageChange(pageNum);
    });
  }

  // TODO: Create viewProblemStates similar to navigateToPage using onFilterSelectionChange from Filter.js on page 135
}

DynamicTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  panelHeader: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowCount: PropTypes.number,
  currentPage: PropTypes.number,
  showPagination: PropTypes.bool,
  showTitle: PropTypes.bool
};

DynamicTable.defaultProps = {
  title: '',
  data: [],
  columns: [],
  panelHeader: (record) => record.name,
  rowsPerPage: 25,
  rowCount: 0,
  currentPage: 1,
  showPagination: false,
  showTitle: true
};

export default DynamicTable;