import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import IconPositive from '../../theming/icons/IconPositive';
import IconError from '../../theming/icons/IconError';
import IconViewable from '../../theming/icons/IconViewable';
import IconLocked from '../../theming/icons/IconLocked';
import IconHostsGroup from '../../theming/icons/IconHostsGroup';

export const projectColumns = [
  {
    title: "Name",
    key: "name",
    render: (text, record) => <Link to={`/glsmon/projects/${record.id}`} className="underline">{record.name}</Link>
  },
  {
    title: "Use Count",
    key: "use_count",
    dataIndex: "customer_active_use_count"
  },
  {
    title: "Public",
    key: "public",
    render: (text, record) => {
      return record.is_public === 1
        ? <IconPositive />
        : <IconError />
    }
  },
  {
    title: "Viewable",
    key: "viewable",
    render: (text, record) => {
      return record.viewable
        ? <IconViewable />
        : <IconError />
    }
  },
  {
    title: "Editable",
    key: "editable",
    render: (text, record) => {
      return record.editable
        ? <IconPositive />
        : <IconLocked />
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];

export const projectFilterTypes = {

}

export const projectLabelMap = {
  "name": "Project Name"
}

export const projectWidgets = {
  "customer": {
    render: (resource) => {
      if (resource && resource.customer) {
        return (
          <div>
            <p>{resource.customer.name}</p>
            <label>Customer</label>
          </div>
        );
      }
    }
  },
  "is_public": {
    render: (resource) => { }
  }
}

export const projectIcon = (props) => <Icon component={IconHostsGroup}  {...props} />;
