import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import ProjectsViewingChecks from './ProjectsViewingChecks';
import ProjectsViewingFiles from './ProjectsViewingFiles';
import WrappedDataForm from '../components/DataForm';
import BackLink from '../components/BackLink';
import ResourceInfo from '../components/ResourceInfo';
import MultiSelector from '../components/MultiSelector';
import TabbedPage from '../components/TabbedPage';
import { projectWidgets, projectIcon, projectLabelMap } from '../api/schema/project';
import GLSAPI from '../api/api_config';
import { UserStateContext } from '../UserContext';

class ProjectsViewing extends React.Component {
  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : null); // TEMP MOCK ID

    this.state = {
      resource: {},
      resourceId: resourceId,
      availableHosts: [],
      selectedHostsToPull: [],
      selectedHostsToPush: [],
      resourceLoading: true,
      allHostsLoading: true,
    }
  }

  componentDidMount(props) {

    GLSAPI.endpoints['project'].getOne({ id: this.state.resourceId })
      .then((response) => {
        this.setState({
          resource: response,
          selectedHostsToPull: response.c2s,
          selectedHostsToPush: response.s2c,
          resourceLoading: false
        })
      }).catch((error) => {
        this.setState({
          resourceLoading: false
        })
      });

    GLSAPI.endpoints['host'].getAllPages()
      .then((response) => {
        let hosts = response.map((item, i) => { return { ...item, key: item.id } });
        this.setState({
          availableHosts: hosts,
          allHostsLoading: false,
        });
      }).catch((error) => {
        message.destroy()
        message.error('Error fetching hosts');
        this.setState({
          allHostsLoading: false,
        })
      });
  }

  onSubmitCallback = (project) => {

    this.setState({
      resource: project
    });

  }

  onTitleClick = (id) => {
    this.props.history.push(`/glsmon/projects/${id}/edit`);
  }

  render() {
    const match = this.props.match;
    var subPage = 'checks';

    if (match && match.params.subpage) {
      subPage = match.params.subpage;
    }

    return (
      <div className="ProjectsViewing view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">Viewing Project</h1>

          <section className="view-section view-section--no-border">
            <ResourceInfo
              resource={this.state.resource}
              resourceIcon={projectIcon}
              widgets={projectWidgets}
              labelMap={projectLabelMap}
              onTitleClick={this.onTitleClick}
            />
          </section>

          <WrappedDataForm
            apiFunction={GLSAPI.endpoints['project'].edit}
            apiResource="project"
            onSubmitCallback={this.onSubmitCallback}
            verbs={["Updating", "Updated"]}
          >
            {this.editProjectForm}
          </WrappedDataForm>

          <TabbedPage
            isContainer={false}
            activeTab={subPage}
            tabs={[
              {
                title: 'Checks',
                key: 'checks',
                content: <ProjectsViewingChecks projectId={this.state.resourceId} />
              },
              {
                title: 'Files',
                key: 'files',
                content: <ProjectsViewingFiles projectId={this.state.resourceId} />
              },
            ]} />

        </div>
      </div>
    );
  }

  editProjectForm = (defaultFormLayout) => (
    <div>
      <section className="view-section filter-section">
        {this.state.resourceId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
            <Input type="hidden" />
          </Form.Item>
        }
        {this.context.currentCustomer.id &&
          <Form.Item style={{ display: 'none' }} name="customer_id" initialValue={this.context.currentCustomer.id}>
            <Input type="hidden" />
          </Form.Item>
        }

        <header className="view-section-header">
          <h3 className="view-section-title">Hosts allowed to pull from server</h3>
          <p className="view-section-description">Selected Hosts will download these projects from the server, and automatically stay up to date as the projects update.</p>
        </header>
        <Form.Item name="c2s">
          <MultiSelector
            dataIndex="name"
            dataSource={this.state.availableHosts}
            targetKeys={this.state.selectedHostsToPull}
            loading={this.state.allHostsLoading}
          />
        </Form.Item>
      </section>

      <section className="view-section filter-section">
        <header className="view-section-header">
          <h3 className="view-section-title">Hosts allowed to push to server</h3>
          <p className="view-section-description">Selected Hosts will automatically push local Project File updates to the server, and by extension to all of the above hosts</p>
        </header>
        <Form.Item name="s2c">
          <MultiSelector
            dataIndex="name"
            dataSource={this.state.availableHosts}
            targetKeys={this.state.selectedHostsToPush}
            loading={this.state.allHostsLoading}
          />
        </Form.Item>
      </section>
      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  )
}

ProjectsViewing.contextType = UserStateContext;

export default withRouter(ProjectsViewing);
