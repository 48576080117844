import React from 'react';
import { Button, Divider } from 'antd';

export const customerColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "customer",
    field: "customer",
  },
  {
    title: "License count",
    dataIndex: "license_count",
    key: "license_count"
  },
  {
    title: "Licenses used",
    dataIndex: "licenses_used",
    key: "licenses_used"
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];


export const sortableCustomerColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    field: "customer",
    sorter: true
  },
  {
    title: "License count",
    dataIndex: "license_count",
    key: "license_count",
    sorter: true,
  },
  {
    title: "Licenses used",
    dataIndex: "licenses_used",
    key: "licenses_used"
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Actions",
    key: "actions",
    render: actions => (
      <span>
        {actions.length && actions.map((action, index) => {
          return (
            <span>
              <Button type={action.type} disabled={action.disabled}>
                {action.title}
              </Button>
              {index !== actions.length - 1 ? (
                <Divider type="vertical" />
              ) : (
                ""
              )}
            </span>
          );
        })}
      </span>
    )
  }
];