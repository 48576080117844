import React from 'react';
import DataWrapper from '../components/DataWrapper';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import axios from 'axios';
import IconAdd from '../theming/icons/IconAdd';
import { queryColumns, queryFilterTypes } from '../api/schema/query';

class HostsEditQueries extends React.Component {

  state = {
    queries: []
  }

  constructor(props) {
    super(props);
    console.log(props);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Add Query', href: `/glsmon/hosts/${props.hostId}/queries/new` }
    ];

    this.tableOptionsModel = {
      type: 'queries',
      title: 'Queries',
      rowsPerPage: 5,
      currentPage: 1
    };

    this.panelHeader = item => <span className="bold">{item.name}</span>;

  }

  render() {

    return (
      <div className="HostsEditQueries nested-tab-view">
        <DataWrapper
          api="query"
          requiredParams={{ host: this.props.hostId }}
          columns={queryColumns}
          filterTypes={queryFilterTypes}
          tableOptionsLinks={this.tableOptionsLinks}
          tableOptionsModel={this.tableOptionsModel}
          panelHeader={this.panelHeader}
          actions={[
            {
              name: "Edit",
              type: "primary",
              func: this.viewQuery,
              enabled: (record) => {
                // create a rule to determine if the action is available
                return true
              }
            },
            {
              name: "Delete",
              type: "primary",
              func: this.deleteQuery,
              enabled: (record) => {
                return true
              }
            }
          ]}
        >
          {
            (props, state, actions) => (
              <div>
                <div className="view-section view-section--no-border filters-section">
                  <header className="view-section-header">
                    <h3 className="view-section-title">Available Filters</h3>
                    <Filter expanded expandable
                      {...state}
                      {...props}
                      {...actions}
                      // enabledFilters={[{
                      //   key: "host",
                      //   value: parseInt(this.props.hostId)
                      // }]}
                    />
                  </header>
                </div>
                <section className="view-section hosts-section filters-section--expanded">
                  <DynamicTable
                    {...state}
                    {...props}
                    {...actions}

                  />
                </section>
              </div>
            )
          }
        </DataWrapper>
      </div>
    );
  }

  viewQuery = (query) => {
    this.props.history.push(`/glsmon/hosts/${this.props.hostId}/queries/${query.id}`);
  }
  deleteQuery = (query) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(query);
    var r = window.confirm(`Are you sure you want to delete this query id : ${query.id} `);
    if (r == true) {
      var obj = { type: query.type, query_id: query.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}

export default HostsEditQueries;
