import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { Link } from 'react-router-dom';

import Toggler from './Toggler';
import IconArrowLeft from '../theming/icons/IconArrowLeft';
import IconArrowRight from '../theming/icons/IconArrowRight';

import './TableOptions.scss';

function TableOptions(props) {
  const { toggler, links, model: { title, rowsPerPage, rowCount, currentPage }, navigateToPage } = props;

  const getPaginationLabel = () => {
    const top = (currentPage * rowsPerPage > rowCount) ? rowCount : currentPage * rowsPerPage;
    const bottom = (currentPage * rowsPerPage > rowCount) ? (rowsPerPage * (currentPage - 1)) + 1 : top - (rowsPerPage - 1);

    return `${bottom}-${top} of ${rowCount}`;
  }

  const pageCount = Math.ceil(rowCount / rowsPerPage);

  const buildPageSelector = () => {
    const options = [];
    for (let i = 0; i < pageCount; i++) {
      options.push(<Select.Option value={i + 1} key={i + 1}>Page {i + 1}</Select.Option>)
    }

    return <Select defaultValue={currentPage} value={currentPage} onChange={(pageNum) => navigateToPage(pageNum)}>{options}</Select>;
  }

  const getLinksOption = () => {
    return links
      ? links.map((link, i) => {
        if (link.href) {
          return (
            <Link className={`positive bold ${link.icon ? 'inline-icon' : null}`} to={link.href} key={link.href}>
              {link.icon}{link.linkText}
            </Link>
          );
        } else {
          return <span key={i}>{link.linkText}</span>;
        }
      })
      : null;
  }

  return (
    <div className="TableOptions view-section-options table-options">

      <div className="view-section-options--left table-options--left">
        <span className="view-section-actions table-actions">
          {toggler
            ? <Toggler offLabel={toggler.offLabel} onLabel={toggler.onLabel} checked={toggler.defaultChecked} />
            : getLinksOption()
          }
        </span>
      </div>

      <div className="view-section-options--right table-options--right">
        <div className="view-section-info table-info">
          <span className="view-section-info-name table-info-name">{title}</span>
          <span className="view-section-info-count table-info-count">{getPaginationLabel()}</span>
          <span className="view-section-info-selector table-selector">{buildPageSelector()}</span>
          <span className="view-section-info-arrows table-info-arrows">
            <a href="#_" className={"table-info-arrow-link " + (currentPage === 1 ? "inactive" : "")} onClick={(e) => {
              e.preventDefault();
              if (currentPage - 1 > 0) {
                navigateToPage(currentPage - 1);
              }
            }}
            >
              <IconArrowLeft inactive={currentPage === 1} />
            </a>
            <a href="#_" className={"table-info-arrow-link " + (currentPage === pageCount ? "inactive" : "")} onClick={(e) => {
              e.preventDefault();
              if (currentPage + 1 <= pageCount) {
                navigateToPage(currentPage + 1);
              }
            }}
            >
              <IconArrowRight inactive={currentPage === pageCount} />
            </a>
          </span>
        </div>
      </div>
    </div>

  );
}

TableOptions.propTypes = {
  toggler: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.object),
  model: PropTypes.shape({
    title: PropTypes.string,
    rowsPerPage: PropTypes.number,
    rowCount: PropTypes.number,
    currentPage: PropTypes.number
  }),
  navigateToPage: PropTypes.func
}

TableOptions.defaultProps = {
  toggler: false,
  links: [],
  model: {
    type: 'Resource',
    title: 'Resource',
    rowsPerPage: 5,
    rowCount: 20,
    currentPage: 1
  },
  navigateToPage: () => { },
}

export default TableOptions;