import React from 'react';
import { withRouter } from 'react-router-dom';
import HostgroupsViewingProjects from './HostgroupsViewingProjects';
import BackLink from '../components/BackLink';
import ResourceInfo from '../components/ResourceInfo';
import { hostgroupWidgets, hostgroupLabelMap } from '../api/schema/hostgroup';
import { hostgroupIcon } from '../theming/_props-mock/ResourceInfoProps';
import GLSAPI from '../api/api_config';

class HostgroupsViewing extends React.Component {

  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : null);

    this.state = {
      resource: {},
      resourceId: resourceId,
      loading: true
    }
  }

  componentDidMount(props) {
    this.setState({
      loading: true
    })

    GLSAPI.endpoints['hostgroup'].getOne({ id: this.state.resourceId })
      .then((response) => {
        this.setState({
          resource: response,
          loading: false
        });
      }).catch((error) => {
        this.setState({
          loading: false
        })
      });
  }

  onTitleClick = (id) => {
    this.props.history.push(`/glsmon/hostgroups/${id}/edit`);
  }

  render() {
    return (
      <div className="HostgroupsViewing view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">Viewing Hostgroup</h1>

          <section className="view-section view-section--no-border">
            <ResourceInfo
              resource={this.state.resource}
              resourceIcon={hostgroupIcon}
              widgets={hostgroupWidgets}
              labelMap={hostgroupLabelMap}
              onTitleClick={this.onTitleClick}
            />
          </section>
          <HostgroupsViewingProjects />
        </div>
      </div>
    );
  }
}

export default withRouter(HostgroupsViewing);
