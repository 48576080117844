import React from 'react'

const IconLocked = props => {
  const { width, height } = props;

  return (
    <svg className="IconLocked gls-icon" width={width || "14px"} height={height || "18px"} viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-1107.000000, -5286.000000)">
          <g id="Group-25" transform="translate(928.000000, 5273.000000)">
            <g id="locked" transform="translate(162.000000, 0.000000)">
              <rect id="Rectangle-Copy" x="0" y="0" width="48" height="48"></rect>
              <g id="flaticon1554854743-svg" transform="translate(17.000000, 13.000000)" fill="#495466" fillRule="nonzero">
                <path d="M13.611099,6.75 L12.4444323,6.75 L12.4444323,5.24998828 C12.4444323,2.35511719 10.002125,0 7,0 C3.997875,0 1.55556771,2.35511719 1.55556771,5.24998828 L1.55556771,6.75 L0.388901042,6.75 C0.173942708,6.75 0,6.91773047 0,7.12501172 L0,16.5000234 C0,17.3272852 0.697630208,18 1.55556771,18 L12.4444687,18 C13.3023698,18 14,17.3272852 14,16.4999883 L14,7.12501172 C14,6.91773047 13.8260573,6.75 13.611099,6.75 Z M8.16440625,14.5836211 C8.17654688,14.6894414 8.14125521,14.7956484 8.06757292,14.8751367 C7.99389063,14.9545898 7.88830729,15.0000117 7.77780208,15.0000117 L6.22223438,15.0000117 C6.11172917,15.0000117 6.00614583,14.9545898 5.93246354,14.8751367 C5.85878125,14.7956836 5.82345313,14.6894766 5.83563021,14.5836211 L6.08095833,12.4563164 C5.68257813,12.1768945 5.44446875,11.734875 5.44446875,11.25 C5.44446875,10.4227383 6.14209896,9.74998828 7.00003646,9.74998828 C7.85797396,9.74998828 8.55560417,10.4227031 8.55560417,11.25 C8.55560417,11.734875 8.31749479,12.1768945 7.91911458,12.4563164 L8.16440625,14.5836211 Z M10.111099,6.75 L3.88890104,6.75 L3.88890104,5.24998828 C3.88890104,3.59581641 5.2845625,2.25 7,2.25 C8.7154375,2.25 10.111099,3.59581641 10.111099,5.24998828 L10.111099,6.75 L10.111099,6.75 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconLocked;
