import React from 'react';
import { Button, Form, Input, Select, message } from 'antd';
import LoadingScreen from './LoadingScreen';
import GLSAPI from '../api/api_config';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';

class ProjectsFilesAdd extends React.Component {
  constructor(props) {
    super(props);

    let parentProjectId = (props.match ? parseInt(props.match.params.id) : null);
    let resourceId = (props.match ? props.match.params.resource_id : null);

    this.state = {
      loading: true,
      resource: {},
      resourceId,
      parentProjectId,
      availableProjects: []
    }

    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['file'].create
      : GLSAPI.endpoints['file'].edit;
  }

  componentDidMount() {
    if (this.state.resourceId) {
      GLSAPI.endpoints['file'].getOne({ id: this.state.resourceId }).then((response) => {
        this.setState({
          resource: response,
          loading: false
        });
      }).catch((error) => {
        this.setState({
          loading: false
        });
      });
    }

    GLSAPI.endpoints['project'].getAllPages()
      .then((response) => {
        this.setState({
          availableProjects: response.map((item, i) => { return { ...item, key: i } }),
          loading: false
        });
      }).catch((error) => {
        this.setState({
          loading: false
        })
      });
  }

  render() {
    return (
      <div className="ProjectsFilesAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} File</h1>
          {(this.state.loading ? (
            <LoadingScreen />
          ) : (
            <WrappedDataForm
              title=""
              apiFunction={this.apiFunction}
              apiResource='file'
              onSubmitCallback={this.onSubmitCallback}
              onErrorCallback={this.onErrorCallback}
              hideRequiredMark
              verbs={this.getVerbs()}
              initialValues={{
                id: this.state.resourceId,
                project_id: parseInt(this.state.parentProjectId),
                relpath: this.state.resource.relpath,
                db_content: JSON.stringify(this.state.resource.db_content)
              }}
            >
              {this.addFileForm}
            </WrappedDataForm>
          ))}
        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  onSubmitCallback = file => {
    this.props.history.push(`/glsmon/projects/${this.state.parentProjectId}/files`);
  }

  onErrorCallback = (error) => {
    if (error.message === "Name already exists for the project") {
      message.destroy();
      message.error('ProjectFile Name already exists for the project. Please try a different name.');
    }
  }

  disabledInput = () => {
    return this.props.actionType === 'create' ? false : true;
  }

  addFileForm = (defaultFormLayout) => (
    <div>
      <div className="view-section view-section--no-border">
        {this.state.resourceId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id">
            <Input type="hidden" />
          </Form.Item>
        }

        <Form.Item label="Project" {...defaultFormLayout}
          name="project_id"
          rules={[{ required: true, message: 'A project is required' }]}
        >
          <Select>
            {
              this.state.availableProjects.map((project) => <Select.Option value={project.id} key={project.id}>{project.name}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          label={<span><span>File Name</span><span className="form-item-label-description">Moving existing files (here or directly via git) is not well supported yet, so this is disabled when editing an existing file<br />Only specify the monitoring file names (ex: check_disk)</span>Only specify the monitoring file names (ex: check_disk)</span>}
          {...defaultFormLayout}
          name="relpath"

          rules={[{ required: true, message: 'File Name is required' }]}
        >
          <Input
            disabled={this.disabledInput()}
          />
        </Form.Item>

        <Form.Item label="File Content" {...defaultFormLayout}
          name="db_content"
          rules={[{ required: true, message: 'File content is required' }]}
        >
          <Input.TextArea rows={8} />
        </Form.Item>
      </div>

      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  )
}

export default ProjectsFilesAdd;
