import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../components/Header';
import LoginForm from '../components/LoginForm';
import Page from '../components/Page';
import CustomerBar from '../components/CustomerBar';
import Dashboard from './Dashboard';
import MetricsTabs from './MetricsTabs';
import Help from './Help';
import HostsTabs from './HostsTabs';
import AccountsTabs from './AccountsTabs';
import DevelopmentTabs from './DevelopmentTabs';
import Notifications from './Notifications';

const NotFound = () => (
  <Page view={<h1>Page not found...</h1>} />
);

const Main = (props) => {
  return (
    <>
      <Route exact path="/" render={props => <Page view={<LoginForm />} {...props} />} />

      <Header {...props} />
      <CustomerBar />
      <Switch>

        <Route path="/glsmon/dashboard" render={props => <Page view={<Dashboard />} {...props} />} />

        <Route path="/glsmon/notifications" render={props => <Page view={<Notifications />} {...props} />} />

        <Route path="/glsmon/metrics" render={props => <MetricsTabs activeTab="glsmon/metrics" {...props} />} />

        <Route path="/glsmon/metricack" render={props => <MetricsTabs activeTab="glsmon/metricack" {...props} />} />

        <Route path="/glsmon/events" render={props => <MetricsTabs activeTab="glsmon/events" {...props} />} />

        <Route path="/glsmon/development" render={props => <DevelopmentTabs {...props} />} />

        <Route path="/glsmon/projects" render={props => <DevelopmentTabs activeTab="glsmon/projects" {...props} />} />

        <Route path="/glsmon/hosts" render={props => <HostsTabs activeTab="glsmon/hosts" {...props} />} />

        <Route path="/glsmon/hostack" render={props => <HostsTabs activeTab="glsmon/hostack" {...props} />} />

        <Route path="/glsmon/hostgroups" render={props => <HostsTabs activeTab="glsmon/hostgroups" {...props} />} />

        <Route path="/glsmon/registerpin" render={props => <HostsTabs activeTab="glsmon/registerpin" {...props} />} />

        <Route path="/glsmon/accounts" render={props => <AccountsTabs activeTab="glsmon/customers" {...props} />} />

        <Route path="/glsmon/customers" render={props => <AccountsTabs activeTab="glsmon/customers" {...props} />} />

        <Route path="/glsmon/users" render={props => <AccountsTabs activeTab="glsmon/users" {...props} />} />

        <Route path="/glsmon/help" render={props => <Page view={<Help />} {...props} />} />

        <Route path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default Main;
