import React from 'react';
import { Button, Input, InputNumber, Checkbox, Select, DatePicker, Form, Row, Col } from 'antd';
import moment from 'moment';
import GLSAPI from '../api/api_config';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';
import { formatTimeValueEpoch } from '../helpers';
import { UserStateContext } from '../UserContext';


class MetricsAckAdd extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : 0);
    let parentMetricId = parseInt((props.match ? props.match.params.metricId : 0));

    this.state = {
      resource: {},
      resourceId,
      fetched: this.props.actionType === 'create',
      parentMetricId,
      availableMetrics: [],
      availablecontactaddress: [],
      availableevent: [],
      is_global: 1,
      endTimeMethod: "1",
      metricStatesArray: [],
      hostUPChecked: false,
      hostDOWNChecked: false,
      serviceOKChecked: false,
      serviceWarningChecked: false,
      serviceCriticalChecked: false,
      serviceUnknownChecked: false,
      selectAllChecked: false,
      checkedAddress: [],
    }
    this.handleChange = this.handleChange.bind(this);
  }

  fetchResourceData() {
    if (this.context && this.context.currentCustomer !== {}) {
      GLSAPI.endpoints['metric'].getAllPages()
        .then((response) => {
          let availableMetrics = response.map((item, i) => { return { ...item, key: i } });
          this.setState({
            availableMetrics
          });
        }).catch((error) => {
        });
      GLSAPI.endpoints['contactaddress'].getAllPages()
        .then((response) => {
          let availablecontactaddress = response.map((item, i) => { return { ...item, key: i } });
          this.setState({
            availablecontactaddress
          });
        }).catch((error) => {
        });
      GLSAPI.endpoints['metricevent'].getAllPages()
        .then((response) => {
          let availableevent = response.map((item, i) => { return { ...item, key: i } });
          this.setState({
            availableevent
          });
        }).catch((error) => {
        });

      if (this.state.resourceId) {
        GLSAPI.endpoints['metricack'].getOne({ id: this.state.resourceId })
          .then((response) => {
            let hostUPChecked = (response.metric_state_type_ids.includes('1') ? 1 : 0);
            let hostDOWNChecked = (response.metric_state_type_ids.includes('2') ? 1 : 0);
            let serviceOKChecked = (response.metric_state_type_ids.includes('3') ? 1 : 0);
            let serviceWarningChecked = (response.metric_state_type_ids.includes('4') ? 1 : 0);
            let serviceCriticalChecked = (response.metric_state_type_ids.includes('5') ? 1 : 0);
            let serviceUnknownChecked = (response.metric_state_type_ids.includes('6') ? 1 : 0);
            let staleChecked = (response.metric_state_type_ids.includes('7') ? 1 : 0);
            let metricStatesArray = (response.metric_state_type_ids ? response.metric_state_type_ids : []);
            let checkedAddress = (response.contact_address_id ? response.contact_address_id : []);
            this.setState({
              loading: false,
              fetched: true,
              resource: response,
              metric_id: response.metric_id,
              contact_address_id: response.contact_address_id,
              metric_event_id: response.metric_event_id,
              metricStatesArray,
              hostUPChecked,
              hostDOWNChecked,
              serviceOKChecked,
              serviceWarningChecked,
              serviceCriticalChecked,
              serviceUnknownChecked,
              staleChecked,
              checkedAddress
            }, () => {
              this.formRef.current.setFieldsValue({
                end_after_unacked_state: this.state.resource.end_after_unacked_state,
                unacked_state_start_time: moment.unix(this.state.resource.unacked_state_start_time),
                end_time: moment.unix(this.state.resource.end_time),
                silence_all_states: this.state.resource.silence_all_states,
                is_global: this.state.resource.is_global,
                metric_id: this.state.metric_id,
                contact_address_id: this.state.contact_address_id,
                metric_event_id: this.state.metric_event_id,
                hostUPChecked,
                hostDOWNChecked,
                serviceOKChecked,
                serviceWarningChecked,
                serviceCriticalChecked,
                serviceUnknownChecked,
                staleChecked
              })
            });
          }).catch((error) => {
            this.setState({
              loading: false,
              fetched: false,
            });
          });
      }
    }
  }

  componentDidMount() {
    this.fetchResourceData();
  }

  componentDidUpdate() {
  }

  render() {

    const { actionType } = this.props;
    this.apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['metricack'].create
      : GLSAPI.endpoints['metricack'].edit;
    return (
      <div className="MetricsAckAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />
          <h1 className="header-bar no-margin">Add Metric Acknowledgement</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="metricack"
            apiResourceReadable="Metric Acknowledgement"
            onSubmitCallback={this.onSubmitCallback}
            hideRequiredMark
            specialFormatting={{
              unacked_state_start_time: formatTimeValueEpoch,
              end_time: formatTimeValueEpoch
            }}
            formRef={this.formRef}
          >
            {this.addMetricAckForm}
          </WrappedDataForm>
        </div>
      </div>
    );
  }

  onSubmitCallback = (metricack) => {
    this.setState({
      resourceId: metricack.id
    });
    this.props.history.push(`/glsmon/metricack`);
    localStorage.removeItem('Name');
  }

  getCheckedValue = (e) => {
    if (e.target.checked === false) {
      this.setState({ is_global: 0 });
      return e.target.checked ? 1 : 0;
    }
    else {
      this.setState({ is_global: 1 });
      return e.target.checked ? 1 : 0;
    }
  };

  handleEndTimeSelection = (e) => {
    this.setState({
      endTimeMethod: e.target.value
    })
  }

  scheduledEndTimeInputs = () => {
    if (this.state.endTimeMethod === "1") {
      return (
        <DatePicker showTime />
      );
    } else if (this.state.endTimeMethod === "2") {
      return (
        <div>
          <div><span>Days </span> <InputNumber defaultValue={1} /></div>
          <div><span>Hours </span> <InputNumber defaultValue={0} /></div>
          <div><span>Minutes </span> <InputNumber defaultValue={0} /></div>
        </div>
      );
    }

    return <Input style={{ display: 'none' }} value="" />
  }

  handleChange = (checkedValue) => {
    if (checkedValue.target.checked) {
      this.state.checkedAddress.push('' + checkedValue.target.id);
    } else {
      let newArray = this.state.checkedAddress.filter(function (value) {
        return value !== '' + checkedValue.target.id
      });
      this.state.checkedAddress = newArray;
    }
    return checkedValue.target.checked ? 1 : 0;
  };

  onChangeAddMetricStates = (checkedValue) => {
    switch (checkedValue.target.name) {
      case "hostUP":
        this.setState({
          hostUPChecked: checkedValue.target.checked
        });
        break;
      case "hostDOWN":
        this.setState({
          hostDOWNChecked: checkedValue.target.checked
        });
        break;
      case "serviceOK":
        this.setState({
          serviceOKChecked: checkedValue.target.checked
        });
        break;
      case "serviceWarning":
        this.setState({
          serviceWarningChecked: checkedValue.target.checked
        });
        break;
      case "serviceCritical":
        this.setState({
          serviceCriticalChecked: checkedValue.target.checked
        });
        break;
      case "serviceUnknown":
        this.setState({
          serviceUnknownChecked: checkedValue.target.checked
        });
        break;
      case "stale":
        this.setState({
          staleChecked: checkedValue.target.checked
        });
        break;
      case "selectAll":
        this.setState({
          selectAllChecked: checkedValue.target.checked
        });
        break;
    }

    if (checkedValue.target.checked && checkedValue.target.value === '8') {
      this.state.metricStatesArray = ['1', '2', '3', '4', '5', '6', '7'];
      this.setState({
        hostUPChecked: true,
        hostDOWNChecked: true,
        serviceOKChecked: true,
        serviceWarningChecked: true,
        serviceCriticalChecked: true,
        serviceUnknownChecked: true,
        staleChecked: true
      });

    } else if (!checkedValue.target.checked && checkedValue.target.value === '8') {
      this.state.metricStatesArray = [];
      this.setState({
        hostUPChecked: false,
        hostDOWNChecked: false,
        serviceOKChecked: false,
        serviceWarningChecked: false,
        serviceCriticalChecked: false,
        serviceUnknownChecked: false,
        staleChecked: false
      });
    }
    else if (checkedValue.target.checked) {
      this.state.metricStatesArray.push(checkedValue.target.value);
    } else {
      let newArray = this.state.metricStatesArray.filter(function (value) {
        return value !== checkedValue.target.value
      });
      this.state.metricStatesArray = newArray;
    }
    return checkedValue.target.checked ? 1 : 0;
  }

  addMetricAckForm = (defaultFormLayout) => {
    return (
      <div>
        <section className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }

          <Form.Item label="Metric" {...defaultFormLayout} name="metric_id"
            initialValue={this.state.parentMetricId}
            rules={[{ required: true, message: 'A metric is required' }]}
          >
            <Select>
              {
                this.state.availableMetrics.map((metric) => {
                  return <Select.Option value={parseInt(metric.id)} key={parseInt(metric.id)}>{metric.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="metric_event_id"
            initialValue={localStorage.getItem('Name')}
          >
            <div>
              <Input type="hidden" />
            </div>
          </Form.Item>

          <Form.Item label="Contact Addresses" {...defaultFormLayout} name="is_global"
            initialValue={this.state.is_global}
            getValueFromEvent={this.getCheckedValue}
            valuePropName="checked"
          >

            <Checkbox>Apply to all Contact Addresses
              {
                this.state.availablecontactaddress.map((resource) => {
                  if (this.state.is_global === 0) {
                    return (
                      <Form.Item name="contact_address_id"
                        initialValue={this.state.resource.contact_address_id}
                        getValueFromEvent={() => { return this.state.checkedAddress }}
                      >
                        <Checkbox id={resource.id}
                          name={resource.name}
                          onChange={this.handleChange}
                          value={parseInt(resource.id)}
                          key={parseInt(resource.id)}>{resource.name}
                        </Checkbox>
                      </Form.Item>
                    );
                  }
                  else {
                    return <li></li>
                  }
                })
              }
            </Checkbox>
          </Form.Item>

          <Form.Item
            label={<span><span>Metric States</span><span className="form-item-label-description">For the duration of the Ack, treat selected states as synonymous with "Ok" and not problem states. This prevents ongoing notifications of selected problem states, as well as notifications of changes between an "Ok" state and any selected state</span></span>}
            {...defaultFormLayout}
            name="metric_state_type_ids"
            getValueFromEvent={() => { return this.state.metricStatesArray }}
            valuePropName="checked"
          >

            <Row>
              <Col span={8}>
                <Checkbox name="hostUP"
                  checked={this.state.hostUPChecked}
                  onChange={this.onChangeAddMetricStates} value="1">host UP</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="hostDOWN"
                  checked={this.state.hostDOWNChecked}
                  onChange={this.onChangeAddMetricStates} value="2">host DOWN</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="serviceOK"
                  checked={this.state.serviceOKChecked}
                  onChange={this.onChangeAddMetricStates} value="3">service OK</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="serviceWarning"
                  checked={this.state.serviceWarningChecked}
                  onChange={this.onChangeAddMetricStates} value="4">service warning</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="serviceCritical"
                  checked={this.state.serviceCriticalChecked}
                  onChange={this.onChangeAddMetricStates} value="5">service critical</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="serviceUnknown"
                  checked={this.state.serviceUnknownChecked}
                  onChange={this.onChangeAddMetricStates} value="6">service unknown</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="stale"
                  checked={this.state.staleChecked}
                  onChange={this.onChangeAddMetricStates} value="7">stale</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox name="selectAll"
                  checked={this.state.selectAllChecked}
                  onChange={this.onChangeAddMetricStates} value="8">Select All</Checkbox>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={<span><span>End ack after n minutes</span><span className="form-item-label-description">You can optionally use this field to create a temporary Ack that clears itself after the problem state is resolved. If the Acked Metric continuously stays in a state not listed above for more than the number of minutes entered here, the Ack will be automatically deleted.</span></span>}
            {...defaultFormLayout}
            name="end_after_unacked_state"
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label={<span><span>Scheduled End Time</span><span className="form-item-label-description">Optionally set an end time to clear the Ack automatically.</span></span>}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 6 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 14 },
            }}
            colon={false}
          >

            <>
              <Form.Item name="end_time" rules={[{ required: true, type: 'object' }]}>
                <DatePicker showTime />
              </Form.Item>
            </>

          </Form.Item>
          <Form.Item
            label={<span><span>Unacked State Start Time</span><span className="form-item-label-description"></span></span>}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 6 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 14 },
            }}
            colon={false}
          >
            <Form.Item name="unacked_state_start_time" rules={[{ required: true, type: 'object' }]}>
              <DatePicker showTime />
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>

        </section>
      </div>
    )
  }
}

MetricsAckAdd.contextType = UserStateContext;
export default MetricsAckAdd;

