import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import BackLink from '../components/BackLink';
import ResourceInfo from '../components/ResourceInfo';
import DataGraph from '../components/DataGraph';
import { metricsViewingIcon, metricsViewingWidgets, metricsViewingLabels } from '../theming/_props-mock/ResourceInfoProps';
import GLSAPI from '../api/api_config';
import './MetricsViewing.scss';
import DynamicTable from '../components/DynamicTable';
import IconPositive from '../theming/icons/IconPositive';
import IconError from '../theming/icons/IconError';
import IconWarning from '../theming/icons/IconWarning';
import IconUnknown from '../theming/icons/IconUnknown';

class MetricsViewing extends React.Component {

  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : 57); // TEMP MOCK ID

    this.state = {
      resource: {},
      resourceId: resourceId,
      loading: true,
      error: {}
    }
  }

  componentDidMount(props) {

    GLSAPI.endpoints['metric'].getOne({ id: this.state.resourceId })
      .then((response) => {
        console.log(response);
        this.setState({
          loading: false,
          resource: response,
        })
      }).catch((error) => {
        if (error.message === "404") {
          this.props.history.push('/404');
        }
        this.setState({
          loading: false,
          error: error
        })
      });
  }

  onTitleClick = (id) => {
    this.props.history.push(`/glsmon/metrics/${id}`);
  }

  render() {
    const panelHeaderState = item => {
      let icon;
      switch (item.status) {
        case "up": icon = <IconPositive />; break;
        case "down": icon = <IconError />; break;
        case "warning": icon = <IconWarning />; break;
        default: icon = <IconUnknown />;
      }

      return <span className="inline-icon simple-table-panel">{icon} {item.state_change}</span>;
    }

    const panelHeaderNotif = item => {
      return (
        <div className="flex-main simple-table-panel">
          <span>{item.notification}</span>
          <span>{item.email}</span>
        </div>
      );
    }

    const Host = [
      {
        key: "resource",
        render: (text, record) => <span className="bold">{record.resource}</span>
      },
      {
        key: "resource_value",
        render: (text, record) => <Link to={`/glsmon/hosts/${this.state.resource.host_id}`} className="underline">{record.resource_value}</Link>
      }
    ];

    const HostData = [
      {
        "resource": "Host",
        "resource_value": this.state.resource.host_name
      }
    ];
    const Project = [
      {
        key: "resource",
        render: (text, record) => <span className="bold">{record.resource}</span>
      },
      {
        key: "resource_value1",
        render: (text, record) => <Link to={`/glsmon/projects/${this.state.resource.project_id}`} className="underline">{record.resource_value1}</Link>
      }
    ];

    const ProjectData = [
      {
        "resource": "Project",
        "resource_value1": this.state.resource.project_name
      }
    ];
    const Query = [
      {
        key: "resource",
        render: (text, record) => <span className="bold">{record.resource}</span>
      },
      {
        key: "resource_value2",
        render: (text, record) => <Link to={`/glsmon/hosts/${this.state.resource.host_id}/queries/${this.state.resource.query_id}`} className="underline">{record.resource_value2}</Link>
      }
    ];

    const QueryData = [
      {
        "resource": "Query",
        "resource_value2": this.state.resource.query_name
      }
    ];

    return (
      <div className="MetricsViewing view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar">Viewing Metric</h1>

          <section className="view-section view-section--no-border">
            <ResourceInfo
              resource={this.state.resource}
              resourceIcon={metricsViewingIcon}
              widgets={metricsViewingWidgets}
              labelMap={metricsViewingLabels}
              onTitleClick={this.onTitleClick}
            />
          </section>
          <div className="EventsCheck view">
            <div className="page-area--tabbed">
              <section className="view-section output-section">
                <p className="bold">Output:</p>
                <pre className="info-color" style={{ "border": "1px outset black", "padding": "20px" }}>{this.state.resource.check_text}</pre>
                <br />
                <Row>
                  <Col md={24} lg={12}>
                    <div className="customer-links">
                      <Link to={`/glsmon/metrics/${this.state.resource.host_id}/ack/new`} className="underline">Create Metric Ack</Link>
                    </div>
                  </Col>
                </Row>
              </section>

              <section className="view-section graph-section" style={{ minHeight: '300px' }}>
                <p>DataGraph goes here</p>
                {!this.state.loading &&
                  <DataGraph
                    resourceType="metric"
                    graphId="field-history"
                    resource={this.state.resource}
                  />
                }
              </section>

              <section className="view-section">
                <Row gutter={16}>
                  <Col sm={24} lg={8} className="simple-table simple-table--no-borders">
                    <DynamicTable
                      columns={Host}
                      data={HostData}
                    />
                    <DynamicTable
                      columns={Project}
                      data={ProjectData}
                    />
                    <DynamicTable
                      columns={Query}
                      data={QueryData}
                    />
                  </Col>
                </Row>
              </section>

            </div>
          </div>
        </div >
      </div >
    );
  }
}

export default MetricsViewing;
