import React from 'react'

const IconEdit = props => {
  const { width, height } = props;

  return (
    <svg className="IconEdit gls-icon" width={width || "20px"} height={height || "20px"} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-942.000000, -5287.000000)">
          <g id="Group-25" transform="translate(928.000000, 5273.000000)">
            <g id="edit">
              <rect id="Rectangle-Copy" x="0" y="0" width="48" height="48"></rect>
              <path d="M26.436494,17.3377423 L30.5049464,21.4260813 L20.2065699,31.7748923 L16.1404241,27.6865533 L26.436494,17.3377423 Z M33.5921149,16.3517287 L31.7777416,14.5284699 C31.076549,13.8238434 29.9379571,13.8238434 29.2343822,14.5284699 L27.4963941,16.2749695 L31.5648465,20.3633465 L33.5921149,18.3261499 C34.1359617,17.7796017 34.1359617,16.898239 33.5921149,16.3517287 Z M14.0113215,33.4330428 C13.9372809,33.7678953 14.2381324,34.0679401 14.5713908,33.986507 L19.1049983,32.8818966 L15.0388525,28.7935577 L14.0113215,33.4330428 Z" id="Shape-Copy" fill="#495466" fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconEdit;
