import React from 'react';

export const logo = (
    <h1 className="logo-text">GLS</h1>
);

export const mainMenu = [
    {
        title: 'Dashboard',
        path: '/glsmon/dashboard',
        key: 'dashboard'
    },
    {
        title: 'Notifications',
        path: '/glsmon/notifications',
        key: 'notifications'
    },
    {
        title: 'Metrics',
        path: '/glsmon/metrics',
        key: 'Metrics'
    },
    {
        title: 'Development',
        path: '/glsmon/projects',
        key: 'Development'
    },
    {
        title: 'Hosts',
        path: '/glsmon/hosts',
        key: 'Hosts'
    },
    {
        title: 'Accounts',
        path: '/glsmon/customers',
        key: 'Accounts'
    },
    {
        title: 'Help',
        path: '/glsmon/help',
        key: 'Help'
    },
];

export const accountMenu = [
    {
        title: 'Alerts',
        path: '/alerts',
        key: 'alerts',
        badgeCount: 1,
    },
    {
        key: 'account',
        badgeCount: 0,
    },
];

export const accountSubMenu = [
    {
        title: 'Billing',
        path: '/billing',
        key: 'billing'
    },
    {
        title: 'Log In',
        path: '/auth/login',
        key: 'login'
    }
];

export const badgeCount = 0;

export const accountMenuAlertBadge = [
    {
        title: 'Alerts',
        path: '/alerts',
        key: 'alerts',
        badgeCount: 9,
    },
    {
        key: 'account',
        badgeCount: 0,
    },
];

export const accountMenuAccountBadge = [
    {
        title: 'Alerts',
        path: '/alerts',
        key: 'alerts',
        badgeCount: 0,
    },
    {
        key: 'account',
        badgeCount: 4,
    },
];

export const accountMenuExtraItem = [
    {
        title: 'Notes',
        path: '/notes',
        key: 'notes',
        badgeCount: 0,
    },
    {
        title: 'Alerts',
        path: '/alerts',
        key: 'alerts',
        badgeCount: 0,
    },
    {
        key: 'account',
        badgeCount: 0,
    },
];
