import React from 'react'

const IconPositive = props => {
  const { width, height } = props;

  return (
    <svg className="IconPositive gls-icon" width={width || "22px"} height={height || "22px"} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-547.000000, -5285.000000)">
          <g id="possitive" transform="translate(534.000000, 5272.000000)">
            <g id="Group-5">
              <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
            </g>
            <g id="flaticon1548404558-svg-copy-2" transform="translate(13.000000, 13.000000)" fill="#0ECC31" fillRule="nonzero">
              <path d="M11,0 C4.92515457,0 0,4.92494832 0,11 C0,17.0750517 4.92515457,22 11,22 C17.0755157,22 22,17.0750517 22,11 C22,4.92494832 17.0753095,0 11,0 Z M8.98214997,16.6504854 L4.13861897,11.8071606 L5.7529918,10.1927878 L8.98214997,13.4217397 L16.2470082,6.15667524 L17.861381,7.77104808 L8.98214997,16.6504854 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconPositive;
