import React from 'react';
import ContactAddresses from './ContactAddresses';
import ContactNodes from './ContactNodes';
import TimePeriods from './TimePeriods';
import TabbedPage from '../components/TabbedPage';

const ContactsTabs = props => {
  const { activeTab, match, history } = props;

  const contactsTabs = [
    { title: 'Contact Addresses', key: 'contacts', content: <ContactAddresses {...props} /> },
    { title: 'Contact Nodes', key: 'contact-nodes', content: <ContactNodes {...props} /> },
    { title: 'Time Periods', key: 'time-periods', content: <TimePeriods {...props} /> },
  ];
  const tabbedPage = (
    <TabbedPage
      isContainer={false}
      tabs={contactsTabs}
      activeTab={activeTab}
      router={{ match, history }}
    />
  );

  return tabbedPage; //<Page view={tabbedPage} {...props} />;
}

export default ContactsTabs;
