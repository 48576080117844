import React from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import BackLink from '../components/BackLink';
import AlertBar from '../components/AlertBar';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import ContactAddressAdd from './ContactAddressAdd';
import IconAdd from '../theming/icons/IconAdd';
import { contactAddressColumns, contactAddressActions } from '../api/schema/contactaddress';

class ContactAddresses extends React.Component {
  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New Contact Address', href: '/glsmon/notifications/contacts/new' }
    ];

    this.tableOptionsModel = {
      type: 'contactaddress',
      title: 'Contact Address',
      rowsPerPage: 5,
      currentPage: 1
    }
  }

  render() {
    let match = this.props.match;

    return (
      <div className="ContactAddresses view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">

            <BackLink history={this.props.history} />

            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return alert ? <AlertBar key={i} alert={alert} showIcon /> : null;
              })}
            </div>

            <h1 className="header-bar no-margin">Contact Addresses</h1>

            <DataWrapper
              api="contactaddress"
              columns={contactAddressColumns}
              // filterTypes={filterTypes} 
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
              panelHeader={this.panelHeader}
              actions={contactAddressActions(this.props.history)}
            >
              {
                (props, state, actions) => (
                  <section className="view-section hosts-section filters-section--expanded">
                    <DynamicTable
                      {...props}
                      {...state}
                      {...actions}
                    />
                  </section>
                )
              }
            </DataWrapper>

          </div>
        )} />
        <Switch>
          <Route exact path="/glsmon/notifications/contacts/new" render={(props) => <ContactAddressAdd actionType="create" {...props} />} />
          <Route path="/glsmon/notifications/contacts/:id" render={(props) => <ContactAddressAdd actionType="edit" {...props} />} />
        </Switch>
      </div>
    );
  }

  viewContactAddress = (id) => {
    this.props.history.push(`/glsmon/contacts/${id}`);
  }

  deleteContactAddress = (id) => {

  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Contact Addresses define output destinations (such as an email address or SMS phone number) and methods (currently only email, but this could expand to choosing more concise or verbose message templates for example).</p>,
          <p>Addresses must be linked via Contact Nodes to Metrics (or groups of Metrics, such as by Host or Customer) in order to receive Notifications.</p>
        ]
      }
    ];
  }

  panelHeader = (item) => <Link to={`/glsmon/notifications/contacts/${item.id}`}>{item.name}</Link>;
}

export default withRouter(ContactAddresses);
