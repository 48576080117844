import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import Filter from '../components/Filter';
import AlertBar from '../components/AlertBar';
import BackLink from '../components/BackLink';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import ContactNodeAdd from './ContactNodeAdd';
import axios from 'axios';
import IconAdd from '../theming/icons/IconAdd';
import { contactNodesColumns } from '../api/schema/contactnode';

class ContactNodes extends React.Component {
  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New Contact Node', href: '/glsmon/notifications/contact-nodes/new' }
    ];
    this.tableOptionsModel = {
      type: 'contactnode',
      title: 'Contact Nodes',
      rowsPerPage: 5,
      currentPage: 1
    }
  }

  render() {
    const match = this.props.match;

    return (
      <div className="ContactNodes view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">

            <BackLink history={this.props.history} />

            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return alert ? <AlertBar key={i} alert={alert} showIcon /> : null;
              })}
            </div>

            <h1 className="header-bar no-margin">Contact Nodes</h1>

            <DataWrapper
              api="contactnode"
              columns={contactNodesColumns}
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
              panelHeader={this.panelHeader}
              actions={[
                {
                  name: "Edit",
                  type: "primary",
                  func: this.viewContactNode,
                  enabled: () => true
                },
                {
                  name: "Delete",
                  type: "primary",
                  func: this.deleteContactNode,
                  enabled: (record) => {
                    return true
                  }
                }
              ]}
            >
              {
                (props, state, actions) => (
                  <><section className="view-section filters-section filters-section--expanded">
                    <header className="view-section-header">
                      <h3 className="view-section-title">Available Filters</h3>
                      <Filter expanded expandable
                        {...props}
                        {...state}
                        {...actions} />
                    </header>
                  </section><section className="view-section hosts-section">
                      <DynamicTable
                        {...props}
                        {...state}
                        {...actions} />
                    </section></>
                )
              }
            </DataWrapper>

          </div>
        )} />
        <Switch>
          <Route exact path={`${match.url}/new`} render={(props) => <ContactNodeAdd actionType="create" {...props} />} />
          <Route path={`${match.url}/:id`} render={(props) => <ContactNodeAdd actionType="edit" {...props} />} />
        </Switch>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Contact Nodes are used to route Event Signals from Metrics (optionally grouped in bulk by Host or Customer) to Contact Addresses. When a Metric changes state, it signals any attached Contact Nodes, which may propagate that signal in turn to additional Contact Nodes and/or Contact Addresses, which then convert that Event Signal to outgoing Notifications.</p>,
          <p>Contact Nodes may optionally delay or filter signals passing through them - see the edit form for more details on specific fields.</p>,
          <p>Use the flowchart link below to view the current overall system configuration. This is the best way to understand the configuration as a whole, and to verify that everything connects as expected. </p>,
        ]
      }
    ];
  }

  panelHeader = (item) => {
    return <Link to={`/glsmon/contact-nodes/${item.id}`}>{item.name}</Link>
  }

  viewContactNode = (contactNode) => {
    this.props.history.push(`/glsmon/notifications/contact-nodes/${contactNode.id}`);
  }
  deleteContactNode = (contactNode) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(contactNode);
    var r = window.confirm(`Are you sure you want to delete this contactNode id : ${contactNode.id} `);
    if (r == true) {
      var obj = { type: contactNode.type, contactnode_id: contactNode.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}

export default ContactNodes;
