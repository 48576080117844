import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import AlertBar from '../components/AlertBar';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import IconAdd from '../theming/icons/IconAdd';
import { timePeriodColumns, timePeriodActions } from '../api/schema/timeperiod';
import TimePeriodsAdd from './TimePeriodsAdd';

class TimePeriods extends React.Component {
  constructor(props) {
    super(props);
    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New Time Period', href: '/glsmon/notifications/time-periods/new' }
    ];
    this.state = {
      loading: true
    }
    this.tableOptionsModel = {
      type: 'timeperiod',
      title: 'Time Period',
      rowsPerPage: 5,
      currentPage: 1
    }
  }

  render() {
    const match = this.props.match;
    return (
      <div className="TimePeriods view">
        <Route exact path={match.url} render={() => (
          <div className="page-area--tabbed">

            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return alert ? <AlertBar key={i} alert={alert} showIcon /> : null;
              })}
            </div>
            <h1 className="header-bar no-margin">Time Periods</h1>

            <DataWrapper
              api="timeperiod"
              columns={timePeriodColumns}
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
              panelHeader={this.panelHeader}
              actions={timePeriodActions(this.props.history)}
            >

              {

                (props, state, actions) => (
                  <section className="view-section hosts-section filters-section--expanded">
                    <DynamicTable
                      {...props}
                      {...state}
                      {...actions}
                    />

                  </section>
                )
              }
            </DataWrapper>

          </div>
        )} />
        <Switch>
          <Route exact path={`${match.url}/new`} render={(props) => <TimePeriodsAdd {...props} actionType="create" />} />
          <Route path={`${match.url}/:id`} render={(props) => <TimePeriodsAdd {...props} actionType="edit" />} />
        </Switch>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Time Periods are applied to Contact Nodes to define when Notifications should or should not be created, or to route Event Signals through different paths of Contact Nodes to change the initial delay, notify rate, or other details.</p>,
          <p>Note that multiple blocks may be defined within a single day, and blocks may overlap midnight.</p>
        ]
      }
    ];
  }

  panelHeader = item => <Link to={`/glsmon/notifications/time-periods/${item.id}`}>{item.name}</Link>
}

export default TimePeriods;
