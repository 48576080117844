import React from 'react';
import { SettingFilled } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import './NotificationsLinks.scss';

const NotificationsLinks = () => {

  return (
    <div className="NotificationsLinks">
      <a href='https://www.serversynergy.com/glsmon/contactnode/graph'><SettingFilled />View Notification Flowchart</a>
      <NavLink to="/glsmon/notifications/contacts"><SettingFilled />Configure Notifications</NavLink>
    </div>
  );
}

export default NotificationsLinks;
