import React from 'react';
import KeyValue from '../components/KeyValue';
import GLSAPI from '../api/api_config';
import { message } from 'antd';
import { formatKeyValuePairs, parseKeyValuePairs } from '../helpers';

class HostEditEnvironment extends React.Component {

  state = {
    keyValuePairs: []
  };

  componentDidMount() {
    if (this.props.host.env_vars) {
      this.setEnvVars(this.props.host.env_vars)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.host !== prevProps.host) {
      this.setEnvVars(this.props.host.env_vars)
    }
  }

  setEnvVars = (envVars) => {
    this.setState({
      keyValuePairs: parseKeyValuePairs(envVars)
    });
  }

  updateHost = (newKeyValuePairs) => {
    if (newKeyValuePairs !== this.state.keyValuePairs) {
      message.loading(`Saving environment variables`, 0);

      newKeyValuePairs = formatKeyValuePairs(newKeyValuePairs);
      GLSAPI.endpoints['host'].edit({ id: this.props.hostId, env_vars: newKeyValuePairs })
        .then((response) => {
          this.setState({
            keyValuePairs: parseKeyValuePairs(response.env_vars)
          })
          message.destroy();
          message.success("Environment variables updated", 5);
        }).catch((error) => {
          console.log(error);
          message.destroy();
          message.error('Something went wrong', 5);
        })
    }
  }

  render() {
    return (
      <div className="HostEditEnvironment nested-tab-view">
        <KeyValue keyValuePairs={this.state.keyValuePairs} onChange={this.updateHost} />
      </div>
    );
  }
}

export default HostEditEnvironment;