import React from 'react';
import GLSAPI from './api/api_config';
import LoadingScreen from './views/LoadingScreen';
import ErrorScreen from './views/ErrorScreen';
import * as auth from './auth-provider';

async function bootstrapAppData() {
  let authenticated = false;
  let user = await auth.getUser();

  if (user) {
    // check that the user we have is authenticated
    authenticated = await auth.checkAuthenticated();
  }

  return {
    user,
    authenticated,
    loading: false
  }
}

const AuthContext = React.createContext();
const AuthDispatchContext = React.createContext();

const initialState = {
  bootstrapped: false,
  user: null,
  error: null,
  authenticated: false,
  loading: true,
  status: 'pending',
};

function authReducer(state, action) {
  switch (action.type) {
    case 'authenticated': {
      return { authenticated: true };
    }
    case 'unauthenticated': {
      GLSAPI.setAuthenticated(false);
      return { authenticated: false };
    }
    case 'bootstrap_complete': {
      return {
        bootstrapped: true,
        authenticated: action.payload.authenticated,
        loading: action.payload.loading,
        status: action.payload.status,
        user: action.payload.user,
        error: null,
      }
    }
    case 'authentication_complete': {
      return {
        ...state,
        authenticated: action.payload.authenticated,
        loading: action.payload.loading,
        status: action.payload.status,
        user: action.payload.user,
        error: null,
      }
    }
    case 'authentication_error': {
      return {
        bootstrapped: true,
        authenticated: false,
        loading: false,
        status: 'error',
        user: null,
        error: action.payload.error,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  if (!state.bootstrapped) {
    bootstrapAppData().then((data) => {
      GLSAPI.setAuthenticated(data.authenticated);
      dispatch({ type: 'bootstrap_complete', payload: data });
    }, (error) => {
      dispatch({ type: 'authentication_error', payload: { error, status: 'error', user: null } });
    });
  }

  if (state.loading) {
    return <LoadingScreen />
  }

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {state.error ? (
          <ErrorScreen errorMessage={state.error.message} />
        ) : (
          children
        )}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}

function AuthConsumer({ children }) {
  return (
    <AuthContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('AuthConsumer must be used within a AuthProvider')
        }
        return children(context)
      }}
    </AuthContext.Consumer>
  )
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthDispatchContext.Provider');
  }
  return context;
}

async function setAuthenticated(dispatch, authenticated) {
  let user = null;
  if (authenticated) {
    user = await auth.getUser();
  }
  GLSAPI.setAuthenticated(authenticated);
  dispatch({
    type: 'authentication_complete', payload: {
      user,
      authenticated,
      loading: false,
      status: 'success'
    }
  });
}

export { AuthProvider, AuthConsumer, useAuth, useAuthDispatch, setAuthenticated };