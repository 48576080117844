import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input } from 'antd';
import Icon from '@ant-design/icons';
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';

import IconPositive from '../theming/icons/IconPositive';
import IconError from '../theming/icons/IconError';

import './KeyValue.scss';

class KeyValue extends React.Component {

    constructor(props) {
        super(props);

        var keyValuePairs = props.keyValuePairs.map((pair) => {
            return { ...pair, loading: false }
        });

        keyValuePairs.push({
            key: this.props.keyPlaceholder,
            value: this.props.valuePlaceholder,
            loading: false
        });

        this.state = {
            keyValuePairs: keyValuePairs
        }

        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.keyValuePairs !== prevProps.keyValuePairs) {
            var keyValuePairs = this.props.keyValuePairs.map((pair) => {
                return { ...pair, loading: false }
            });

            keyValuePairs.push({
                key: this.props.keyPlaceholder,
                value: this.props.valuePlaceholder,
                loading: false
            });

            this.setState({
                keyValuePairs
            });
        } else {
            // automatically add new key/value inputs:
            let existingPairs = this.state.keyValuePairs;
            var lastElement = existingPairs[existingPairs.length - 1];
            if (lastElement.key !== this.props.keyPlaceholder) {
                this.addKeyValuePair();
            }
        }
    }

    render() {
        return (
            <div className="KeyValue">
                <Row className="key-value-header">
                    <Col span={12} >Press enter after you input a key value pair to save or update</Col>
                </Row>
                <Row gutter={12} className="key-value-header">
                    <Col span={12}>Key</Col>
                    <Col span={12}>Value</Col>
                </Row>
                {
                    this.state.keyValuePairs.map((pair, i) => {
                        var keyInputProps = {};
                        var valueInputProps = {};

                        if (pair.key === this.props.keyPlaceholder) {
                            keyInputProps['placeholder'] = this.props.keyPlaceholder;
                        } else {
                            keyInputProps['defaultValue'] = pair.key;
                        }

                        if (pair.value === this.props.valuePlaceholder) {
                            valueInputProps['placeholder'] = this.props.valuePlaceholder;
                        } else {
                            valueInputProps['defaultValue'] = pair.value;
                        }

                        return (
                            <Row gutter={12} key={pair.key + pair.value} className="key-value-row">
                                <Col span={12}>
                                    <Input
                                        {...keyInputProps}
                                        onChange={() => this.setLoading(pair)}
                                        onPressEnter={(e) => this.handleKeyChange(pair.key, e.target.value)}
                                        onBlur={(e) => this.handleKeyChange(pair.key, e.target.value)}
                                        addonBefore={this.savedIcon(pair)}
                                        className="key-value-input"
                                        ref={inputEl => (this.keyInput = inputEl)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Input
                                        {...valueInputProps}
                                        onChange={() => this.setLoading(pair)}
                                        onPressEnter={(e) => { this.handleValueChange(pair.key, e.target.value) }}
                                        onBlur={(e) => this.handleValueChange(pair.key, e.target.value)}
                                        addonAfter={this.deleteButton(pair.key)}
                                        className="key-value-input-textarea"
                                        autosize={{ minRows: 1, maxRows: 10 }}
                                        ref={inputEl => (this.valueInput = inputEl)}
                                    />
                                </Col>
                            </Row>
                        )
                    })
                }
            </div>
        );
    }

    setLoading(loadingPair) {
        this.setState(state => {
            const keyValuePairs = state.keyValuePairs.map((pair) => {
                if (pair.key === loadingPair.key) {
                    return ({
                        ...pair,
                        loading: true
                    });
                } else {
                    return pair;
                }
            })

            return {
                keyValuePairs
            }
        });
    }

    setNotLoading(notLoadingPair) {
        this.setState(state => {
            const keyValuePairs = state.keyValuePairs.map((pair) => {
                if (pair.key === notLoadingPair.key) {
                    return ({
                        ...pair,
                        loading: false
                    });
                } else {
                    return pair;
                }
            })

            return {
                keyValuePairs
            }
        });
    }

    savedIcon(pair) {
        if (pair.loading) {
            return <LoadingOutlined />;
        } else if (pair.key !== this.props.keyPlaceholder || pair.value !== this.props.valuePlaceholder) {
            return <Icon component={IconPositive} />;
        } else if (pair.key === this.props.keyPlaceholder) {
            return "";
        } else if (pair.error) {
            return <Icon component={IconError} />;
        } else {
            return <Icon className="blank-icon" />
        }
    }

    deleteButton(key) {
        if (key !== this.props.keyPlaceholder) {
            return (
                <a href="#_" onClick={(e) => { e.preventDefault(); this.deletePair(key) }}>
                    <DeleteOutlined />
                </a>
            );
        } else {
            return "";
        }
    }

    deletePair(keyToRemove) {
        this.setState(state => {
            const keyValuePairs = state.keyValuePairs.filter((pair) => pair.key !== keyToRemove);
            return {
                keyValuePairs
            }
        }, () => {
            this.sendUpdatedValues();
        });
    }

    addKeyValuePair() {
        this.setState(state => {
            const keyValuePairs = [...state.keyValuePairs, { key: this.props.keyPlaceholder, value: this.props.valuePlaceholder, loading: false }];

            return {
                keyValuePairs
            };
        });
    }

    handleKeyChange(oldKey, newKey) {
        if (newKey === "" || newKey === " " || newKey === "Key") {
            return;
        }
        this.setState(state => {
            const keyValuePairs = state.keyValuePairs.map((pair) => {
                if (pair.key === oldKey) {
                    return ({
                        key: newKey,
                        value: pair.value,
                        loading: (pair.value === this.props.valuePlaceholder ? true : false)
                    });
                } else {
                    return pair;
                }
            });

            return {
                keyValuePairs
            }
        }, () => {
            if (this.shouldSendUpdate(newKey)) {
                this.sendUpdatedValues();
                this.valueInput.focus();
            }
        });
    }

    handleValueChange(key, newValue) {
        if (newValue === "" || newValue === " " || newValue === "Value") {
            return;
        }

        this.setState(state => {
            const keyValuePairs = state.keyValuePairs.map((pair) => {
                if (pair.key === key) {
                    return ({
                        key: key,
                        value: newValue,
                        loading: false
                    });
                } else {
                    return pair;
                }
            });

            return {
                keyValuePairs
            }
        }, () => {
            if (this.shouldSendUpdate(key)) {
                this.sendUpdatedValues();
            }
        });
    }

    sendUpdatedValues() {
        // remove props used just in this component
        const keyValuePairs = this.state.keyValuePairs.filter((pair) => (pair.key !== this.props.keyPlaceholder && pair.value !== this.props.valuePlaceholder)).map((pair) => {
            return ({
                key: pair.key,
                value: pair.value
            });
        });
        this.props.onChange(keyValuePairs);
    }

    shouldSendUpdate = (key) => {
        let keyValuePairs = this.state.keyValuePairs;
        for (let i = 0; i < keyValuePairs.length; i++) {
            let keyPair = keyValuePairs[i];
            if (keyPair.key === key) {
                if (keyPair.key === this.props.keyPlaceholder || keyPair.value === this.props.valuePlaceholder) {
                    return false;
                }
                return true;
            }
        }
    }
}

KeyValue.propTypes = {
    onChange: PropTypes.func,
    keyValuePairs: PropTypes.arrayOf(PropTypes.object),
}

KeyValue.defaultProps = {
    keyValuePairs: [],
    keyPlaceholder: 'Key',
    valuePlaceholder: 'Value',
    onChange: () => { }
}

export default KeyValue;