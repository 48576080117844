import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import './TabbedPage.scss';

const TabPane = Tabs.TabPane;

class TabbedPage extends React.Component {
    render() {
        if (this.props.isContainer) {
            return this.wrapTabs(
                <Tabs type={this.props.tabStyle} className="tab-wrapper" activeKey={this.props.activeTab} onChange={this.updateRouter}>
                    {
                        this.props.tabs.map((tab, index) => {
                            return (
                                <TabPane key={tab.key ? tab.key : tab.title} tab={tab.title}>
                                    {tab.content}
                                </TabPane>
                            );
                        })
                    }
                </Tabs>
            )
        }
        return (
            <div className="TabbedPage">
                <Tabs type={this.props.tabStyle} className="tab-wrapper" activeKey={this.props.activeTab} onChange={this.updateRouter}>
                    {
                        this.props.tabs.map((tab, index) => {
                            return (
                                <TabPane key={tab.key ? tab.key : tab.title} tab={tab.title}>
                                    {tab.content}
                                </TabPane>
                            );
                        })
                    }
                </Tabs>
            </div>
        );
    }

    wrapTabs(content) {
        return (
            <div className="TabbedPage">
                {content}
            </div>
        );
    }

    updateRouter = (tabKey) => {
        if (this.props.history && this.props.match) {
            let history = this.props.history;
            let match = this.props.match;
            if (this.props.isContainer) {
                // swap the path for the new path
                let newPath = '/' + tabKey;
                history.push(newPath);
            } else {
                let currentSubpage = match.params.subpage;
                let currentPath = match.url;
                // is nested, only change the subpage
                if (currentSubpage) {
                    let newPath = currentPath.replace(currentSubpage, tabKey);
                    history.push(newPath);
                } else {
                    let newPath = currentPath + '/' + tabKey;
                    history.push(newPath);
                }
            }
        }
    }
}

TabbedPage.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.node
    })),
    tabStyle: PropTypes.string,
    isContainer: PropTypes.bool,
    activeTab: PropTypes.string,
    onChange: PropTypes.func,
};

TabbedPage.defaultProps = {
    tabs: [],
    tabStyle: 'card',
    isContainer: true,
};

export default withRouter(TabbedPage);
