import React from 'react';
import { withRouter } from 'react-router-dom';
import DataWrapper from '../components/DataWrapper';
import { checkColumns, checkFilterTypes } from '../api/schema/check';
import DynamicTable from '../components/DynamicTable';
import axios from 'axios';
import IconAdd from '../theming/icons/IconAdd';

class ProjectsViewingChecks extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    this.panelHeader = item => item.check;

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Create New Check', href: `/glsmon/projects/${this.props.projectId}/checks/new` }
    ];

    this.tableOptionsModel = {
      type: 'checks',
      title: 'Checks',
      rowsPerPage: 5,
      rowCount: 16,
      currentPage: 1
    };
  }

  render() {
    return (
      <div className="ProjectsViewingChecks nested-tab-view">

        <DataWrapper
          pageTitle="Checks"
          api="check"
          columns={checkColumns}
          requiredParams={{ project: this.props.projectId }}
          filterTypes={checkFilterTypes}
          panelHeader={this.panelHeader}
          tableOptionsLinks={this.tableOptionsLinks}
          tableOptionsModel={this.tableOptionsModel}
          actions={[
            {
              name: "Edit",
              type: "primary",
              func: this.viewCheck,
              enabled: (record) => {
                // create a rule to determine if the action is available
                return true
              }
            },
            {
              name: "Delete",
              type: "primary",
              func: this.deleteCheck,
              enabled: (record) => {
                return true
              }
            }
          ]}>
          {
            (props, state, actions) => (
              <>
                <div className="view-section view-section--no-border">
                  <DynamicTable
                    {...props}
                    {...state}
                    {...actions}

                  />
                </div>
              </>
            )
          }
        </DataWrapper>
      </div>
    )
  }

  viewCheck = (check) => {
    this.props.history.push(`/glsmon/projects/${this.props.projectId}/checks/${check.id}`);
  }
  deleteCheck = (check) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(check);
    var r = window.confirm(`Are you sure you want to delete this check id : ${check.id} `);
    if (r == true) {
      var obj = { type: check.type, check_id: check.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}

export default withRouter(ProjectsViewingChecks);
