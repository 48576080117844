import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import axios from 'axios';


export const hostgroupColumns = [
  {
    title: "Hostgroup",
    key: "hostgroup",
    render: (text, record) => <Link className="underline" to={`/glsmon/hostgroups/${record.id}`}>{record.name}</Link>
  },
  {
    title: "Hosts",
    key: "hosts",
    render: (text, record) => {
      let records = record.hosts.slice(0, 2);
      if (record.hosts.length > 2) records.push({ name: `and ${record.hosts.length - 2} more`, id: 'extra-hosts' });
      return records.map(record => <div key={record.id}>{record.name}</div>);
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          {record.actions.map(action => {
            if (action.name == "View") {
              return <Button key={`${record.id}-${action.name}`} onClick={() => action.func(record)} type={action.type}>{action.name}</Button>
            }
            else {
              return <Button key={`${record.id}-${action.name}`} onClick={() => action.func(record)} type={action.type} danger>{action.name}</Button>
            }
          })}
        </span>
      );
    }
  }
];

export const hostgroupActions = (history) => {
  return [
    {
      name: "View",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/hostgroups/${record.id}`);
      },
      enabled: (record) => {
        return true
      }
    },
    {
      name: "Delete",
      type: "primary",
      func: (record) => {
        // history.push(`/glsmon/hostgroups/${record.id}`);
        console.log(record);
        var r = window.confirm(`Are you sure you want to delete this hostgroup id : ${record.id} `);
        if (r == true) {
          var obj = { type: record.type, hostgroup_id: record.id };
          const options = {
            headers: { "Access-Control-Allow-Origin": "*" }
          };
          axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
            .then(response => {
              // this.setState({ customer: response.data });
              console.log(response);
              window.location.reload();
            })
            .catch(error => {
              alert("Data is not Sucessfully posted");
              window.location.reload();
            })
        }
      },
      enabled: (record) => {
        return true
      }
    }
  ];
}

export const hostgroupFilterTypes = {
  "Hostgroup": "SearchField",
  "Select Hostgroups": "MultiSelector"
};

export const hostgroupWidgets = {
  "hosts": {
    spanWidth: 24,
    render: (resource) => {
      if (resource && resource.hosts && resource.hosts.length > 0) {
        const reducer = (hostNames, currentHost) => {
          if (hostNames.length === 0) { return currentHost.name; }
          return hostNames + ", " + currentHost.name;
        }

        const hostNames = resource.hosts.reduce(reducer, []);

        return (
          <div>
            <p>{hostNames}</p>
            <label>Hosts</label>
          </div>
        );
      }
    }
  }
}

export const hostgroupLabelMap = {
  "name": "Hostgroup Name"
}
