import React from 'react';
import { Switch } from 'antd';

import './Toggler.scss';

const Toggler = props => {
  const { offLabel, onLabel, checked } = props;
  return (
    <div className="Toggler">
      <span className="Toggler-off-label">{offLabel}</span>
      <span className="Toggler-switch-wrap"><Switch defaultChecked={checked} /></span>
      {/* TODO: add onChange handler that has to pass a prop up to DynamicTable which in turn makes another api call */}
      <span className="Toggler-on-label">{onLabel}</span>
    </div>
  );
}

export default Toggler;