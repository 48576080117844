import React from 'react';
import { Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './AlertBar.scss';

import Expander from './Expander';

function AlertBar({ alert: { type, title, content }, showIcon, open }) {
  if (type === 'basic' && showIcon) {
    var icon = <InfoCircleOutlined />
  };

  return (
    <div className="AlertBar">
      <Alert
        message={title}
        description={<Expander sections={content} open={open} />}
        type={type || 'info'}
        showIcon={showIcon}
        icon={icon}
      />
    </div>
  )
}

AlertBar.propTypes = {
  alert: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.node)
  }),
  showIcon: PropTypes.bool,
  open: PropTypes.bool,
};

AlertBar.defaultProps = {
  showIcon: false
}

export default AlertBar;