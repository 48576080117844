import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import HostgroupsEdit from './HostgroupsEdit';
import HostgroupsViewing from './HostgroupsViewing';
import DataWrapper from '../components/DataWrapper';
import AlertBar from '../components/AlertBar';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import { hostgroupColumns, hostgroupFilterTypes, hostgroupActions } from '../api/schema/hostgroup';
import { UserConsumer } from '../UserContext';

class Hostgroups extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: null, linkText: 'Create New Hostgroup', href: '/glsmon/hostgroups/new' },
    ];

    this.tableOptionsModel = { type: 'hostgroups', title: 'Hostgroups', rowsPerPage: 5, currentPage: 1 };

  }


  render() {
    let match = this.props.match;
    return (
      <div className="Hostgroups view">
        <UserConsumer>
          {({ currentCustomer }) => (
            <>
              <Route exact path={match.url} render={() => (
                <div className="page-area--tabbed">

                  <h1 className="header-bar">All Hostgroups</h1>
                  <div className="page-info">
                    {this.alerts.map((alert, i) => {
                      return <AlertBar key={i} alert={alert} showIcon />
                    })}
                  </div>
                  <DataWrapper
                    pageTitle="Hostgroups"
                    api="hostgroup"
                    columns={hostgroupColumns}
                    filterTypes={hostgroupFilterTypes}
                    panelHeader={this.panelHeader}
                    tableOptionsLinks={this.tableOptionsLinks}
                    tableOptionsModel={this.tableOptionsModel}
                    requiredParams={{
                      customer_id: currentCustomer.id
                    }}
                    actions={hostgroupActions(this.props.history)}
                  >
                    {
                      (props, state, actions) => (
                        <>
                          {props.availableFilters &&
                            <section className="view-section filters-section filters-section--expanded">
                              <header className="view-section-header">
                                <h3 className="view-section-title">Available Filters</h3>
                                <Filter expanded expandable
                                  {...props}
                                  {...state}
                                  {...actions}
                                />
                              </header>
                            </section>
                          }
                          <section className="view-section">
                            <DynamicTable
                              {...props}
                              {...state}
                              {...actions}

                            />
                          </section>
                        </>
                      )
                    }
                  </DataWrapper>
                </div>
              )} />
              <Switch>
                <Route path="/glsmon/hostgroups/new" render={props => <HostgroupsEdit viewTitle="Add Hostgroup" actionType="create" {...props} />} />
                <Route path="/glsmon/hostgroups/:id/edit" render={props => <HostgroupsEdit viewTitle="Edit Hostgroup" actionType="edit" {...props} />} />
                <Route path="/glsmon/hostgroups/:id" render={props => <HostgroupsViewing viewTitle="Hostgroup" actionType="create" {...props} />} />
              </Switch>
            </>
          )}
        </UserConsumer>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: "info",
        title: "",
        content: [<p>Hostgroups are used to group Hosts, both to help visually organize large monitoring installations as well as to allow bulk configuration across similar or related hosts.</p>]
      }
    ];
  }

  panelHeader = (item) => {
    return (
      <span className="panel-header-hostgroups">
        <a className="underline" href={`/glsmon/hostgroups/${item.id}`}>{item.name}</a>
      </span>
    );
  }
}

Hostgroups.defaultProps = {
  alerts: []
}

export default withRouter(Hostgroups);
