import * as React from 'react';
import Page from '../components/Page';

function ErrorScreen(props) {
  return (
    <Page view={
      <div className="ErrorScreen">
        <h2>Something went wrong . . .</h2>
        {props.errorMessage ? (
          <p>{props.errorMessage}</p>
        ) : (
          ''
        )}
      </div>
    } />
  )
}

export default ErrorScreen;