import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import IconAdd from '../theming/icons/IconAdd';
import { v4 as uuidv4 } from 'uuid';
import './CustomerBar.scss';
import { useUserState, useUserDispatch } from '../UserContext';

function CustomerBar(props) {
  const { currentCustomer, customers } = useUserState();
  const dispatch = useUserDispatch();

  const onSelectCustomer = ({ key }) => {
    key = parseInt(key);
    window.localStorage.setItem('customer_id', key);
    // console.log(key);
    dispatch({ type: 'set_customer_from_id', payload: key });
  }

  // const onSelectCustomer = value => {
  //   // console.log(value);
  //   value = parseInt(value);
  //   // console.log(key);
  //   dispatch({ type: 'set_customer_from_id', payload: value });
  // };

  const addCustomerButton = (
    <span key={uuidv4()}>
      {
        props.addCustomer ?
          <Link
            to="/customers/new"
            className="positive bold inline-icon"
            key={"add-customer-button"}
          >
            <IconAdd /> Add Customer
          </Link>
          :
          null
      }
    </span>
  );

  const renderMenu = () => (
    <Menu className="scroll" onClick={onSelectCustomer} style={{'height': 'auto','max-height': '350px', 'overflowY': 'scroll', '-ms-overflow-style': 'none', 'scrollbar-width': 'none'}}>
      {customers.map(customer => <Menu.Item key={customer.id} disabled={currentCustomer ? currentCustomer.id === customer.id : false}>{customer.name}</Menu.Item>)}
    </Menu>
  );

  const customerLink = (
    <a className="ant-dropdown-link" href="">
      {currentCustomer ? currentCustomer.name : ''}
      <CaretDownOutlined />
    </a>
  );

  var customerDropdown;
  if (customers.length === 1) {
    customerDropdown = customerLink;
  } else if (customers.length >= 2) {
    customerDropdown = (
      <Dropdown overlay={renderMenu()} trigger={['click']}>
        {customerLink}
      </Dropdown>
      // <Select onChange={onSelectCustomer} style={{ width: '200px'}} defaultValue={customerLink}>
      //   {customers.map(customer => <Select.Option key={customer.id} value={customer.id} disabled={currentCustomer ? currentCustomer.id === customer.id : false}>{customer.name}</Select.Option>)}
      // </Select>
    );
  }

  const titleBar = (
    <h2>
      {
        Object.entries(currentCustomer).length > 0 ?
          <span className="logged-in-text">You are logged in as customer</span>
          :
          <span className="logged-in-text">You do not have any customers</span>
      }
      {customerDropdown}
    </h2>
  );

  return (
    <div className="CustomerBar solid">
      <PageHeader
        title={titleBar}
        extra={[addCustomerButton]}
      />
    </div>
  );
}

export default CustomerBar;
