import React from 'react';
import { Button, Form, Input, message } from 'antd';
import BackLink from '../components/BackLink';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';
import MultiSelector from '../components/MultiSelector';

class HostgroupsEdit extends React.Component {
  constructor(props) {
    super(props);
    let resourceId = (props.match ? parseInt(props.match.params.id) : 0);
    this.state = {
      resourceId,
      resource: {},
      availableHosts: [],
      availableProjects: [],
      selectedHosts: [],
      selectedProjects: [],
      fetched: this.props.actionType === 'create',
    }
    // TODO: change resource to 'hostgroup'
    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['hostgroup'].create
      : GLSAPI.endpoints['hostgroup'].edit;
  }

  componentDidMount(props) {
    this.setState({
      loading: true
    })

    if (this.props.actionType === 'edit') {
      GLSAPI.endpoints['hostgroup'].getOne({ id: this.state.resourceId })
        .then((response) => {
          this.setState({
            fetched: true,
            resource: response,
            selectedHosts: response.hosts.map(function (obj) {
              return obj.id;
            }),
            selectedProjects: response.projects.map(function (obj) {
              return obj.id;
            })
          });
        }).catch((error) => {
          message.destroy();
          message.error('Error fetching hostgroups');
          this.setState({
            loading: false
          });
        })
    }


    GLSAPI.endpoints['host'].getAllPages()
      .then((response) => {
        let hosts = response.map((item, i) => { return { ...item, key: item.id } });
        this.setState({
          availableHosts: hosts
        });
      }).catch((error) => {
        message.destroy();
        message.error('Error fetching hosts');
        this.setState({
          loading: false
        });
      })

    GLSAPI.endpoints['project'].getAllPages()
      .then((response) => {
        let projects = response.map((item, i) => { return { ...item, key: item.id } });
        this.setState({
          availableProjects: projects
        });
      }).catch((error) => {
        message.destroy();
        message.error('Error fetching projects');
        this.setState({
          loading: false
        });
      })

  }


  render() {
    const { viewTitle } = this.props;

    return (
      <div className="HostgroupsEdit view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />
          <h1 className="header-bar no-margin">{viewTitle}</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="hostgroup"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            verbs={this.getVerbs()}
          >
            {this.addHostgroupForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  onSubmitCallback = (hostgroup) => {
    this.props.history.push('/glsmon/hostgroups/' + hostgroup.id);
  }

  onErrorCallback = (error) => {
    if (error.message === "Name already exists") {
      message.destroy();
      message.error('Hostgroup Name already exists. Please try a different name.');
    }
  }

  addHostgroupForm = (defaultFormLayout) => {
    if (!this.state.fetched) return null;

    return (
      <div>
        <div className="view-section view-section--no-border">
          {this.state.resourceId ?
            <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
            : ''
          }
          {
            (this.state.resourceId > 0) ?
              <Form.Item label="Hostgroup Name" {...defaultFormLayout} name="name"
                initialValue=''
              >
                <Input
                  placeholder={this.state.resource.name}
                />
              </Form.Item>
              :
              <Form.Item label="Hostgroup Name" {...defaultFormLayout} name="name"
                rules={[
                  { required: true, message: 'Hostgroup Name is required' }
                ]}
              >
                <Input />
              </Form.Item>
          }

        </div>
        <div className="view-section">
          <Form.Item label="Hosts" name="hosts"
            initialValue={this.state.selectedHosts}
          >
            <MultiSelector
              dataSource={this.state.availableHosts}
              targetKeys={this.state.selectedHosts}
              titles={["Available Hosts", "Added Hosts"]} />
          </Form.Item>
        </div>
        <div className="view-section">
          <Form.Item label="Projects" name="projects"
            initialValue={this.state.selectedProjects}
          >
            <MultiSelector
              dataSource={this.state.availableProjects}
              targetKeys={this.state.selectedProjects}
              titles={["Available Projects", "Added Projects"]} />
          </Form.Item>
        </div>
        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  };
}

export default HostgroupsEdit;
