import React from 'react'

const IconWarning = props => {
  const { width, height } = props;

  return (
    <svg className="IconWarning gls-icon" width={width || "22px"} height={height || "19px"} viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-435.000000, -5287.000000)">
          <g id="warning" transform="translate(422.000000, 5272.000000)">
            <g id="Group-5">
              <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
            </g>
            <path d="M34.8064071,31.6926366 L25.1543251,15.6532371 C24.9103912,15.2478702 24.4724102,15 23.9999682,15 C23.5275262,15 23.0895452,15.2478271 22.8456113,15.6532371 L13.1936153,31.6926366 C12.9425915,32.1098007 12.9351579,32.6301257 13.1742364,33.0542648 C13.4133148,33.478447 13.8617802,33.7407407 14.3479292,33.7407407 L33.6520502,33.7407407 C34.1381992,33.7407407 34.5866646,33.478404 34.825743,33.0542648 C35.0648215,32.6300826 35.0574308,32.1098007 34.8064071,31.6926366 Z M24.007187,20.5482099 C24.5615275,20.5482099 25.0305749,20.8615674 25.0305749,21.4169826 C25.0305749,23.1117738 24.8315864,25.5472477 24.8315864,27.242039 C24.8315864,27.6835725 24.3483163,27.8686678 24.007187,27.8686678 C23.5524052,27.8686678 23.1686078,27.6835294 23.1686078,27.242039 C23.1686078,25.5472477 22.9696623,23.1117738 22.9696623,21.4169826 C22.9696623,20.8615674 23.4244441,20.5482099 24.007187,20.5482099 Z M24.0214096,31.0447383 C23.3960417,31.0447383 22.9269513,30.5320342 22.9269513,29.9481163 C22.9269513,29.3499471 23.3959987,28.8514943 24.0214096,28.8514943 C24.6041525,28.8514943 25.1016453,29.3499471 25.1016453,29.9481163 C25.1016453,30.5320342 24.6041525,31.0447383 24.0214096,31.0447383 Z" id="warning_icon" fill="#FFA900" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconWarning;
