import React from 'react';
import Hosts from './Hosts';
import Hostgroups from './Hostgroups';
import HostAcks from './HostAcks';
import Page from '../components/Page';
import TabbedPage from '../components/TabbedPage';
import RegisterPin from './RegisterPin';

const HostsTabs = props => {
  const { activeTab } = props;
  const hostsTabs = [
    { title: 'Hosts', key: 'glsmon/hosts', content: <Hosts {...props} /> },
    { title: 'Hostgroups', key: 'glsmon/hostgroups', content: <Hostgroups {...props} /> },
    { title: 'HostAcks', key: 'glsmon/hostack', content: <HostAcks {...props} /> },
    { title: 'RegisterPins', key: 'glsmon/registerpin', content: <RegisterPin {...props} /> }
  ];
  const tabbedPage = <TabbedPage
    tabs={hostsTabs}
    activeTab={activeTab}
  />;

  return <Page view={tabbedPage} {...props} />;
};

export default HostsTabs;
