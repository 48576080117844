import React from 'react';
import { Input, TimePicker, InputNumber } from 'antd';

import './TextInput.scss';

const { TextArea } = Input;

function TextInput(props) {
  const { label, description, inputType, initialValue, inputPlaceholder, fullWidthInMobile } = props;

  let input;
  switch (inputType) {
    case 'password':
      input = <Input.Password inputValue={initialValue || ""} placeholder={inputPlaceholder || ""} />
      break;
    case 'number':
      input = <InputNumber defaultValue={initialValue !== undefined ? initialValue : 0} />
      break;
    case 'time':
      input = <TimePicker defaultOpenValue={initialValue || {}} />
      break;
    case 'textarea':
      input = <TextArea defaultValue={initialValue || ''} />
      break;
    default:
      input = <Input inputValue={initialValue || ""} placeholder={inputPlaceholder || ""} />
  }

  return (
    <div className={fullWidthInMobile === false ? "TextInput keep-flex" : "TextInput"}>
      <div className="TextInput-label-group">
        <label>{label}</label>
        {description
          ? <p className="TextInput-description">{description}</p>
          : null
        }
      </div>
      <div className="TextInput-input-group">
        {input}
      </div>
    </div>
  );
}

export default TextInput;
