import React from 'react';

const IconAdd = props => {
    const { width, height } = props;
    const defaultIconAdd = (
        <svg className="IconAdd IconAdd--default gls-icon" width={width || "22px"} height={height || "22px"} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="GLS_Desktop-Components" transform="translate(-603.000000, -5285.000000)">
                    <g id="add" transform="translate(590.000000, 5272.000000)">
                        <g id="Group-5">
                            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                            <path d="M24,13 C17.95,13 13,17.95 13,24 C13,30.05 17.95,35 24,35 C30.05,35 35,30.05 35,24 C35,17.95 30.05,13 24,13 Z M29.5,25.1 L25.1,25.1 L25.1,29.5 L22.9,29.5 L22.9,25.1 L18.5,25.1 L18.5,22.9 L22.9,22.9 L22.9,18.5 L25.1,18.5 L25.1,22.9 L29.5,22.9 L29.5,25.1 Z" id="Shape" fill="#0073FF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

    const pressedIconAdd = (
        <svg className="IconAdd IconAdd--pressed gls-icon" width={width || "28px"} height={height || "28px"} viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="GLS_Desktop-Components" transform="translate(-600.000000, -5330.000000)">
                    <g id="addpressed" transform="translate(590.000000, 5320.000000)">
                        <g id="Group-5">
                            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                        </g>
                        <g id="Group-7" transform="translate(10.000000, 10.000000)" fillRule="nonzero">
                            <circle id="Oval" fill="#E1F2FF" cx="14" cy="14" r="14"></circle>
                            <g id="flaticon1549331733-svg-copy-3" transform="translate(3.000000, 3.000000)">
                                <g id="flaticon1549331733-svg-copy-2" transform="translate(11.000000, 11.000000) scale(1, -1) translate(-11.000000, -11.000000) ">
                                    <rect id="Rectangle" fill="#FFFFFF" x="3.66666667" y="3.66666667" width="14.6666667" height="14.6666667" rx="7.33333333"></rect>
                                    <path d="M11,0 C4.95,0 0,4.95 0,11 C0,17.05 4.95,22 11,22 C17.05,22 22,17.05 22,11 C22,4.95 17.05,0 11,0 Z M16.5,12.1 L12.1,12.1 L12.1,16.5 L9.9,16.5 L9.9,12.1 L5.5,12.1 L5.5,9.9 L9.9,9.9 L9.9,5.5 L12.1,5.5 L12.1,9.9 L16.5,9.9 L16.5,12.1 Z" id="Shape" fill="#107CFF"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

    return props.pressed ? pressedIconAdd : defaultIconAdd;
}

export default IconAdd;
