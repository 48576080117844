import React from 'react';
import GLSAPI from './api/api_config';
import LoadingScreen from './views/LoadingScreen';
import ErrorScreen from './views/ErrorScreen';

import { useAuth } from './AuthContext';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const initialState = {
  loading: true,
  error: null,
  currentCustomer: {},
  customers: [],
  bootstrapped: false,
};

async function bootstrapUserData() {
  let data = initialState;
  let authState = useAuth();
  if (authState.authenticated) {
    await GLSAPI.endpoints['customer'].getAllPages().then((response) => {
      data.customers = response;
      data.loading = false;
      data.currentCustomer = response.length ? response[0] : {};
    }).catch((error) => {
      data.error = error
      data.loading = false;
    });
  }
  return data;
}

function userReducer(state, action) {
  switch (action.type) {
    case 'bootstrap_complete':
      GLSAPI.setActiveCustomer(action.payload.currentCustomer);
      return {
        ...state,
        loading: false,
        bootstrapped: true,
        error: null,
        customers: action.payload.customers,
        currentCustomer: action.payload.currentCustomer
      };
    case 'set_customer':
      GLSAPI.setActiveCustomer(action.payload);
      return {
        ...state,
        currentCustomer: action.payload,
      };
    case 'set_customer_from_id':
      let customer = state.customers.filter(customer => customer.id === action.payload);
      if (customer.length === 1) {
        GLSAPI.setActiveCustomer(customer[0]);
        return {
          ...state,
          currentCustomer: customer[0],
        };
      } else {
        return state;
      }
    case 'set_customers':
      return {
        ...state,
        customers: action.payload,
      };
    case 'error':
      return {
        ...state,
        error: action.payload,
        bootstrapped: true,
        loading: false
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  if (!state.bootstrapped) {
    bootstrapUserData().then((data) => {
      if (!data.error) {
        dispatch({ type: 'bootstrap_complete', payload: data });
      } else {
        dispatch({ type: 'error', payload: data.error });
      }
    }, (error) => {
      dispatch({ type: 'error', payload: error });
    })
  }

  if (state.loading) {
    return <LoadingScreen />
  }

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {state.error ? (
          <ErrorScreen errorMessage={state.error.message} />
        ) : (
          children
        )}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function UserConsumer({ children }) {
  return (
    <UserStateContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('UserConsumer must be used within a UserProvider')
        }
        return children(context)
      }}
    </UserStateContext.Consumer>
  )
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, UserConsumer, UserStateContext };