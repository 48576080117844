import React from 'react';
import { Button } from 'antd';
import { formatTimestampSeconds } from '../../helpers';

export const pinColumns = [
  {
    title: "Pin",
    dataIndex: "pin",
    key: "pin",
    render: (text, record) => {
      return (record.pin)
    }
  },
  {
    title: "Creation Time",
    dataIndex: "time_generated",
    key: "time_generated",
    render: (text, record) => formatTimestampSeconds(record.time_generated)
  },
  {
    title: "Current Installs",
    dataIndex: "install_count",
    key: "install_count",
    render: (text, record) => (record.install_count)
  },
  {
    title: "Max Installs",
    dataIndex: "max_installs",
    key: "max_installs",
    render: (text, record) => (record.max_installs)
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return null; }
          console.log(record);
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];
export const pinFilterTypes = {

};
