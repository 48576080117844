import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import axios from 'axios';

export const timePeriodColumns = [
  {
    title: 'Time Period',
    key: 'time_period',
    render: (text, record) => <Link to={`/glsmon/notifications/time-periods/${record.id}`}>{record.name}</Link>
  },
  // {
  //   title: 'Contact Nodes',
  //   key: 'contact_nodes',
  //   dataIndex: 'contact_nodes',
  //   render: (text, record) => {

  //     if (record.contact_nodes.length === 0) { return; }

  //     let reducer = (contactNodes, currentNode) => {
  //       if (contactNodes.length === 0) { return currentNode.name; }

  //       return contactNodes + ", " + currentNode.name;
  //     }
  //     return record.contact_nodes.reduce(reducer, []);
  //   }
  // },
  {
    title: "Contact Nodes",
    key: "contact_nodes",
    dataIndex: "contact_nodes",
    render: (text, record) => {
      let records = record.contact_nodes ? record.contact_nodes.slice(0, 2) : [];
      if (record.contact_nodes && record.contact_nodes.length > 2) {
        records.push({ name: `and ${record.contact_nodes.length - 2} more`, id: `extra-hosts` });
      }
      return records.map(record => <div key={record.id}>{record.name}</div>);
    }
  },
  {
    title: 'Mon-Fri Definition',
    key: 'mon_fri_definition',
    dataIndex: 'mon_fri_definition',
    render: (text, record) => {
      let output = '';
      if (record) {
        if (record.monday) {
          output += 'mon ' + record.monday + ', ';
        };
        if (record.tuesday) {
          output += 'tue ' + record.tuesday + ', ';
        };
        if (record.wednesday) {
          output += 'wed ' + record.wednesday + ', ';
        };
        if (record.thursday) {
          output += 'thu ' + record.thursday + ', ';
        };
        if (record.friday) {
          output += 'fri ' + record.friday;
        };
      }
      return output;
    }
  },
  {
    title: 'Sat-Sun Definition',
    key: 'sat_sun_definition',
    dataIndex: 'sat_sun_definition',
    render: (text, record) => {
      let output = '';
      if (record) {
        if (record.saturday) {
          output += 'sat ' + record.saturday + ', ';
        };
        if (record.sunday) {
          output += 'sun ' + record.sunday;
        };
      }
      return output;
    }
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {

          if (!action.enabled(record)) { return }

          return (
            <span key={record}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
]

export const timePeriodActions = (history) => {
  return [
    {
      name: "Edit",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/notifications/time-periods/${record.id}`);
      },
      enabled: (record) => {
        return true
      }
    },
    {
      name: "Delete",
      type: "primary",
      func: (record) => {
        // history.push(`/glsmon/hostgroups/${record.id}`);
        console.log(record);
        var r = window.confirm(`Are you sure you want to delete this timeperiod id : ${record.id} `);
        if (r == true) {
          var obj = { type: record.type, timeperiod_id: record.id };
          const options = {
            headers: { "Access-Control-Allow-Origin": "*" }
          };
          axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
            .then(response => {
              // this.setState({ customer: response.data });
              console.log(response);
              window.location.reload();
            })
            .catch(error => {
              alert("Data is not Sucessfully posted");
              window.location.reload();
            })
        }
      },
      enabled: (record) => {
        return true
      }
    }
  ];


}
