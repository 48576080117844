import React from 'react'

const IconMetrics = props => {
  const { width, height } = props;

  return (
    <svg className="IconMetrics gls-icon" width={width || "23px"} height={height || "22px"} viewBox="0 0 23 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-782.000000, -5285.000000)">
          <g id="Group-24" transform="translate(711.000000, 5272.000000)">
            <g id="metrics" transform="translate(58.000000, 0.000000)">
              <g id="Group">
                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
              </g>
              <g id="flaticon1552243137-svg" transform="translate(13.000000, 13.000000)" fill="#000000">
                <path d="M6.20873665,6 L0.791210758,6 C0.352769128,6 0,6.32748538 0,6.73450292 L0,21.2654971 C0,21.6725146 0.352769128,22 0.791210758,22 L6.20873665,22 C6.64717828,22 6.99994741,21.6725146 6.99994741,21.2654971 L6.99994741,6.73450292 C7.00498697,6.32748538 6.64717828,6 6.20873665,6 Z M5.41752589,20.5309942 L1.58242152,20.5309942 L1.58242152,7.46900585 L5.41752589,7.46900585 L5.41752589,20.5309942 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M22.2087833,10 L16.7912167,10 C16.3527718,10 16,10.3235747 16,10.7257319 L16,21.2742681 C16,21.6764253 16.3527718,22 16.7912167,22 L22.2087833,22 C22.6472282,22 23,21.6764253 23,21.2742681 L23,10.7257319 C23,10.3235747 22.6472282,10 22.2087833,10 Z M17.5824334,11.4514638 L18.812095,11.4514638 L17.5824334,12.5793529 L17.5824334,11.4514638 Z M17.5824334,13.7349769 L20.0669546,11.4560863 L21.3218143,11.4560863 L17.5824334,14.8859784 L17.5824334,13.7349769 L17.5824334,13.7349769 Z M21.4175666,20.5485362 L20.1828654,20.5485362 L21.4175666,19.4160247 L21.4175666,20.5485362 Z M21.4175666,18.2650231 L18.9280058,20.5485362 L17.6731461,20.5485362 L21.412527,17.1186441 L21.412527,18.2650231 L21.4175666,18.2650231 L21.4175666,18.2650231 Z M21.4175666,15.9676425 L17.5824334,19.4853621 L17.5824334,18.3343606 L21.4175666,14.816641 L21.4175666,15.9676425 Z M21.4175666,13.6656394 L17.5824334,17.183359 L17.5824334,16.0323575 L21.4175666,12.5146379 L21.4175666,13.6656394 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M14.2137756,0 L8.79121067,0 C8.35276909,0 8,0.328919265 8,0.737718924 L8,21.2622811 C8,21.6710807 8.35276909,22 8.79121067,22 L14.208736,22 C14.6471776,22 14.9999467,21.6710807 14.9999467,21.2622811 L14.9999467,0.737718924 C15.0049862,0.328919265 14.6522171,0 14.2137756,0 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconMetrics;
