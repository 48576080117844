import React from 'react';
import { Input, Button, Form, Col, Row, Checkbox, message } from 'antd';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';
import GLSAPI from '../api/api_config';
import './TimePeriodsAdd.scss';

class TimePeriodsAdd extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : null); // TEMP MOCK ID

    this.state = {
      resource: {},
      fetched: this.props.actionType === 'create',
      resourceId: resourceId,
      loading: true,
      weekdayRecurring: '00:00-23:59',
      weekendRecurring: '00:00-23:59',
      monday: '00:00-23:59',
      tuesday: '00:00-23:59',
      wednesday: '00:00-23:59',
      thursday: '00:00-23:59',
      friday: '00:00-23:59',
      saturday: '00:00-23:59',
      sunday: '00:00-23:59'
    }

    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['timeperiod'].create
      : GLSAPI.endpoints['timeperiod'].edit;
  }

  componentDidMount(props) {

    GLSAPI.endpoints['timeperiod'].getOne({ id: this.state.resourceId })
      .then((response) => {
        this.setState({
          loading: false,
          fetched: true,
          resource: response,
          resourceId: response.id,
          timePeriodFormat: 'HH:mm'
        }, () => {
          this.formRef.current.setFieldsValue({
            monday: this.state.resource.monday,
            tuesday: this.state.resource.tuesday,
            wednesday: this.state.resource.wednesday,
            thursday: this.state.resource.thursday,
            friday: this.state.resource.friday,
            saturday: this.state.resource.saturday,
            sunday: this.state.resource.sunday
          });
          this.setState({
            monday: this.state.resource.monday,
            tuesday: this.state.resource.tuesday,
            wednesday: this.state.resource.wednesday,
            thursday: this.state.resource.thursday,
            friday: this.state.resource.friday,
            saturday: this.state.resource.saturday,
            sunday: this.state.resource.sunday
          })
        });
      }).catch((error) => {
        this.setState({
          loading: false,
          fetched: false,
        });
      });
  }


  render() {
    const { actionType } = this.props;
    this.apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['timeperiod'].create
      : GLSAPI.endpoints['timeperiod'].edit;
    return (
      <div className="TimePeriodsAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />
          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} Time Periods</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="Time Period"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            formRef={this.formRef}
            timeEntryValidation={this.timeEntryValidation}
          >
            {this.addTimePeriodForm}
          </WrappedDataForm>
        </div>
      </div>
    );
  }

  onSubmitCallback = (timeperiod) => {
    this.props.history.push(`/glsmon/notifications/time-periods`);
  }

  onErrorCallback = (error) => {
    if (error.message === 'Name already exists') {
      message.destroy();
      message.error('Name already exists');
    }
  }

  timeEntryValidation = [
    { required: true, message: 'A time period is required ex: 00:00-23:59' },
    { min: 11, message: 'A time period is required ex: 00:00-23:59' },
    { max: 11, message: 'A time period is required ex: 00:00-23:59' }
  ];

  setWeekdayTimes = () => {
    let weekdayRecurring = this.state.monday;
    this.formRef.current.setFieldsValue({
      tuesday: weekdayRecurring,
      wednesday: weekdayRecurring,
      thursday: weekdayRecurring,
      friday: weekdayRecurring
    });
  }

  setWeekendTimes = () => {
    let weekendRecurring = this.state.saturday;
    this.formRef.current.setFieldsValue({
      sunday: weekendRecurring
    });
  }

  addTimePeriodForm = (defaultLayoutForm) => {
    if (!this.state.fetched) return null;
    return (
      <div>
        <section className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultLayoutForm} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }
          <Form.Item label="Time Period Name" {...defaultLayoutForm}
            name="name"
            initialValue={this.state.resource.name}
            rules={[{ required: true, message: 'Time Period name is required.' }]}
          >
            <Input placeholder="Time Period name" />
          </Form.Item>

          <Row>
            <Col span={6}>
              <strong>Weekday (Mon-Fri)</strong>
              <p>Set same time for every day <br></br>(based on Monday's timerange)<br></br><Checkbox onClick={this.setWeekdayTimes}></Checkbox></p>
            </Col>
            <Col span={8}>
              <Form.Item label={<div className="time-period--days">
                <span>Mon</span></div>} {...defaultLayoutForm} name="monday" placeholder='00:00-23:59'

                rules={this.timeEntryValidation}
              >
                <Input placeholder='00:00-23:59' onChange={(e) => {
                  this.setState({ monday: e.target.value })
                }} />
              </Form.Item>
              <Form.Item {...defaultLayoutForm} label={<div className="time-period--days">
                <span>Tue</span></div>} rules={this.timeEntryValidation} placeholder='00:00-23:59' name="tuesday" >
                <Input placeholder='00:00-23:59' />
              </Form.Item>

              <Form.Item {...defaultLayoutForm} label={<div className="time-period--days">
                <span>Wed</span></div>} rules={this.timeEntryValidation} placeholder='00:00-23:59' name="wednesday" >
                <Input placeholder='00:00-23:59' />
              </Form.Item>

              <Form.Item {...defaultLayoutForm} label={<div className="time-period--days">
                <span>Thu</span></div>} rules={this.timeEntryValidation} placeholder='00:00-23:59' name="thursday" initialValue={this.state.resource.thursday}>
                <Input placeholder='00:00-23:59' />
              </Form.Item>

              <Form.Item {...defaultLayoutForm} label={<div className="time-period--days">
                <span>Fri</span></div>} rules={this.timeEntryValidation} placeholder='00:00-23:59' name="friday" >
                <Input placeholder='00:00-23:59' />
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col span={6}>
              <strong>Weekend (Sat-Sun)</strong>
              <p>Set same time for every weekend day <br></br>(based on Saturday's timerange)<br></br><Checkbox onClick={this.setWeekendTimes}></Checkbox></p>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<div className="time-period--days">
                  <span>Sat</span></div>} {...defaultLayoutForm}
                placeholder='00:00-23:59' rules={this.timeEntryValidation}
                name="saturday"
              >
                <Input placeholder='00:00-23:59' onChange={(e) => {
                  this.setState({ saturday: e.target.value })
                }} />
              </Form.Item>

              <Form.Item label={<div className="time-period--days">
                <span>Sun</span></div>} {...defaultLayoutForm} name="sunday" placeholder='00:00-23:59' rules={this.timeEntryValidation} >
                <Input placeholder='00:00-23:59' />
              </Form.Item>
            </Col>
          </Row>


        </section>
        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  }
}

export default TimePeriodsAdd;
