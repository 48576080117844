import React from 'react';

import CustomerAccounts from './CustomerAccounts';
import Users from './Users';
import Page from '../components/Page';
import TabbedPage from '../components/TabbedPage';

const AccountsTabs = props => {
  const { activeTab } = props;

  const accountsTabs = [
    { title: 'Customers', key: 'glsmon/customers', content: <CustomerAccounts {...props} /> },
    { title: 'Users', key: 'glsmon/users', content: <Users {...props} /> }
  ];
  const tabbedPage = <TabbedPage
    router={{ match: props.match, history: props.history }}
    tabs={accountsTabs}
    activeTab={activeTab}
  />;

  return <Page view={tabbedPage} {...props} />;
}

export default AccountsTabs;
