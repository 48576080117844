import React from 'react';

const IconDelete = props => {
  const { width, height } = props;

  return (
    <svg className="IconDelete gls-icon" width={width || "22px"} height={height || "22px"} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-659.000000, -5286.000000)">
          <g id="delete" transform="translate(646.000000, 5273.000000)">
            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
            <path d="M24,13 C17.95,13 13,17.95 13,24 C13,30.05 17.95,35 24,35 C30.05,35 35,30.05 35,24 C35,17.95 30.05,13 24,13 Z M29.5,25.1 L25.1,25.1 L22.9,25.1 L18.5,25.1 L18.5,22.9 L22.9,22.9 L25.1,22.9 L29.5,22.9 L29.5,25.1 Z" id="Shape" fill="#DE2E28" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconDelete;
