import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustomerAccountsAdd from './CustomerAccountsAdd';
import DataWrapper from '../components/DataWrapper';
import DynamicTable from '../components/DynamicTable';
import AlertBar from '../components/AlertBar';
import IconAdd from '../theming/icons/IconAdd';
import IconStar from '../theming/icons/IconStar';
import { customerColumns } from '../api/schema/customer';
import axios from 'axios';
import './CustomerAccounts.scss';

export default class CustomerAccounts extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);

    this.tableOptionsLinks = [
      { icon: <IconAdd />, linkText: 'Add Customer', href: '/glsmon/customers/new' }
    ];

    this.tableOptionsModel = { type: 'customers', title: 'Customers', rowsPerPage: 5, currentPage: 1 };

  }

  render() {
    let match = this.props.match;
    return (
      <div className="Accounts CustomerAccounts view">
        <Route exact path={match.url} render={() => (
          <div className="page-area">
            <h1 className="header-bar">Customer Accounts</h1>
            <div className="page-info">
              {this.alerts.map((alert, i) => {
                return <AlertBar key={i} alert={alert} showIcon />
              })}
            </div>

            <DataWrapper
              api="customer"
              columns={customerColumns}
              tableOptionsLinks={this.tableOptionsLinks}
              tableOptionsModel={this.tableOptionsModel}
              panelHeader={this.panelHeader}
              actions={[
                {
                  name: "Edit",
                  type: "primary",
                  func: this.viewCustomer,
                  enabled: (record) => {
                    // create a rule to determine if the action is available
                    return true
                  }
                },
                {
                  name: "Delete",
                  type: "primary",
                  func: this.deleteCustomer,
                  enabled: (record) => {
                    return true
                  }
                }
              ]}
            >
              {
                (props, state, actions) => (
                  <section className="view-section accounts-section">
                    <DynamicTable
                      {...state}
                      {...props}
                      {...actions}
                    />
                  </section>
                )
              }
            </DataWrapper>
          </div>
        )} />
        <Switch>
          <Route
            path="/glsmon/customers/new"
            render={props => <CustomerAccountsAdd viewTitle="Add Customer" actionType="create" {...props} />}
          />
          <Route
            path="/glsmon/customers/:id"
            render={props => <CustomerAccountsAdd viewTitle="Edit Customer" actionType="edit" {...props} />}
          />
        </Switch>
      </div>
    );
  }

  viewCustomer = (record) => {
    this.props.history.push('/glsmon/customers/' + record.id);
  }
  deleteCustomer = (record) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(record);
    var r = window.confirm(`Are you sure you want to delete this customer id : ${record.id} `);
    if (r == true) {
      var obj = { type: record.type, customer_id: record.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }

  panelHeader = (item) => {
    return (
      <span className="panel-header-customer inline-icon" href="/">
        {item.star ? <IconStar /> : <span className="void-icon--nowidth"></span>}{item.name}
      </span>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Customer accounts own Hosts, Projects, and most other object types - except for Users, which are independent entities that can be linked to multiple Customers. A logged in User can use the 'Browse As' button below to change their current browser view and edit permissions to match any Customer they are linked to.</p>,
          <p>In general one Customer should not be able to view or edit objects from another Customer, with some exceptions such as publically-shared Projects. These permissions are not fully enforced at the moment, since permission bugs were getting in the way of higher priority development issues.</p>,
          <p>Multiple Customers on one server should work fine, and that separation should be mostly honored, but some data and permissions may leak between them so it should not yet be treated as a proper security boundary.</p>
        ]
      }
    ];
  }
}

CustomerAccounts.defaultProps = {
  alerts: []
}
