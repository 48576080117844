// various functions to perform common tasks


export function getColumnsFromData(data) {
    var columns = [];
    if (data.length > 0) {
        let objectKeys = Object.keys(data[0]);
        columns = objectKeys.map((key) => {
            let keyParts = key.split('_');
            let keyString = keyParts.reduce((string, part) => string + ' ' + part);
            return ({
                title: keyString,
                dataIndex: key,
                key: key
            });
        })
    }
    return columns;
}

/**
 * 
 * @param {an array of filters} filters 
 * 
 * available_filters is returned as an array of arrays
 * The filters are easier to work with as an object formatted:
 * {
 *  key: 'key',
 *  value: 'value'
 * }
 */

export function reFormatFilters(filters, filterTypes) {
    var newFiltersArray = [];
    for (let i = 0; i < filters.length; i++) {
        var filter = filters[i];
        var newFilter = {};
        var newValuesArray = [];

        if (filterTypes[filter.key]) {
            newFilter.type = filterTypes[filter.key];
        }

        newFilter.key = filter.key;

        for (let i = 0; i < filter.values.length; i++) {
            var value = filter.values[i];
            var valueObject = {};

            valueObject.key = value[0];
            valueObject.name = value[1];
            newValuesArray.push(valueObject);
        }

        newFilter.values = newValuesArray;
        newFiltersArray.push(newFilter);
    }
    return newFiltersArray;
}

export function addSortingToColumns(columns, sortKeys) {
    if (sortKeys) {
        sortKeys.forEach((sortKey) => {
            columns.find((column, i) => {
                if (column.key === sortKey) {
                    columns[i] = { ...column, sorter: true };
                    return true;
                }
            })
        })
    }
    return columns;
}

export function formatKeyValuePairs(keyValuePairs) {
    if (keyValuePairs.length > 0) {
        let keyValuePairsString = keyValuePairs.reduce((prev, current, index) => {
            let currentPairString = current.key + "=" + current.value;
            return (index === 0 ? currentPairString : prev + "," + currentPairString);
        }, '')
        return keyValuePairsString;
    } else {
        return "";
    }
}

export function parseKeyValuePairs(keyValuePairString) {
    if (keyValuePairString.length < 1) {
        return [];
    }

    return keyValuePairString.split(',').reduce((result, pairString) => {
        if (pairString !== '') {
            let pair = pairString.split('=');
            result.push({ key: pair[0], value: pair[1] });
        }
        return result;
    }, []);
}

export function formatTimeValue(momentDate) {
    return momentDate.format('HH:mm');
}

export function formatTimeValueEpoch(momentDate) {
    return momentDate.unix();
}

export function formatMilliseconds(msString) {
    if (msString) {
        let date = new Date(msString);
        // console.log('date', date);
        return date.getFullYear() + '/' +("0" + (date.getMonth() + 1)).slice(-2)+ '/' + ("0" + date.getDate()).slice(-2) + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2);
    }
}

export function formatTimestampSeconds(msString) {
    if (msString) {
        let date = new Date(msString * 1000);
        // console.log('date', date);
        return date.getFullYear() + '/' +("0" + (date.getMonth() + 1)).slice(-2)+ '/' + ("0" + date.getDate()).slice(-2) + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2);
    }
}

export function formatTimestampSecondsToTime(msString) {
    if (msString) {
        let date = new Date(msString * 1000);
        console.log('date', date);
        return date.getHours() + ':' + date.getMinutes();
    }
}