import React from 'react';

const IconArrowRight = props => {
  const { width, height } = props;

  const arrowRightActive = (
    <svg className="IconArrowRight gls-icon" width={width || "11px"} height={height || "18px"} viewBox="0 0 11 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-390.000000, -5288.000000)">
          <g id="arrowactive" transform="translate(395.000000, 5297.000000) rotate(-180.000000) translate(-395.000000, -5297.000000) translate(371.000000, 5273.000000)">
            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
            <polygon id="arrow_down-copy-4" fill="#0C1B2E" fillRule="nonzero" transform="translate(23.500000, 24.000000) rotate(-180.000000) translate(-23.500000, -24.000000) " points="20.0810811 15 18 17.1 24.8378378 24 18 30.9 20.0810811 33 29 24"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
  const arrowRightInactive = (
    <svg className="IconArrowRight IconArrowRight--inactive gls-icon" width={width || "11px"} height={height || "18px"} viewBox="0 0 11 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="GLS_Desktop-Components" transform="translate(-390.000000, -5288.000000)">
          <g id="arrowactive" transform="translate(395.000000, 5297.000000) rotate(-180.000000) translate(-395.000000, -5297.000000) translate(371.000000, 5273.000000)">
            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
            <polygon id="arrow_down-copy-4" fill="#A5B7D0" fillRule="nonzero" transform="translate(23.500000, 24.000000) rotate(-180.000000) translate(-23.500000, -24.000000) " points="20.0810811 15 18 17.1 24.8378378 24 18 30.9 20.0810811 33 29 24"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );

  return props.inactive ? arrowRightInactive : arrowRightActive;
};

export default IconArrowRight;