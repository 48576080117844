import React from 'react';
import { Button, Form, Input, Checkbox, message } from 'antd';
import { Link } from 'react-router-dom';
import BackLink from '../components/BackLink';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';

class ProjectsAdd extends React.Component {

  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : 57); // TEMP MOCK ID

    this.state = {
      resource: {},
      resourceId
    }
  }
  render() {

    const { actionType } = this.props;
    this.apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['project'].create
      : GLSAPI.endpoints['project'].edit;

    return (
      <div className="ProjectsAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">Add Project</h1>

          <WrappedDataForm
            title=""
            apiFunction={GLSAPI.endpoints['project'].create}
            apiResource="project"
            onSubmitCallback={this.onSubmitCallback}
            hideRequiredMark
            onErrorCallback={this.onErrorCallback}
          >
            {this.addProjectForm}
          </WrappedDataForm>

          <section className="view-section view-section--no-border">
            <p className="align-center"><Link to="/glsmon/projects/browse-public" className="bold">Browse Public Projects</Link></p>
          </section>

        </div>
      </div>
    );
  }

  onSubmitCallback = project => {
    this.props.history.push('/glsmon/projects/' + project.id);
  }

  getCheckedValue = (e) => {
    return e.target.checked ? 1 : 0;
  };

  onErrorCallback = (error) => {
    if (error.message === "Name already exists") {
      message.destroy();
      message.error('Name already exists.');
    }
  }


  addProjectForm = (defaultFormLayout) => (
    <div>
      <div className="view-section view-section--no-border">
        <Form.Item
          label={<span><span>Project Name</span><span className="form-item-label-description">Limited [-\w./] character set for now: This is still used unfiltered in filesystem paths.</span></span>} {...defaultFormLayout}
          name="name"
          rules={[{
            required: true, message: 'Project Name is required and spaces are not allowed', pattern: /^[\w./-]+$/,
            validator: (_, value) => {
              if (!value || value.trim().length === 0) {
                return Promise.reject(new Error('Project name cannot be empty'));
              }
              if (/\s/.test(value)) {
                return Promise.reject(new Error('Spaces are not allowed in the Project name'));
              }
              return Promise.resolve();
            },
          }]}
        >
          <Input placeholder="Project Name" />
        </Form.Item>

        <Form.Item label="Is Public" {...defaultFormLayout}
          name="is_public"
          initialValue={1}
          valuePropName="checked"
          getValueFromEvent={this.getCheckedValue}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  )
}

export default ProjectsAdd;
