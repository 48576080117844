import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'antd';

export const metricAckColumns = [
  {
    title: "Host",
    key: "hosts",
    render: (text, record) => {
      let name = '';
      if (record && record.hosts[0]) {
        name = record.hosts ? record.hosts[0].name : '';
      }
      return (
        <Link to={`/glsmon/hosts/${record.hosts[0].id}`} className="underline">
          {name}
        </Link>
      );
    }
  },
  {
    title: "Acked",
    key: "metric_state_type_ids",
    render: (text, record) => {
      return record.metric_state_type_ids
        ? <span className="positive">Acked</span>
        : <span className="danger">Not Acked</span>
    }
  },
  {
    title: "Metric",
    key: "metric",
    dataIndex: "metric",
    render: (text, record) => (record.metric.name)
  },
  {
    title: "Contact Addresses",
    key: "contact",
    dataIndex: "is_global",
    render: (is_global, record) => {
      if (is_global == 1) {
        return "All Contacts"
      }
      else {
        let records = record.contact.slice(0, 2);
        if (record.contact.length > 2) records.push({ name: `and ${record.contact.length - 2} more`, id: 'extra-contact' });
        return records.map(record => <div key={record.id}>{record.name}</div>);
      }
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          console.log(record);
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];

export const metricAckColumnsCondensed = [
  {
    title: "Host",
    key: "hosts",
    render: (text, record) => {
      let name = '';
      if (record && record.hosts[0]) {
        name = record.hosts ? record.hosts[0].name : '';
      }
      return (
        <Link to={`/glsmon/hosts/${record.hosts[0].id}`} className="underline">
          {name}
        </Link>
      );
    }
  },
  {
    title: "Acked",
    key: "metric_state_type_ids",
    render: (text, record) => {
      return record.metric_state_type_ids
        ? <span className="positive">Acked</span>
        : <span className="danger">Not Acked</span>
    }
  },
  {
    title: "Metric",
    key: "metric",
    dataIndex: "metric",
    render: (text, record) => (record.metric.name)
  },
  {
    title: "Contact Addresses",
    key: "contact",
    dataIndex: "is_global",
    render: (is_global, record) => {
      if (is_global == 1) {
        return "All Contacts"
      }
      else {
        let records = record.contact.slice(0, 2);
        if (record.contact.length > 2) records.push({ name: `and ${record.contact.length - 2} more`, id: 'extra-contact' });
        return records.map(record => <div key={record.id}>{record.name}</div>);
      }
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {

          if (!action.enabled(record)) { return null; }

          return (
            <span key={`${record.id}-${action.name}`}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const metricAckActions = (history) => {
  return [
    {
      name: "Edit",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/metrics/${record.metric.id}/ack/${record.id}`);
      },
      enabled: (record) => {
        return true
      }
    },
    {
      name: "Delete",
      type: "primary",
      func: (record) => {
        // history.push(`/glsmon/hostgroups/${record.id}`);
        console.log(record);
        var r = window.confirm(`Are you sure you want to delete this metricack id : ${record.id} `);
        if (r == true) {
          var obj = { type: record.type, metricack_id: record.id };
          const options = {
            headers: { "Access-Control-Allow-Origin": "*" }
          };
          axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
            .then(response => {
              // this.setState({ customer: response.data });
              console.log(response);
              window.location.reload();
            })
            .catch(error => {
              alert("Data is not Sucessfully posted");
              window.location.reload();
            })
        }
      },
      enabled: (record) => {
        return true
      }
    }
  ];
}

export const metricackAction = (history) => {
  return [
    {
      name: "Edit",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/metrics/${record.metric.id}/ack/${record.id}`);
      },
      enabled: (record) => {
        return true
      }
    },
  ];
}
