import React from 'react';
import { Button, Form, Select, Input, message, Col, Row } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import BackLink from '../components/BackLink';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';

class ProjectsCheckAdd extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.nextOutputFieldId = 1;
    this.nextThresholdFieldId = 1;

    let parentProjectId = (props.match ? props.match.params.id : null);
    let resourceId = (props.match ? props.match.params.resource_id : null);

    this.state = {
      resource: {},
      parentProjectId,
      resourceId: resourceId,
      availableFiles: [],
      outputFields: [],
      thresholdFields: [],
      loading: true
    }

    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['check'].create
      : GLSAPI.endpoints['check'].edit
  }

  selectApiFunction() {
    return this.props.actionType === 'create' ? GLSAPI.endpoints['check'].create : GLSAPI.endpoints['check'].edit;
  }

  componentDidMount(props) {


    if (this.state.resourceId) {
      GLSAPI.endpoints['check'].getOne({ id: this.state.resourceId })
        .then((response) => {
          let resource = response;
          let outputFields = resource.check_output_fields.map((field, index) => ({ id: index, ...field }));
          let thresholdFields = resource.default_thresholds.map((field, index) => ({ id: index, ...field }));
          this.setState({
            resource,
            outputFields: outputFields.concat(this.state.outputFields),
            thresholdFields,
            loading: false
          }, () => {
            this.formRef.current.setFieldsValue({
              id: this.state.resource.id,
              default_thresholds: this.state.thresholdFields,
              output_fields: this.state.outputFields,
            })
          });
        }).catch((error) => {
          this.setState({
            loading: false
          })
        });
    }

    GLSAPI.endpoints['file'].getAll({ project: this.state.parentProjectId })
      .then((response) => {
        let availableFiles = response.objects.map((item) => { return { ...item, key: item.id } });
        this.setState({
          availableFiles,
          loading: false
        });
      }).catch((error) => {
        message.destroy();
        message.error('Error fetching files');
        this.setState({
          loading: false
        })
      });
  }

  render() {
    if (this.state.loading) return null;
    let resource = this.state.resource;
    return (
      <div className="ProjectsCheckAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />

          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} Check</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.selectApiFunction()}
            apiResource="check"
            onSubmitCallback={this.onSubmitCallback}
            onErrorCallback={this.onErrorCallback}
            hideRequiredMark
            verbs={this.getVerbs()}
            initialValues={{
              id: resource.id,
              project_id: this.state.parentProjectId,
              project_file_id: this.selectProjectFile(),
              name: resource.name,
              interpreter: resource.interpreter,
              check_output_fields: this.state.outputFields,
              default_thresholds: this.state.default_thresholds
            }}
            formRef={this.formRef}
          >
            {this.addCheckForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  addField = () => {
    const fields = this.state.outputFields;
    const newFields = fields.concat({ id: this.nextOutputFieldId, name: "" });
    this.setState({
      outputFields: newFields
    })
    this.nextOutputFieldId++;
  }

  addThresholdField = () => {
    const fields = this.state.thresholdFields;
    const newFields = fields.concat({ id: this.nextThresholdFieldId, name: "" });
    this.setState({
      thresholdFields: newFields
    })
    this.nextThresholdFieldId++;
  }

  removeField = (removeId) => {
    const fields = this.state.outputFields;
    const newFields = fields.filter(field => field.id !== removeId);
    this.setState({
      outputFields: newFields
    });
  }

  removeThresholdField = (removeId) => {
    const thresholdFields = this.state.thresholdFields;
    const newThresholdFields = thresholdFields.filter(field => field.id !== removeId);
    this.setState({
      thresholdFields: newThresholdFields
    });
  }

  onSubmitCallback = (check) => {
    this.props.history.push(`/glsmon/projects/${this.state.parentProjectId}/checks`);
  }

  onErrorCallback = (error) => {
    if (error.message === "Check already exists for the project") {
      message.destroy();
      message.error('Check Name already exists for the project. Please try a different name.');
    }
  }

  selectProjectFile = () => {
    if (this.state.resource.project_file_id) {
      return this.state.resource.project_file_id;
    } else if (this.state.availableFiles.length) {
      return this.state.availableFiles[0].id;
    }
    return null;
  }

  disableOutputFields = () => {
    return this.props.actionType === 'edit' ? true : false;
  }


  addCheckForm = (defaultFormLayout) => (
    <div className="ProjectsCheckAdd">
      <div className="view-section view-section--no-border">
        {this.state.resourceId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id">
            <Input type="hidden" />
          </Form.Item>
        }
        {this.state.parentProjectId &&
          <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="project_id">
            <Input type="hidden" />
          </Form.Item>
        }

        <Form.Item label="Project file" {...defaultFormLayout}
          name="project_file_id"
          rules={[{ required: true, message: 'A project file is required' }]}
        >
          <Select>
            {
              this.state.availableFiles.map((file) => <Select.Option value={file.id} key={file.id}>{file.name}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item label={<span><span>Check Name</span><span className="form-item-label-description">(Leave blank to default to the file path selected above)</span></span>} {...defaultFormLayout}
          name="name"
          rules={[{ required: true, message: 'Check Name is required' }]}
        >
          <Input placeholder="Check Name" />
        </Form.Item>

        <Form.Item label={<span><span>Interpreter</span><span className="form-item-label-description">Optionally define an external program to be used to run the check script. Any value in this field will be injected into the query command line directly before the check script path.</span></span>} {...defaultFormLayout}
          name="interpreter"
        >
          <Input />
        </Form.Item>

        <Form.Item label={<span><span>Output Fields</span><span className="form-item-label-description">Define fields that may be reported by this check for use in state tresholds below.</span></span>} {...defaultFormLayout}>
          {
            this.state.outputFields.length === 0 && this.props.actionType === 'create' ?
              <Col span={4}>
                <div>
                  <Button type="default" onClick={this.addField}>Add Row</Button>
                </div>
              </Col>
              : null
          }
          {
            this.state.outputFields.map((field, index) => {
              return (
                <div key={field.id}>
                  <Row gutter={5}>
                    <Col span={8}>
                      <Form.Item name={['check_output_fields', index, 'name']}>
                        <Input
                          placeholder="Name"
                          disabled={this.disableOutputFields()}
                        // className="dynamic-input dynamic-input--left"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={['check_output_fields', index, 'unit']}>
                        <Input
                          placeholder="Unit" unit="unit"
                          disabled={this.disableOutputFields()}
                        // className="dynamic-input dynamic-input--left"
                        />
                      </Form.Item>
                    </Col>

                    {index >= 1 ?
                      <Col span={4} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} >
                        <MinusCircleOutlined className="dynamic-delete-button" type="minus-circle-o" style={{ marginLeft: '10px', marginBottom: '15px' }} onClick={() => this.removeField(field.id)} />
                      </Col>
                      : null}
                  </Row>
                  {this.state.outputFields.length === (index + 1) && this.props.actionType === 'create' ? <Button type="default" onClick={this.addField}>Add Row</Button> : null}
                </div>
              );
            })
          }
        </Form.Item>

        {
          this.props.actionType === 'edit' &&

          <Form.Item label={<span><span>Default Thresholds</span><span className="form-item-label-description"
            name="default_thresholds"
          >If defined, incoming metric results are evaluated against these conditions in order from top to bottom. The first to be matched will decide the metric state. If none match, the metric is considered "Ok".</span></span>} {...defaultFormLayout}>


            {
              this.state.thresholdFields.length === 0 ?
                <Col span={4}>
                  <div>
                    <Button type="default" onClick={this.addThresholdField}>Add Row</Button>
                  </div>
                </Col>
                : null
            }
            {

              this.state.thresholdFields.map((field, index) => {
                return (
                  <div key={field.id}>
                    <Row gutter={5}>
                      <Col span={6}>
                        <Form.Item name={['default_thresholds', index, 'metric_state_type_id']}
                        >
                          <Select
                            // className="form-item--inline"
                            style={{ marginRight: 10 }}
                          // className="form-item"
                          >
                            <Select.Option value={3} key={3}>Ok</Select.Option>
                            <Select.Option value={4} key={4}>Critical</Select.Option>
                            <Select.Option value={5} key={5}>Warning</Select.Option>
                            <Select.Option value={6} key={6}>Unknown</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item name={['default_thresholds', index, 'check_output_field_id']}
                        >
                          <Select
                          // className="form-item--inline"
                          >
                            {
                              this.state.outputFields.map((outputField, index) => {
                                return <Select.Option key={index} value={outputField.id}>{outputField.unit}</Select.Option>;
                              })
                            }

                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item name={['default_thresholds', index, 'compare_operator']}
                        >
                          <Select>
                            <Select.Option value={"<"}>{"<"}</Select.Option>
                            <Select.Option value={"<="}>{"<="}</Select.Option>
                            <Select.Option value={"=="}>{"=="}</Select.Option>
                            < Select.Option value={"!="}>{"!="}</Select.Option>
                            <Select.Option value={">="}>{">="}</Select.Option>
                            <Select.Option value={">"}>{">"}</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={['default_thresholds', index, 'threshold_value']}
                        >
                          <Input placeholder={0}
                            // className="form-item--inline" 
                            style={{ paddingBottom: 2 }}
                          />
                        </Form.Item>
                      </Col>

                      {index >= 1 ?
                        <Col span={4} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }} >
                          <MinusCircleOutlined style={{ marginLeft: '5px', marginBottom: '15px' }} className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeThresholdField(field.id)} />
                        </Col>
                        : null

                      }
                    </Row>
                    {
                      this.state.thresholdFields.length === (index + 1) ?
                        <Col span={4}>
                          <div>
                            <Button type="default" onClick={this.addThresholdField}>Add Row</Button>
                          </div>
                        </Col>
                        : null
                    }

                  </div>
                );
              })
            }
          </Form.Item>
        }
      </div>

      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
      </div>
    </div>
  )
}

export default ProjectsCheckAdd;
