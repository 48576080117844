import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import IconPositive from '../../theming/icons/IconPositive';
import IconError from '../../theming/icons/IconError';
import IconWarning from '../../theming/icons/IconWarning';
import IconUnknown from '../../theming/icons/IconUnknown';
import { formatTimestampSeconds } from '../../helpers';

export const notificationColumns = [
  {
    title: "Metric",
    key: "metric",
    render: (text, record) => <Link to={"/glsmon/metrics/" + record.metric.id} className="underline">{record.metric.name}</Link>
  },
  {
    title: "Status",
    key: "status",
    render: (text, record) => {
      switch (record.metric_state_type.name) {
        case "Ok": return <span className="inline-icon positive"><IconPositive /> Ok</span>;
        case "Host Up": return <span className="inline-icon positive"><IconPositive /> Ok</span>;
        case "Host Down": return <span className="inline-icon danger"><IconError /> Host Down</span>;
        case "Critical": return <span className="inline-icon danger"><IconError /> Critical</span>;
        case "Warning": return <span className="inline-icon warning"><IconWarning /> Warning</span>;
        case "Stale": return <span>Stale</span>
        default: return <span className="inline-icon"><IconUnknown /> Unknown</span>;
      }
    }
  },
  {
    title: "Contact Address",
    key: "contact_address",
    dataIndex: "contact_address",
    render: (text, record) => (record.contact_address.name)
  },
  {
    title: "Subject",
    key: "subject",
    dataIndex: "subject",
    render: (text, record) => (record.subject)
  },
  // {
  //   title: "Acked",
  //   key: "acked",
  //   render: (text, record) => {
  //     return record.acked
  //       ? <span className="positive">Acked</span>
  //       : <span className="danger">Not Acked</span>
  //   }
  // },
  {
    title: "Time",
    key: "time",
    dataIndex: "time",
    render: (text, record) => formatTimestampSeconds(record.time)
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          console.log(record);
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const notificationColumnsCondensed = [
  {
    title: "Metric",
    key: "metric",
    render: (text, record) => <Link to={"/glsmon/metrics/" + record.metric.id} className="underline">{record.metric.name}</Link>
  },
  {
    title: "Status",
    key: "status",
    render: (text, record) => {
      switch (record.metric_state_type.name) {
        case "Ok": return <span className="inline-icon positive"><IconPositive /> Ok</span>;
        case "Host Up": return <span className="inline-icon positive"><IconPositive /> Ok</span>;
        case "Host Down": return <span className="inline-icon danger"><IconError /> Host Down</span>;
        case "Critical": return <span className="inline-icon danger"><IconError /> Critical</span>;
        case "Warning": return <span className="inline-icon warning"><IconWarning /> Warning</span>;
        case "Stale": return <span>Stale</span>
        default: return <span className="inline-icon"><IconUnknown /> Unknown</span>;
      }
    }
  },
  // {
  //   title: "Acked",
  //   key: "acked",
  //   render: (text, record) => {
  //     return record.acked
  //       ? <span className="positive">Acked</span>
  //       : <span className="danger">Not Acked</span>
  //   }
  // },
  {
    title: "Contact Address",
    key: "contact_address",
    dataIndex: "contact_address",
    render: (text, record) => (record.contact_address.name)
  },
  {
    title: "Time",
    key: "time",
    dataIndex: "time",
    render: (text, record) => formatTimestampSeconds(record.time)
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {

          if (!action.enabled(record)) { return null; }

          return (
            <span key={`${record.id}-${action.name}`}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const notificationFilterTypes = {

};

export const notificationActions = (history) => {
  return [
    {
      name: "Ack",
      type: "default",
      func: (record) => {
        history.push(`/glsmon/metrics/${record.metric.id}/ack/new`);
      },
      enabled: (record) => {
        return true
      }
    },
  ];
}
