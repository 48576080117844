import React from 'react';
import { Checkbox, Input } from 'antd';
import PropTypes from 'prop-types';

import './SearchField.scss';

class SearchField extends React.Component {

    state = {
        searchString: "",
        checked: false,
    }

    render() {
        return (
            <div className="SearchField">
                {this.props.checkable &&
                    <Checkbox onChange={(e) => {
                        let checked = e.target.checked;
                        this.setState({
                            checked
                        }, () => {
                            this.props.onChange(this.props.dataIndex, checked, this.state.searchString)
                        });
                    }}
                    />
                }
                <label>{this.props.title}:</label>
                <Input placeholder={this.props.placeholder} onChange={(e) => {
                    let value = e.target.value;
                    this.setState({
                        searchString: value
                    }, () => {
                        if (this.props.checkable) {
                            this.props.onChange(this.props.dataIndex, this.state.checked, value);
                        } else {
                            this.props.onChange(this.props.dataIndex, value);
                        }
                    });
                }} />
            </div>
        );
    }
}

SearchField.propTypes = {
    title: PropTypes.string,
    dataIndex: PropTypes.string,
    checkable: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

SearchField.defaultProps = {
    title: 'Filter key',
    checkable: true,
    placeholder: "",
    onChange: () => { }
}

export default SearchField;