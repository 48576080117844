import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatTimestampSeconds } from '../../helpers';
import axios from 'axios';

export const hostAckColumns = [
  {
    title: "Host",
    key: "host",
    render: (text, record) => <Link className="underline" to={"/glsmon/hosts/" + record.host.id}>{record.host.name}</Link>
  },
  {
    title: "Start time",
    dataIndex: "start_time",
    key: "start_time",
    render: (text, record) => <span>{formatTimestampSeconds(record.start_time)}</span>
  },
  {
    title: "End time",
    dataIndex: "end_time",
    key: "end_time",
    render: (text, record) => <span>{formatTimestampSeconds(record.end_time)}</span>
  },
  {
    title: "Contact Addresses",
    key: "contact",
    dataIndex: "is_global",
    render: (is_global, record) => {
      if (is_global == 1) {
        return "All Contacts"
      }
      else {
        let records = record.contact.slice(0, 2);
        if (record.contact.length > 2) records.push({ name: `and ${record.contact.length - 2} more`, id: 'extra-contact' });
        return records.map(record => <div key={record.id}>{record.name}</div>);
      }
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
           console.log(record);
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  }
];


export const hostAckCondensedColumns = [
  {
    title: "Host",
    key: "host",
    render: (text, record) => {
      let name = '';
      if (record.host && record.host) {
        name = record.host ? record.host.name : '';
      }
      return (
        <Link to={`/glsmon/hosts/${record.host.id}`} className="underline">
          {name}
        </Link>
      );
    }
  },
  {
    title: "Contact Addresses",
    key: "contact",
    dataIndex: "is_global",
    render: (is_global, record) => {
      if (is_global == 1) {
        return "All Contacts"
      }
      else {
        let records = record.contact.slice(0, 2);
        if (record.contact.length > 2) records.push({ name: `and ${record.contact.length - 2} more`, id: 'extra-contact' });
        return records.map(record => <div key={record.id}>{record.name}</div>);
      }
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          if (!action.enabled(record)) { return }

          return (
            <span key={record}>
              <Button
                type={action.type}
                disabled={action.disabled}
                onClick={() => action.func(record)}
              >
                {action.name}
              </Button>
            </span>
          );
        })}
      </span>
    )
  }
];

export const hostAckActions = (history) => {
  return [
    {
      name: "Edit",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/hosts/${record.host.id}/ack/${record.id}`);
      },
      enabled: (record) => {
        return true
      }
    },
    {
      name: "Delete",
      type: "primary",
      func: (record) => {
        // history.push(`/glsmon/hostgroups/${record.id}`);
        console.log(record);
        var r = window.confirm(`Are you sure you want to delete this hostack id : ${record.id} `);
        if (r == true) {
          var obj = { type: record.type, hostack_id: record.id };
          const options = {
            headers: { "Access-Control-Allow-Origin": "*" }
          };
          axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
            .then(response => {
              // this.setState({ customer: response.data });
              console.log(response);
              window.location.reload();
            })
            .catch(error => {
              alert("Data is not Sucessfully posted");
              window.location.reload();
            })
        }
      },
      enabled: (record) => {
        return true
      }
    }
  ];
}

export const hostAckAction = (history) => {
  return [
    {
      name: "Edit",
      type: "primary",
      func: (record) => {
        history.push(`/glsmon/hosts/${record.host.id}/ack/${record.id}`);
      },
      enabled: (record) => {
        return true
      }
    }
  ];
}
