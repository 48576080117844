import React from 'react';
import { Checkbox, Radio } from 'antd';

import './CheckboxInput.scss';

function CheckboxInput(props) {
  const { label, description, checkboxes, type } = props;

  const checkboxList = type === 'radio'
    ? checkboxes.map(checkbox => {
      return (
        <Radio
          defaultChecked={checkbox.defaultChecked}
          value={checkbox.value}>
          {checkbox.label}
        </Radio>
      );
    })
    : checkboxes.map(checkbox => {
      return (
        <Checkbox
          defaultChecked={checkbox.defaultChecked}
          value={checkbox.value}>
          {checkbox.label}
        </Checkbox>
      );
    });

  return (
    <div className="CheckboxInput">
      <div className="CheckboxInput-label-group">
        <label>{label}</label>
        <p className="CheckboxInput-description">{description}</p>
      </div>
      <div className="CheckboxInput-input-group">
        {checkboxList}
      </div>
    </div>
  );
}

export default CheckboxInput;