import React from 'react';
import { Select } from 'antd';

import './SelectInput.scss';

const Option = Select.Option;

function SelectInput(props) {
  const { label, description, options } = props;

  const input = options => {
    const selectOptions = options.map(option => <Option value={option.value}>{option.optionLabel}</Option>);
    return <Select style={{ width: '100%' }}>{selectOptions}</Select>;
  };

  return (
    <div className="SelectInput">
      <div className="SelectInput-label-group">
        <label>{label}</label>
        {description
          ? <p className="SelectInput-description">{description}</p>
          : null
        }
      </div>
      <div className="SelectInput-input-group">
        {input(options)}
      </div>
    </div>
  );
}

export default SelectInput;