import React from 'react';
import { Button, Form, Input } from 'antd';
import WrappedDataForm from '../components/DataForm';
import axios from 'axios';

// let rest;
class Register extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   value1: ""
    // }

    this.loadData1 = this.loadData1.bind(this);
  }

  loadData1() {
    axios
      .get("https://www.serversynergy.com/glsmon/token")
      .then(res => {
        console.log(res);
        document.getElementById('username').value = res.data;
        // this.setState({ value1: res.data });
        // rest = res.data;
      })
      .catch(error => {
        //  this.setState({
        //  errorMsg: `Error retreiving todos data. Detailed error : ${error}`
        // });
      });
  }
  componentDidMount() {
    this.loadData1();
  }
  submithandler = () => {
    var obj = { username: document.getElementById('username').value, password: document.getElementById('cpass').value };
    const options = {
      headers: { "Access-Control-Allow-Origin": "*" }
    };
    if (document.getElementById('username').value && document.getElementById('cpass').value) {
      axios.post('https://www.serversynergy.com/glsmon/register/api', JSON.stringify(obj), options)
        .then(response => {
          if (response.status === 200) {
            alert("User registered sucessfully");
            window.location = "/";
          }
        })
        .catch(() => {
          alert("That is not a valid email address");
          window.location.reload();
        })
    } else {
      alert("Enter required fields");
    }
  }

  render() {
    return (
      <div className="page-area--tabbed">

        <h1 className="header-bar">Register</h1>

        <WrappedDataForm>
          {this.addUserForm}
        </WrappedDataForm>

      </div>
    );
  }

  addUserForm = (defaultFormLayout) => (
    <div className="view-section view-section--no-border">
      <Form.Item label="Username" {...defaultFormLayout} name="username"
        // initialValue={this.state.value1}
        hasFeedback>
        <Input placeholder="Enter Your Invite email" id='username' disabled />

      </Form.Item>

      <Form.Item label="Password"
        {...defaultFormLayout}
        name="password"
        rules={[
          { required: true, message: 'A password is required' },
          { min: 8, message: "Password must be minimum 8 characters." }
        ]}
        hasFeedback

      >
        <Input.Password id='pass' />
      </Form.Item>

      <Form.Item label="Confirm Password" {...defaultFormLayout}
        name="confirm_password"
        rules={[
          { required: true, message: 'Password confirmation is required' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords must match'));
            }
          })
        ]}
        hasFeedback
      >
        <Input.Password id='cpass' />
      </Form.Item>
      <div className="view-action">
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={this.submithandler.bind(this)}>Register</Button>
        </Form.Item>
      </div>
    </div>
  );
}

export default Register;
