import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import IconEdit from '../theming/icons/IconEdit';

import './ResourceInfo.scss';

function ResourceInfo({ resource, titleKey, labelMap, resourceIcon, widgets, onTitleClick, spanWidth }) {

    let ResourceIcon = resourceIcon;

    return (
        <div className="ResourceInfo">
            <Row>
                <Col span={1}>
                    <ResourceIcon className="resource-icon" />
                </Col>
                <Col span={23}>
                    <h3 className="resource-title" onClick={() => onTitleClick(resource.id)}>{resource[titleKey]} <Icon className="edit-resource" component={IconEdit} /></h3>
                    <label>{labelMap[titleKey]}</label>
                </Col>
            </Row>
            <Row className="resource-widgets">
                {
                    Object.keys(widgets).map((widgetKey) => {
                        const widget = widgets[widgetKey];
                        return (
                            <Col span={(widget.spanWidth ? widget.spanWidth : spanWidth)} key={widgetKey}>
                                <div className={"resource-widget "}>
                                    {widget.render(resource)}
                                </div>
                            </Col>
                        );
                    })
                }
            </Row>
        </div>
    )
}


ResourceInfo.propTypes = {
    resource: PropTypes.object,
    widgets: PropTypes.object
}

ResourceInfo.defaultProps = {
    resource: {},
    titleKey: 'name',
    widgets: {},
    labelMap: {},
    spanWidth: 4
}

export default ResourceInfo;