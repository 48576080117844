import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import MetricsViewing from './MetricsViewing';
import Metricsedit from './Metricsedit';
import MetricsAck from './MetricsAck';
import MetricsAckEdit from './MetricsAckEdit';
import MetricsAckAdd from './MetricsAckAdd';
import DataWrapper from '../components/DataWrapper';
import AlertBar from '../components/AlertBar';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import axios from 'axios';
import { UserConsumer } from '../UserContext';
import { metricColumns, metricFilters as filterTypes } from '../api/schema/metric';

class Metrics extends React.Component {

  constructor(props) {
    super(props);

    this.alerts = this.defaultAlerts().concat(this.props.alerts);
    this.tableOptionsModel = { type: 'metrics', title: 'Metrics', rowsPerPage: 5, currentPage: 1 };
  }

  render() {
    let match = this.props.match;
    return (
      <div className="Metrics view">
        <UserConsumer>
          {({ currentCustomer }) => (
            <>
              <Route exact path={match.url} render={() => (
                <div className="page-area--tabbed">
                  <h1 className="header-bar">Metrics</h1>
                  <div className="page-info">
                    {this.alerts.map((alert, i) => {
                      return <AlertBar key={i} alert={alert} showIcon />
                    })}
                  </div>
                  <DataWrapper
                    pageTitle="Metrics"
                    api="metric"
                    columns={metricColumns}
                    requiredParams={{
                      customer_id: currentCustomer.id
                    }}
                    actions={[
                      {
                        name: "View",
                        type: "primary",
                        func: this.viewMetric,
                        enabled: (record) => {
                          // create a rule to determine if the action is available
                          return true
                        }
                      },
                      {
                        name: "Edit",
                        type: "primary",
                        func: this.editMetric,
                        enabled: (record) => {
                          // create a rule to determine if the action is available
                          return true
                        }
                      },
                      {
                        name: "Ack",
                        type: "default",
                        func: this.ackMetric,
                        enabled: (record) => {
                          // create a rule to determine if the action is available
                          return true
                        }
                      },
                      {
                        name: "Delete",
                        type: "primary",
                        func: this.deleteMetric,
                        enabled: (record) => {
                          return true
                        }
                      },
                    ]}
                    filterTypes={filterTypes}
                    // tableOptionsToggler={this.tableOptionsToggler} 
                    tableOptionsModel={this.tableOptionsModel}
                  >
                    {(props, state, actions) => {
                      return (
                        <div>
                          <section className="view-section filters-section filters-section--expanded">
                            <header className="view-section-header">
                              <h3 className="view-section-title">Available Filters</h3>
                              <Filter expanded expandable
                                {...props}
                                {...state}
                                {...actions}
                              />
                            </header>
                          </section>

                          <section className="view-section metrics-section">
                            <DynamicTable
                              {...props}
                              {...state}
                              {...actions}
                            />

                          </section>
                        </div>
                      )
                    }}
                  </DataWrapper>
                </div>
              )} />
              <Switch>
                <Route path={`${match.path}/ack`} component={MetricsAck} />
                <Route exact path={`${match.path}/:id`} component={MetricsViewing} />
                <Route path={`${match.path}/edit/:id`} component={Metricsedit} />
                <Route path={`${match.path}/:metricId/ack/new`} render={(props) => <MetricsAckAdd actionType="create" {...props} />} />
                <Route path={`${match.path}/:metricId/ack/:id`} render={(props) => <MetricsAckEdit actionType="edit" {...props} />} />
              </Switch>
            </>
          )}
        </UserConsumer>
      </div>
    );
  }

  defaultAlerts = () => {
    return [
      {
        type: 'info',
        content: [
          <p>Metrics are generated by running Queries on Hosts, and describe the current state of the Host and services running on it.</p>,
          <p>See also the list of Metric Events for a similar but slightly different view of current Metric states.</p>,
        ]
      }
    ];
  }

  viewMetric = (metric) => {
    this.props.history.push(`/glsmon/metrics/${metric.id}`);
  }

  editMetric = (metric) => {
    this.props.history.push(`/glsmon/metrics/edit/${metric.id}`);
  }

  ackMetric = (metric) => {
    this.props.history.push(`/glsmon/metrics/${metric.id}/ack/new`);
  }
  deleteMetric = (metric) => {
    // this.props.history.push(`/glsmon/hosts/${host.id}/ack/new`);
    console.log(metric);
    var r = window.confirm(`Are you sure you want to delete this metric id : ${metric.id} `);
    if (r == true) {
      var obj = { type: metric.type ,metric_id: metric.id };
      const options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
        .then(response => {
          // this.setState({ customer: response.data });
          console.log(response);
          window.location.reload();
        })
        .catch(error => {
          alert("Data is not Sucessfully posted");
          window.location.reload();
        })
    }
  }
}

Metrics.propTypes = {
  alerts: PropTypes.array,
}

Metrics.defaultProps = {
  alerts: []
}

export default withRouter(Metrics);

