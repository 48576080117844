// Many of the functions called here are located in the external library files
// 'graphing', 'window.d3', and 'dygraphs'. These control a lot of the functionality 
// of the graphs. This component primarily serves as an entry point for those
// libraries and to fetch the data more cleanly.

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button, InputNumber, Select, Checkbox, Spin } from 'antd';
import { apiURL as GLSAPIURL } from '../api/api_config';
import './DataGraph.scss';
import './graphing/dygraph.css';
import axios from 'axios';

const Option = Select.Option;

class DataGraph extends React.Component {

  state = {
    loading: true,
    wideGraphs: true,
    graph_set_object: {},
    pointsPerReq: 20,
    dataLoaded: false,
    graphDurationCount: 1,
    graphDurationUnit: 'weeks',
  }


  constructor(props) {
    super(props);
    this.host_name = props.resource.host_name;
    this.query_name = props.resource.query_name;
    this.graphContainerId = props.graphId + "-container";
    this.graphDataUrl = GLSAPIURL + '/field_history_data/' + props.resource.id;
    this.graph = this.graph.bind(this);
  }

  componentDidMount() {
    this.graph();
    const graphingScript = document.createElement('script');
    graphingScript.src = "/js/libs/graphing.js";
    graphingScript.async = true;
    graphingScript.onload = () => this.scriptsLoaded('graphing');

    const d3Script = document.createElement('script');
    d3Script.src = "/js/libs/d3.min.js";
    d3Script.async = true;
    d3Script.onload = () => this.scriptsLoaded('d3');

    const dyGraphScript = document.createElement('script');
    dyGraphScript.src = "/js/libs/dygraph.js";
    dyGraphScript.async = true;
    dyGraphScript.onload = () => this.scriptsLoaded('dygraph');

    document.body.appendChild(graphingScript);
    document.body.appendChild(d3Script);
    document.body.appendChild(dyGraphScript);
  }

  scriptsLoaded() {
  }
  graph() {
    axios
      .get(this.graphDataUrl)
      .then(res => {
        let graph_field_names = [
          {
            "field_name": "Run Duration",
            "field_unit": "seconds",
            "host_name": this.host_name,
            "metric_field_name": "Run Duration",
            "metric_name": "",
            "query_name": this.query_name,
            "uuid": res.data.graph_data[1][0]
          }
        ];

        window.points_per_req = 20;
        window.graph_set_object = window.make_graph_set(
          this.graphContainerId,
          this.props.graphId,
          graph_field_names,
          this.graphDataUrl
        );

        if (window.graph_set_object.set_opts.show_range_selector) {
          window.d3.selectAll('.gls-dygraph-set-container-inner').classed('with-subchart', true);
        }

        this.setState({ dataLoaded: true, loading: false });
      }).catch((error) => {
        this.setState({
          loading: false
        })
      })
  }
  render() {
    return (
      <div id={this.graphContainerId} className='DataGraph gls-dygraph-set-container-outer'>
        {this.loading()}
        {this.controlBar()}
        <div id={this.graphContainerId + '-graphs'} className='gls-dygraph-set-container-inner'></div>
      </div>
    );
  }

  controlBar = () => {
    if (this.state.dataLoaded) {
      return (
        <Row className='graph-control-container'>
          <div className='graph-control'>
            <Button type="default" onClick={window.graph_set_object.load_zoomed_details}> Load Zoomed Details </Button>
            <Button type="default" onClick={this.load_recent_data} value='Load Recent'>Load Recent</Button>
          </div>
          <div className='graph-control'>
            <InputNumber
              className='graph-duration-count'
              min={1}
              defaultValue={this.state.graphDurationCount}
              onChange={(value) => {
                this.setState({ graphDurationCount: value });
              }}
            />
            <Select
              className='graph-duration-unit'
              defaultValue={this.state.graphDurationUnit}
              onChange={(value) => {
                this.setState({ graphDurationUnit: value });
              }}
            >
              <Option value='hours'>Hours</Option>
              <Option value='days'>Days</Option>
              <Option value='weeks'>Weeks</Option>
              <Option value='months'>Months</Option>
              <Option value='years'>Years</Option>
            </Select>
          </div>

          <div className='graph-control'>
            <Checkbox id='graph-control-range_selector' onClick={window.graph_set_object.toggle_all_subcharts} defaultChecked>Show Range Selector</Checkbox>
          </div>

          <div className='graph-control'>
            <Checkbox type='checkbox' id='graph-control-graph_width' defaultChecked={this.state.wideGraphs ? true : false} onClick={this.toggle_graph_width}>Wide Graphs</Checkbox>
          </div>

          <div className='graph-control'>
            <Checkbox type='checkbox' id='graph-control-y_axis_scale' onClick={window.graph_set_object.toggle_y_axis_scale}>Fit Y-Axis</Checkbox>
          </div>
        </Row>
      )
    }
  }

  loading = () => {
    if (this.state.loading) {
      return (
        <Spin />
      )
    }
  }

  load_recent_data = () => {
    var count = this.state.graphDurationCount;
    var unit = this.state.graphDurationUnit;
    var unit_minutes = {
      hours: 60,
      days: 60 * 24,
      weeks: 60 * 24 * 7,
      months: 60 * 24 * 31,
      years: 60 * 24 * 366,
    }
    var minutes = count * unit_minutes[unit];

    window.graph_set_object.zoom_to_last(minutes);
  }


  toggle_graph_width = () => {
    // Toggle state var
    let wideGraphs = this.state.wideGraphs;
    wideGraphs = !wideGraphs;

    // Adjust CSS
    if (wideGraphs) {
      window.window.d3.selectAll('.gls-dygraph-set-container-inner').classed('wide-graphs', true);
      window.window.d3.selectAll('.gls-dygraph-set-container-inner').classed('narrow-graphs', false);
    }
    else {
      window.d3.selectAll('.gls-dygraph-set-container-inner').classed('wide-graphs', false);
      window.d3.selectAll('.gls-dygraph-set-container-inner').classed('narrow-graphs', true);

    }

    // Tell underlying graph objects to change their size.
    window.graph_set_object.for_all_graphs(function (g) { g.dygraphs_object.resize() });

    this.setState({ wideGraphs });
  }

  toggle_all_subcharts = () => {
    window.graph_set_object.set_opts.show_range_selector = !window.graph_set_object.set_opts.show_range_selector;

    window.d3.selectAll('.gls-dygraph-set-container-inner').classed('with-subchart', window.graph_set_object.set_opts.show_range_selector);

    // Manual forEach instead of for_all_graphs wrapper intentional - see setTimeout comment below.
    // Hmm, but toggle_graph_width resizes without this and it seems fine. Not sure what's going on.
    this.graph_set_object.set_ctx.doing_callback = true;
    this.graph_set_object.all_graphs.forEach(function (g) {
      g.dygraphs_object.updateOptions({
        showRangeSelector: this.graph_set_object.set_opts.show_range_selector,
        interactionModel: window.Dygraph.defaultInteractionModel,
      }, true); // true to skip redraw since we resize just below
      g.dygraphs_object.resize();
    });

    // For some reason doing this instantly causes each graph to zoom_all every other graph,
    // I'm guessing resize() is async and returns immediately.
    setTimeout(function () {
      window.graph_set_object.set_ctx.doing_callback = false;
    }, 50); // Waiting 50ms seems plenty for resize() to finish and shouldn't interfere with further user interaction.
  }


  toggle_y_axis_scale = () => {
    window.graph_set_object.set_opts.static_y_axis_scale = !window.graph_set_object.set_opts.static_y_axis_scale;

    window.graph_set_object.for_all_graphs(function (g) {
      if (window.graph_set_object.set_opts.static_y_axis_scale) {
        g.enforce_y_axis_range(g.dygraphs_object);
      }
      else {
        g.dygraphs_object.updateOptions({
          valueRange: null,
        });
      }
    });
  }


}

DataGraph.propTypes = {
  resource: PropTypes.object,
  resourceType: PropTypes.string,
  resourceApi: PropTypes.string,
  graphId: PropTypes.string,
  graphDataUrl: PropTypes.string,
}

export default DataGraph;

