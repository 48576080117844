import React from 'react';
import DataWrapper from '../components/DataWrapper';
import Filter from '../components/Filter';
import DynamicTable from '../components/DynamicTable';
import IconAdd from '../theming/icons/IconAdd';
import { projectColumns, projectFilterTypes as filterTypes } from '../api/schema/project';

class HostsEditProjects extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);

    this.tableOptionsLinks = [{ icon: <IconAdd />, linkText: 'Add Project', href: '/glsmon/projects/new' }];
    this.tableOptionsModel = {
      type: 'projects',
      title: 'Projects',
      rowsPerPage: 5,
      rowCount: 6,
      currentPage: 1
    };

    this.panelHeader = item => <a href="/" className="underline">{item.name}</a>;
  }

  render() {
    return (
      <div className="HostsEditProjects nested-tab-view">
        <DataWrapper
          api="project"
          requiredParams={{ host: this.props.hostId }}
          columns={projectColumns}
          filterTypes={filterTypes}
          tableOptionsLinks={this.tableOptionsLinks}
          tableOptionsModel={this.tableOptionsModel}
          panelHeader={this.panelHeader}
          actions={[
            {
              name: "View",
              type: "primary",
              func: this.viewProject,
              enabled: (record) => {
                // create a rule to determine if the action is available
                return true
              }
            }
          ]}
        >
          {
            (props, state, actions) => (
              <div>
                <div className="view-section view-section--no-border filters-section">
                  <header className="view-section-header">
                    <h3 className="view-section-title">Available Filters</h3>
                    <Filter expanded expandable
                      {...state}
                      {...props}
                      {...actions}
                    />
                  </header>
                </div>

                <div className="view-section">
                  <DynamicTable
                    {...state}
                    {...props}
                    {...actions}
                  />
                </div>
              </div>
            )
          }

        </DataWrapper>
      </div>
    )
  }

  viewProject = (project) => {
    this.props.history.push(`/glsmon/projects/${project.id}`);
  }
}

export default HostsEditProjects;
