import React from 'react';
import { Button, Input, InputNumber, Form } from 'antd';
import GLSAPI from '../api/api_config';
import WrappedDataForm from '../components/DataForm';
import BackLink from '../components/BackLink';

class RegisterPinAdd extends React.Component {
  constructor(props) {
    super(props);

    let resourceId = (props.match ? props.match.params.id : 0); // TEMP MOCK ID

    this.state = {
      resource: {},
      fetched: this.props.actionType === 'create',
      resourceId: resourceId,
      loading: true
    }
    this.apiFunction = this.props.actionType === 'create'
      ? GLSAPI.endpoints['registerpin'].create
      : GLSAPI.endpoints['registerpin'].edit;
  }

  componentDidMount() {
    if (this.state.resourceId) {
      GLSAPI.endpoints['registerpin'].getOne({ id: this.state.resourceId })
        .then((response) => {
          this.setState({
            loading: false,
            fetched: true,
            resource: response,
            resourceId: response.id,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            fetched: false
          })
        });
    }
  }


  render() {
    const { actionType } = this.props;
    const apiFunction = actionType === 'create'
      ? GLSAPI.endpoints['registerpin'].create
      : GLSAPI.endpoints['registerpin'].edit;

    return (
      <div className="RegisterPinAdd view">
        <div className="page-area--tabbed">

          <BackLink {...this.props} />

          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} Pin</h1>

          <WrappedDataForm
            title=""
            apiFunction={apiFunction}
            apiResource="registerpin"
            onSubmitCallback={this.onSubmitCallback}
            hideRequiredMark
            verbs={this.getVerbs()}
          >
            {this.addregisterpinForm}
          </WrappedDataForm>

        </div>
      </div>
    );
  }

  getVerbs = () => {
    if (this.props.actionType === 'create') {
      return ["Creating", "Created"];
    }
    return ["Updating", "Updated"];
  }

  onSubmitCallback = (registerpin) => {
    this.props.history.push('/glsmon/registerpin');
  }

  addregisterpinForm = (defaultFormItemLayout) => {
    if (!this.state.fetched) return null;
    return (
      <div>
        <div className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultFormItemLayout} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }

          <Form.Item label={<span><span>PIN</span><span className="form-item-label-description"></span></span>}
            {...defaultFormItemLayout}
            name="pin"
            initialValue={this.state.resource.pin}
          >
            <Input placeholder={"PIN"} disabled />
          </Form.Item>

          <Form.Item label="Max Installs" {...defaultFormItemLayout}
            name="max_installs"
            initialValue={this.state.resource.max_installs}
            rules={[{ required: true, message: 'Max Installs is required' }]}
          >
            <InputNumber />
          </Form.Item>
        </div>
        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  }
}

export default RegisterPinAdd;
