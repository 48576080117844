import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'antd';

export const contactAddressColumns = [
  {
    title: "Contact Name",
    key: "contact_name",
    render: (text, record) => record && <Link to={`/glsmon/notifications/contacts/${record.id}`}>{record.name}</Link>
  },
  {
    title: "Address",
    key: "address",
    dataIndex: "address"
  },
  {
    title: "Notify Method",
    key: "notify_method",
    dataIndex: "notify_method",
    render: (text, record) => record && record.contact_method_type.name
  },
  {
    title: "Enabled",
    key: "enabled",
    render: (text, record) => record && (record.is_enabled === 1 ? "Yes" : "No")
  },
  {
    title: "Contact Nodes",
    key: "contact_nodes",
    render: (text, record) => {
      let nodes = record.contact_nodes.slice(0, 4);

      if (record.contact_nodes.length > 5) {
        nodes.push({ id: `${record.id}-more-rows`, name: `. . . ${record.contact_nodes.length - 5} more` });
      }

      return (
        <div>
          {
            nodes.map(node => <div key={node.id}>{node.name}</div>)
          }
        </div>
      )
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        {record.actions.length > 0 && record.actions.map((action, i) => {
          console.log(record);
          if (!action.enabled(record)) { return null; }
          return (
            <span key={`${record.id}-${action.name}`}>
              {action.name == "Delete" ?
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                  danger
                >
                  {action.name}
                </Button> :
                <Button
                  type={action.type}
                  disabled={action.disabled}
                  onClick={() => action.func(record)}
                >
                  {action.name}
                </Button>
              }
            </span>
          );
        })}
      </span>
    )
  },
]

export const contactAddressActions = (history) => {
  return [
    {
      name: "Edit",
      type: "primary",
      func: (record) => history.push(`/glsmon/notifications/contacts/${record.id}`),
      enabled: (record) => {
        return true
      }
    },
    {
      name: "Delete",
      type: "primary",
      func: (record) => {
        // history.push(`/glsmon/hostgroups/${record.id}`);
        console.log(record);
        var r = window.confirm(`Are you sure you want to delete this contactaddress id : ${record.id} `);
        if (r == true) {
          var obj = { type: record.type, contactaddress_id: record.id };
          const options = {
            headers: { "Access-Control-Allow-Origin": "*" }
          };
          axios.post('/glsmon/hosts/delete', JSON.stringify(obj), options)
            .then(response => {
              // this.setState({ customer: response.data });
              console.log(response);
              window.location.reload();
            })
            .catch(error => {
              alert("Data is not Sucessfully posted");
              window.location.reload();
            })
        }
      },
      enabled: (record) => {
        return true
      }
    }
  ]
}
