import React from 'react';
import { Input, Button, Checkbox, Select, Form, InputNumber, message } from 'antd';
import GLSAPI from '../api/api_config';
import BackLink from '../components/BackLink';
import WrappedDataForm from '../components/DataForm';

const metricStateOptions = [
  { value: '0', label: 'Host Down' },
  { value: '1', label: 'Stale' },
  { value: '2', label: 'Warning' },
  { value: '3', label: 'Critical' },
  { value: '4', label: 'Unknown' }
];

class ContactNodeAdd extends React.Component {
  constructor(props) {
    super(props);
    let resourceId = (props.match ? props.match.params.id : 57); // TEMP MOCK ID

    this.state = {
      resource: {},
      resourceId: resourceId,
      selectedPrevNodes: [],
      availableCustomers: [],
      availableHosts: [],
      availableQueries: [],
      availableMetrics: [],
      availableContactAddresses: [],
      availableTimePeriods: [],
      fetched: false,
      loading: true
    }

    this.apiFunction = props.actionType === 'create'
      ? GLSAPI.endpoints['contactnode'].create
      : GLSAPI.endpoints['contactnode'].edit;

  }

  componentDidMount() {

    if (this.props.actionType === "edit") {
      GLSAPI.endpoints['contactnode'].getOne({ id: this.state.resourceId })
        .then((response) => {
          this.setState({
            loading: false,
            resource: response,
            fetched: true
          });
        }).catch((error) => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({ fetched: true });
    }

    GLSAPI.endpoints['customer'].getAllPages()
      .then((response) => {
        this.setState({
          loading: false,
          availableCustomers: response.map((item) => { return { ...item, key: item.id } })
        });
      }).catch((error) => {
      });

    GLSAPI.endpoints['query'].getAllPages()
      .then((response) => {
        this.setState({
          loading: false,
          availableQueries: response.map((item, i) => { return { ...item, key: item.id } }),
        });
      }).catch((error) => {
      });

    GLSAPI.endpoints['host'].getAllPages()
      .then((response) => {
        this.setState({
          loading: false,
          availableHosts: response.map((item) => { return { ...item, key: item.id } })
        });
      }).catch((error) => {
      });

    GLSAPI.endpoints['timeperiod'].getAllPages()
      .then((response) => {
        this.setState({
          loading: false,
          availableTimePeriods: response.map((item) => { return { ...item, key: item.id } }),
        });
      }).catch((error) => {
        this.setState({
          loading: false,
          availableTimePeriods: [{ id: 1, name: 'Fake time period' }]
        });
      });

    GLSAPI.endpoints['metric'].getAllPages()
      .then((response) => {
        this.setState({
          loading: false,
          availableMetrics: response.map((item) => { return { ...item, key: item.id } }),
        });
      }).catch((error) => {
      });

    GLSAPI.endpoints['contactaddress'].getAllPages()
      .then((response) => {
        this.setState({
          loading: false,
          availableContactAddresses: response.map((item) => { return { ...item, key: item.id } }),
        });
      }).catch((error) => {
      });
  }


  render() {
    return (
      <div className="ContactsAdd view">
        <div className="page-area--tabbed">

          <BackLink history={this.props.history} />
          <h1 className="header-bar no-margin">{this.props.actionType === 'create' ? 'Add' : 'Edit'} Contact Node</h1>

          <WrappedDataForm
            title=""
            apiFunction={this.apiFunction}
            apiResource="Contact Node"
            onSubmitCallback={this.onSubmitCallback}
            verbs={["Saving", "Saved"]}
            hideRequiredMark
            onErrorCallback={this.onErrorCallback}
          >
            {this.addContactNodeForm}
          </WrappedDataForm>
        </div>
      </div>
    );
  }

  onErrorCallback = (error) => {
    if (error.message === 'Name already exists') {
      message.destroy();
      message.error('Name already exists');
    }
  }

  onSubmitCallback = (record) => {
    this.props.history.push(`/glsmon/notifications/contact-nodes`);
  }

  getCheckedValue = (e) => {
    return e.target.checked ? 1 : 0;
  };

  selectedEnabledState = () => {
    if (this.state.resource.is_enabled) {
      if (this.state.resource.is_enabled === "1") {
        return 1;
      } else {
        return 0;
      }
    }
    return 1;
  }

  selectedTimePeriod = () => {
    if (this.state.resource.timeperiod_id) {
      return this.state.resource.timeperiod_id;
    } else if (this.state.availableTimePeriods.length > 0) {
      return parseInt(this.state.availableTimePeriods[0].id);
    }
  }

  addContactNodeForm = (defaultFormLayout) => {
    if (!this.state.fetched) return null;
    return (
      <div>
        <section className="view-section view-section--no-border">
          {this.state.resourceId &&
            <Form.Item style={{ display: 'none' }} {...defaultFormLayout} name="id" initialValue={this.state.resourceId}>
              <Input type="hidden" />
            </Form.Item>
          }

          <Form.Item label="Contact Name" {...defaultFormLayout} name="name"
            initialValue={this.state.resource.name}
            rules={[{ required: true, message: 'Contact name is required' }]}>
            <Input placeholder="Contact Node Name" />
          </Form.Item>

          <Form.Item label="Host" {...defaultFormLayout} name="host_id" initialValue={this.state.resource.host_id}>
            <Select>
              {
                this.state.availableHosts.map((resource) => {
                  return <Select.Option value={parseInt(resource.id)} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

          <Form.Item label="Query" {...defaultFormLayout} name="query_id" initialValue={this.state.resource.query_id}>
            <Select>
              {
                this.state.availableQueries.map((resource) => {
                  return <Select.Option value={parseInt(resource.id)} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

          <Form.Item label="Metric" {...defaultFormLayout} name="metric_id" initialValue={this.state.resource.metric_id}>
            <Select>
              {
                this.state.availableMetrics.map((resource) => {
                  return <Select.Option value={parseInt(resource.id)} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

          <Form.Item label="Contact Address" {...defaultFormLayout} name="contact_address_id"
            initialValue={this.state.resource.contact_address_id}
            rules={[{ required: true, message: 'A contact address is required' }]}>
            <Select>
              {
                this.state.availableContactAddresses.map((resource) => {
                  return <Select.Option value={parseInt(resource.id)} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

          <Form.Item label="Time Period" {...defaultFormLayout} name="timeperiod_id"
            initialValue={this.selectedTimePeriod()}
            rules={[{ required: true, message: 'A time period is required' }]}>
            <Select>
              {
                this.state.availableTimePeriods.map((resource) => {
                  return <Select.Option value={parseInt(resource.id)} key={parseInt(resource.id)}>{resource.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>

          <Form.Item
            label={<span><span>Metric States</span><span className="form-item-label-description">Signals passing through this Node will consider any unchecked Metric State Type to be essentially the equivalent of an 'Ok' state for the purposes of creating Notifications.</span></span>}
            {...defaultFormLayout}
            name="metric_state_type_ids"
            initialValue={this.state.resource.metric_state_type_ids}
          >
            <Checkbox.Group
              options={metricStateOptions}
            />
          </Form.Item>

          <Form.Item
            label={<span><span>Delay Minutes</span><span className="form-item-label-description">Delay signal propagation from this Node for this many minutes. This is used to ensure that problems are persistent (and not short-lived flukes) before notifying.</span></span>}
            {...defaultFormLayout}
            name="delay"
            initialValue={this.state.resource.delay}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label={<span><span>Delay Recoveries?</span><span className="form-item-label-description">If checked, recoveries will also be delayed. Generally this should be used for the first delaying node in a contact chain only.</span></span>}
            {...defaultFormLayout}
            name="delay_recoveries"
            initialValue={this.state.resource.delay_recoveries ? true : false}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label={<span><span>Notify Rate</span><span className="form-item-label-description">Set the number of minutes between repeated Notifications for ongoing, un-acked problem states.</span></span>}
            {...defaultFormLayout}
            name="notify_rate"
            initialValue={this.state.resource.notify_rate}
          >
            <InputNumber />
          </Form.Item>
        </section>
        <div className="view-action">
          <Form.Item>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </div>
    )
  };

}

export default ContactNodeAdd;
