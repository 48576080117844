import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'antd';

import { formatMilliseconds } from '../../helpers';

export const fileColumns = [
  {
    title: "Path",
    key: "path",
    dataIndex: "relpath",
    render: (text, record) => <Link to={`/glsmon/projects/${record.project.id}/files/${record.id}`} className="underline">{(record.name ? record.name : (record.relpath ? record.relpath : ''))}</Link>
  },
  {
    title: "Size",
    key: "size",
    dataIndex: "content_length",
    render: (text, record) => <span>{record.content_length}</span>
  },
  {
    title: "Modified Time",
    key: "modified_time",
    dataIndex: "modify_date",
    render: (text, record) => <span>{formatMilliseconds(record.modify_date)}</span>
  },
  {
    title: "Modified By",
    key: "modified_by",
    dataIndex: "name",
    render: (text, record) => <span>{record.project.name}</span>
  },
  {
    title: "CheckSum (md5)",
    key: "checksum",
    dataIndex: "checksum"
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          {record.actions.length > 0 && record.actions.map((action, i) => {
            if (!action.enabled(record)) { return null; }
            return (
              <span key={`${record.id}-${action.name}`}>
                {action.name == "Delete" ?
                  <Button
                    type={action.type}
                    disabled={action.disabled}
                    onClick={() => action.func(record)}
                    danger
                  >
                    {action.name}
                  </Button> :
                  <Button
                    type={action.type}
                    disabled={action.disabled}
                    onClick={() => action.func(record)}
                  >
                    {action.name}
                  </Button>
                }
              </span>
            );
          })}
        </span>
      );
    }
  },
];
